import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SubscriptionForm from '../../components/Subscriptions/SubscriptionForm';
import Controller from '../../store/entities/controller';
import { fetchAllSources } from '../../store/entities/actions/SourceActions';
import { Heading } from '@tbh/ui-kit';
import { fetchSubscriptionsForCurrentUser, editSubscription } from '../../store/entities/actions/SubscriptionActions';
import { fetchAllDeliveryTypes } from '../../store/entities/actions/DeliveryTypeActions';
import Card from '../../components/Ui/Card';


const SubscriptionsEditContainer = ({
	subscription,
	sources,
    fetchSubscriptions,
    fetchDeliveryTypes,
    deliveryTypes,
	createSubscription,
	history,
	editSubscription,
	match,
	...rest
}) => {
	useEffect(() => {
        fetchDeliveryTypes();
		fetchSubscriptions();
	}, []);

	function handleFormSubmit(formData) {
		editSubscription({ ...subscription, ...formData }).then(() => {
			history.push('/subscriptions');
		});
	}

	function handleCancel() {
		history.push('/subscriptions');
	}

	return (
		<div>
			<Heading>Edit Subscription</Heading>
			<Card>
				<SubscriptionForm
                    sources={sources}
                    deliveryTypes={deliveryTypes}
					onSubmit={handleFormSubmit}
					onCancel={handleCancel}
					subscription={subscription}
				/>
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const subscriptions = Controller.denormalize('subscriptions', state.entities);

	const subscription = subscriptions.find((subscription) => {
		return subscription.id == ownProps.match.params.id;
	});

	return {
		subscription,
		deliveryTypes: Controller.denormalize('deliveryTypes', state.entities),
		sources: Controller.denormalize('sources', state.entities),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSubscriptions: () => {
			dispatch(fetchAllSources());
			dispatch(fetchSubscriptionsForCurrentUser());
		},
        
		fetchDeliveryTypes: () => {
			dispatch(fetchAllDeliveryTypes());
		},

		editSubscription: (formData) => {
			return dispatch(editSubscription(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SubscriptionsEditContainer);

import React, { useState, useEffect } from 'react';
import { typography, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const RcnGbsMapForm = (props) => {
	const { onSubmit, onCancel, rcn } = props;
	const[btn_load,setBtnLoad] = useState();
	const [rcn_meeting_name, setRCNMeetingName] = useState();
	const [gbs_meeting_name, setGBSMeetingName] = useState();

	useEffect(() => {
        setBtnLoad(false);
		setRCNMeetingName(rcn.rcn_meeting_name);
		setGBSMeetingName(rcn.gbs_meeting_name);
	}, [rcn]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
        setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			rcn_meeting_name,
			gbs_meeting_name,
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Styled__Label>RCN/SIS Meeting name (remove spaces and add in capital letters. ex: LASPIEDRASM)</Styled__Label>
			<Input value={rcn_meeting_name} onChange={(name, value) => setRCNMeetingName(value)} />
			<Styled__Label>GBS Meeting name (remove spaces and add in capital letters ex: LASPIEDRASM)</Styled__Label>
			<Input value={gbs_meeting_name} onChange={(name, value) => setGBSMeetingName(value)} />

			<br/>

			<Button action={handleCancel} size="medium" type="secondary"> Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} size="medium" loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>
		</form>
	);
};

RcnGbsMapForm.defaultProps = {
	rcn: {
		rcn_meeting_name: '',
		gbs_meeting_name: '',
	},
};

export default RcnGbsMapForm;

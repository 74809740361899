import React from 'react';
import Priority from "./Priority";
import {Droppable} from "react-beautiful-dnd";
import MeetingList from "./MeetingList";

const PriorityList = (props) => {

    return <Droppable droppableId="priority-list" type="priority">
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {props.priorities.map( (priority, index) => {

                        if(priority.meeting_priorities_id == 0){
                            return <MeetingList key={priority.meeting_priorities_id} index={index} meetingsList={priority}/>
                        }

                        return <Priority key={priority.meeting_priorities_id} index={index} priority={priority} removePirotiyAction={props.removePirotiyAction}/>;
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
}

export default PriorityList;
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { logout } from '../../store/application/applicationActions';

class LogoutPage extends React.Component {
	componentDidMount() {
		this.props.logout();
	}

	/**
	 * Render
	 */
	render() {
		return <Redirect to="/login" />;
	}
}

LogoutPage.propTypes = {
	/** Logout action */
	logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {
			return dispatch(logout());
		},
	};
};

export default connect(
	null,
	mapDispatchToProps,
)(LogoutPage);

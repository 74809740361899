import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import SqsQueueList from '../../components/SqsQueue/SqsQueueList';
import { fetchAllSqsQueues, deleteSqsQueue, resetSqsQueues } from '../../store/entities/actions/SqsQueueActions';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import Button from "../../components/Ui/Button";

const SqsQueueListPage = (props) => {
	const { sqsQueues, deleteSqsQueue, fetchSqsQueues } = props;

	useEffect(() => {
		fetchSqsQueues();
	}, []);

	return (
		<div>
			<FlexText>
				<Heading>Sqs Queue List</Heading>
				<Link to="/sqs-queues/create">
					<Button color="primary" round>Create new Sqs Queue</Button>
				</Link>
			</FlexText>
				    <Table bordered  responsive={true} id="sqsTable">
						<thead>
							<tr>
							<th>Client Id</th>
							<th>Subscription Id</th>
							<th>Version</th>
							<th>Region</th>
							<th>Queue Mame</th>
							<th>Action</th>
							</tr>
						</thead>
					<tbody>
					<SqsQueueList sqsQueues={sqsQueues} onDelete={deleteSqsQueue} />
					</tbody>
					</Table>
         </div>
	);
};

const mapStateToProps = (state) => {
	return {
		sqsQueues: Controller.denormalize('sqsQueues', state.entities),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteSqsQueue: (sqsQueueId) => {
			return dispatch(deleteSqsQueue(sqsQueueId)).finally(() => {
				dispatch(resetSqsQueues());
			});
		},

		fetchSqsQueues: () => {
			dispatch(fetchAllSqsQueues());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SqsQueueListPage);

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Validate the resource. BetRadarVenueMap
 *
 * @param {*} formData
 */
const validateBetRadarVenueMap = (betRadarVenueMap) => {
    return betRadarVenueMap;
};

/**
 * Load individual endpoints for the logged in client
 *
 * @param {*} embed
 */
const loadBetRadarVenueMaps = (embed = []) => {
    return get('/admin/betradar-venue-map', {
        params: { with: embed },
    }).catch((e) => {
        throw new Error(`Couldn't load individual endpoints: ${e}`);
    });
};

/**
 * Create a resource
 *
 * @param betRadarVenueMap
 */
export const createBetRadarVenueMap = (betRadarVenueMap) => (dispatch) => {
    return post('/admin/betradar-venue-map', validateBetRadarVenueMap(betRadarVenueMap));
};

/**
 * Edit the resource
 *
 * @param betRadarVenueMap
 */
export const editBetRadarVenueMap = (betRadarVenueMap) => (dispatch) => {
    return put(`/admin/betradar-venue-map/${betRadarVenueMap.id}`, validateBetRadarVenueMap(betRadarVenueMap));
};

/**
 * Delete the resource
 *
 * @param id
 */
export const deleteBetRadarVenueMap = (id) => (disaptch) => {
    return remove(`/admin/betradar-venue-map/${id}`);
};

/**
 * Fetch all individual endpoints for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllBetRadarVenueMaps = (embed) => (dispatch) => {
    loadBetRadarVenueMaps(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('betRadarVenueMaps', data.data).entities)),
    );
};

/**
 * Reset individual endpoints for logged in client
 *
 * @param {*} embed
 */
export const resetBetRadarVenueMaps = (embed) => (dispatch) => {
    loadBetRadarVenueMaps(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                betRadarVenueMaps: {},
                ...Controller.normalize('betRadarVenueMaps', data.data).entities,
            }),
        ),
    );
};

/**
 * Run Artisan command
 *
 * @param {*} embed
 */
export const runCommand = (embed) => {
    return post(`/admin/betradar-venue-map/consume-feed`);
};

// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import { Tabs } from '@tbh/ui-kit';
import SideBarMenuItem from './SideBarMenuItem';

const SideBarMenu = (props) => {
	const { className, filter, items } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const lowercaseFilter = filter.toLowerCase();

	return (
		<Tabs className={componentClasses} isColumn >
			{items
				.filter(
					(item) =>
						!filter || (item.name.toLowerCase().includes(lowercaseFilter) || item.href.includes(lowercaseFilter)),
				)
				.map((item) => (
					<SideBarMenuItem key={item.name} {...item} />
				))}
		</Tabs>
	);
};

SideBarMenu.propTypes = {
	/** Sidebar filter */
	filter: PropTypes.string,

	/** Article's title */
	title: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	/** Configuration for the items to show in the menu */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.string,
			name: PropTypes.string,
			href: PropTypes.string,
		}),
	),
};

SideBarMenu.defaultProps = {
	className: '',
	filter: '',
	title: 'Menu Item',
	items: [],
};

export default SideBarMenu;

import React from 'react';
import { connect } from 'react-redux';

const HomePage = () => {
	return <h1>Home Page</h1>
};

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(HomePage);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Hr1StreamForm from '../../components/Hr1streamMap/Hr1StreamForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllHr1Streams, editHr1stream } from '../../store/entities/actions/Hr1StreamAction';
import Card from '../../components/Ui/Card';

const Hr1streamEditContainer = ({ hr1Stream, fetchHr1Streams, createHr1stream, history, editHr1stream, match, ...rest }) => {
	useEffect(() => {
		fetchHr1Streams();
	}, []);

	function handleFormSubmit(formData) {
		editHr1stream({...hr1Stream, ...formData}).then(() => {
			history.push('/manage-hr1-stream');
		});
	}

	function handleCancel() {
		history.push('/manage-hr1-stream');
	}

	return (
		<div>
			<Heading>Edit Hr1 Stream</Heading>
			<Card>
				<Hr1StreamForm onSubmit={handleFormSubmit} onCancel={handleCancel} hr1Stream={hr1Stream} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const hr1Streams = Controller.denormalize('hr1Stream', state.entities);

	const hr1Stream = hr1Streams.find((hr1Stream) => {
		return hr1Stream.id == ownProps.match.params.id;
	});

	return {
		hr1Stream,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchHr1Streams: () => {
			dispatch(fetchAllHr1Streams());
		},

		editHr1stream: (formData) => {
			return dispatch(editHr1stream(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Hr1streamEditContainer);

import React, { useState, useEffect } from 'react';
import { typography, Select, SelectOption, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const Hr1StreamForm = (props) => {
	const { onSubmit, onCancel, hr1Stream } = props;
	const [btn_load, setBtnLoad] = useState();
	const [venue_key, setLiveStreamVenueKey] = useState();
	const [stream_url, setLiveStreamVenueUrl] = useState();

	useEffect(() => {
		setBtnLoad(false);

		setLiveStreamVenueKey(hr1Stream.venue_key);
		setLiveStreamVenueUrl(hr1Stream.stream_url);
	}, [hr1Stream]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			venue_key,
			stream_url,
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
	}


	return (
		<form onSubmit={handleSubmit}>

			<Styled__Label>HR1 Live Stream Venue Key</Styled__Label>
			<Input value={venue_key} onChange={(name, value) => setLiveStreamVenueKey(value)} />

			<Styled__Label>HR1 Live Stream Venue URL</Styled__Label>
			<Input value={stream_url} onChange={(name, value) => setLiveStreamVenueUrl(value)} />
			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} size="large" loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>

		</form>
	);
};

Hr1StreamForm.defaultProps = {
	hr1Stream: {
		venue_key: '',
		stream_url: ''
	},
};

export default Hr1StreamForm;

import React  from 'react'
import ReactDOM from 'react-dom';
import './confirmation-alert.scss';

/**
 * Promise based confirmation alert
 *
 * @param   message         Alert message
 * @param   title           Alert title
 * @param   confirmLabel    Confirm button label
 * @param   cancelLabel     Cancel button label
 */

const ConfirmationAlert = (message = 'Are you sure?', title = 'Confirm', confirmLabel = 'Yes', cancelLabel = 'No') => {

    /**
     * Default timeout use to add and remove .open css class.
     */
    let defaultTimeout = 75;

    /**
     * Disposes the alert
     *
     * Fade out the alert and remove the element from body
     */
    function dispose() {

        let existingAlert = document.querySelector('.dha-alert-backdrop');

        if(existingAlert){
            existingAlert.classList.remove('open');
            setTimeout(() => {
                existingAlert.parentNode.removeChild(existingAlert);
            }, defaultTimeout);
        }
    }

    const promise =  new Promise((resolve, reject) => {
        dispose();

        const wrapper = document.body.appendChild(document.createElement('div'));
        wrapper.classList.add('dha-alert-backdrop');

        setTimeout(() => {
            wrapper.classList.add('open');
        }, defaultTimeout);

        return ReactDOM.render(
            <div className="dha-alert-modal">
                <div className="dha-alert-header">{title}</div>
                <div className="dha-alert-content">{message}</div>
                <div className="dha-alert-footer">
                    <button className="dha-alert-button dha-alert-cancel" onClick={reject}>{cancelLabel}</button>
                    <button className="dha-alert-button dha-alert-confirm" onClick={resolve}>{confirmLabel}</button>
                </div>
            </div>,
            wrapper
        );
    });

    return promise.then((result) => {
        dispose();
        return result;
    }, (result) => {
        dispose();
        return Promise.reject(result);
    });
}

export default ConfirmationAlert;

import React from 'react';
import { connect } from 'react-redux';
import RcnForm from '../../components/Rcn/RcnForm';
import { Heading } from '@tbh/ui-kit';
import { createRcnVenueMap } from '../../store/entities/actions/RcnActions';
import Card from '../../components/Ui/Card';

const RcnCreateContainer = ({ createRcnVenueMap, history, ...rest }) => {
	function handleFormSubmit(formData) {
		createRcnVenueMap(formData).then(() => {
			history.push('/rcn');
		});
	}

	function handleCancel() {
		history.push('/rcn');
	}

	return (
		<div>
			<Heading>Create Rcn Venue Map</Heading>
			<Card>
				<RcnForm onSubmit={handleFormSubmit} onCancel={handleCancel} />
			</Card>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		createRcnVenueMap: (formData) => {
			return dispatch(createRcnVenueMap(formData));
		},
	};
};

export default connect(
	null,
	mapDispatchToProps,
)(RcnCreateContainer);

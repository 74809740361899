import React from 'react';
import styled, { css } from 'react-emotion';
import SisListItem from './SisListItem';
import { spacings } from '@tbh/ui-kit';
import { SIS_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const Styled__SisListItem = styled(SisListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);

const SisList = ({ siss, onDelete }) => {
	let sisListItem= siss.map((sis) => {
		return <Styled__SisListItem key={sis.id} onDelete={onDelete} {...sis} editLink={`${SIS_ROUTE}/edit/${sis.id}`} />;
	});

	if(sisListItem.length>0){

		$(document).ready(function () {
			$('#sisTable').DataTable();
		});
		return sisListItem;
	}else{

		return null;
	}
};

export default SisList;

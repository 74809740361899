import React from 'react';
import ReactDOM from 'react-dom';
import env from './Environment';
import { Provider } from 'react-redux';
import App from './App';
import { injectGlobal } from 'react-emotion/macro';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import './fonts/tbicons.css';
import { BrowserRouter as Router } from 'react-router-dom';
import createStore from './store/createStore';

window.env = global.env = App.env = env; // Global everywhere

if (!window.__DH_GLOBALS__) {
	window.__DH_GLOBALS__ = {};
}

const store = createStore();

global.App = {
	store,
};

injectGlobal(`
html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // font-size: 16px;
  // font-weight: 400;
  // line-height: 1.5;
  // color: #212529;
  background-color: #eee;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

:any-link {
  text-decoration: none;
}

// body {
//   margin: 0;
//   padding: 0;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   font-family: "proxima-nova",sans-serif;
// }
//
// label, input {
//   color: #000 !important;
// }
//
// input {
//   &::placeholder {
//     color: rgba(0, 0, 0, 0.5) !important;
//   }
// }
//
// fieldset {
//   margin-top: 16px;
//   border: 1px solid #DDD;
// }
//
// select {
// 	padding: 8px !important;
// }

`);

const render = (Component) => {
	return ReactDOM.render(
		<Provider store={store}>
			<Router>
				<Component />
			</Router>
		</Provider>,
		document.getElementById('root'),
	);
};
render(App);
if (process.env.NODE_ENV !== 'production') {
	if (module.hot && process.env.REACT_APP_HOT) {
		module.hot.accept('./App', () => {
			const NextApp = require('./App').default;
			render(NextApp);
		});
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { spacings } from '@tbh/ui-kit';

import styled, { css } from 'react-emotion/macro';
const Styled__SourceLabel = styled('div')(
	(props) => css`
		background: #eee;
		border-radius: 3px 0 0 3px;
		color: #999;
		display: inline-block;
		height: 26px;
		line-height: 26px;
		padding: 0 20px 0 15px;
		position: relative;
		margin: 0 10px 10px 0;
		text-decoration: none;
		-webkit-transition: color 0.2s;

		${!props.hasArrow &&
			css`
				border-radius: 3px 3px 3px 3px;
				padding: 0 20px 0 20px;
			`}

		${props.hasArrow &&
			css`
				::after {
					background: #fff;
					border-bottom: 13px solid transparent;
					border-left: 10px solid #eee;
					border-top: 13px solid transparent;
					content: '';
					position: absolute;
					right: 0;
					top: 0;
				}
			`}

		${props.useHover &&
			css`
				:hover {
					cursor: inherit;
					background-color: crimson;
					color: white;
				}

				:hover::after {
					border-left-color: crimson;
				}
			`}
	`,
);

const SourceLabel = ({ label, onClick, hasArrow = true, ...rest }) => {
	const handleOnClick = (e) => {
		e.stopPropagation();
		onClick && onClick(label);
	};

	return (
		<Styled__SourceLabel useHover={!!onClick} hasArrow={hasArrow} onClick={handleOnClick} {...rest}>
			{label}
		</Styled__SourceLabel>
	);
};
export default SourceLabel;

import React from 'react';
import styled, { css } from 'react-emotion';
import SubscriptionsListItem from './SubscriptionsListItem';
import { spacings } from '@tbh/ui-kit';
import { SUBSCRIPTIONS_ROUTE, SUBSCRIPTION_RACE_CARD_ROUTE } from '../../constants/RouteConstants';

const Styled__SubscriptionsListItem = styled(SubscriptionsListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);

const SubscriptionsList = ({ subscriptions, onDelete }) => {
	return subscriptions.map((subscription) => {
		return (
			<Styled__SubscriptionsListItem
				key={subscription.id}
				onDelete={onDelete}
				{...subscription}
				editLink={`${SUBSCRIPTIONS_ROUTE}/edit/${subscription.id}`}
				testLink={`${SUBSCRIPTIONS_ROUTE}/test/${subscription.id}`}
				raceCardLink={`${SUBSCRIPTION_RACE_CARD_ROUTE}/${subscription.id}`}
			/>
		);
	});
};

export default SubscriptionsList;

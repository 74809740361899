import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GwsForm from '../../components/Gws/GwsForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllGws, editGws } from '../../store/entities/actions/GwsAction';
import Card from '../../components/Ui/Card';

const GwsEditContainer = ({ gws, fetchGws, createGws, history, editGws, match, ...rest }) => {
	useEffect(() => {
		fetchGws();
	}, []);

	function handleFormSubmit(formData) {
		editGws({...gws, ...formData}).then(() => {
			history.push('/manage-gws');
		});
	}

	function handleCancel() {
		history.push('/manage-gws');
	}

	return (
		<div>
			<Heading>Edit GWS</Heading>
			<Card>
				<GwsForm onSubmit={handleFormSubmit} onCancel={handleCancel} gwss={gws} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const gwss = Controller.denormalize('gwss', state.entities);

	const gws = gwss.find((gws) => {
		return gws.id == ownProps.match.params.id;
	});

	return {
		gws,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchGws: () => {
			dispatch(fetchAllGws());
		},

		editGws: (formData) => {
			return dispatch(editGws(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(GwsEditContainer);

import React from 'react';
import styled, { css } from 'react-emotion';
import RcnGbsMapListItem from './RcnGbsMapListItem';
import { RCN_GBS_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const Styled__RcnListItem = styled(RcnGbsMapListItem)(
	(props) => css`
		padding-top: 20px;
		border-bottom: 1px solid grey;
	`,
);
const RcnGbsMapList = ({ rcnGbsMaps, onDelete }) => {


	let rcnListItem= rcnGbsMaps.map((rcn) => {
		return <Styled__RcnListItem key={rcn.id} onDelete={onDelete} {...rcn} editLink={`${RCN_GBS_ROUTE}/edit/${rcn.id}`} />;
	});
	if(rcnListItem.length>0){

		$(document).ready(function () {
		// 	$('#rcntable').DataTable();
		});
		return rcnListItem;
	}else{

		return null;
	}
};

export default RcnGbsMapList;

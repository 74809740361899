import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RACING_RESOURCES from '../../common/racingResources';

import CodePush from '../../components/CodePush';

const RacingPage = (props) => {
	return <CodePush resources={props.racingResources} />;
};

const mapStateToProps = () => {
	return {
		racingResources: RACING_RESOURCES,
	};
};

RacingPage.propTypes = {
	/** The list of racing resources */
	racingResources: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(RacingPage);

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Validate the resource.
 *
 * @param {*} formData
 */
const validateGlobalEndpoint = (globalEndpoint) => {
    return globalEndpoint;
};

/**
 * Load global endpoints for the logged in client
 *
 * @param {*} embed
 */
const loadGlobalEndpoints = (embed = []) => {
    return get('/admin/global-endpoints', {
        params: { with: embed },
    }).catch((e) => {
        throw new Error(`Couldn't load global endpoints: ${e}`);
    });
};

/**
 * Create a resource
 *
 * @param {*} formData
 */
export const createGlobalEndpoint = (globalEndpoint) => (dispatch) => {
    return post('/admin/global-endpoints', validateGlobalEndpoint(globalEndpoint));
};

/**
 * Edit the resource
 *
 * @param {*} globalEndpoint
 */
export const editGlobalEndpoint = (globalEndpoint) => (dispatch) => {
    return put(`/admin/global-endpoints/${globalEndpoint.id}`, validateGlobalEndpoint(globalEndpoint));
};

/**
 * Delete the resource
 *
 * @param {*} globalEndpointId
 */
export const deleteGlobalEndpoint = (globalEndpointId) => (disaptch) => {
    return remove(`/admin/global-endpoints/${globalEndpointId}`);
};

/**
 * Fetch all global endpoints for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllGlobalEndpoints = (embed) => (dispatch) => {
    loadGlobalEndpoints(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('globalEndpoints', data.data).entities)),
    );
};

/**
 * Reset global endpoints for logged in client
 *
 * @param {*} embed
 */
export const resetGlobalEndpoints = (embed) => (dispatch) => {
    loadGlobalEndpoints(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                globalEndpoints: {},
                ...Controller.normalize('globalEndpoints', data.data).entities,
            }),
        ),
    );
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'react-emotion/macro';
import { Redirect } from 'react-router-dom';

import { spacings, ui, Panel, PlotElements, Input, Button, Notification } from '@tbh/ui-kit';

import { getAuthenticatedUser } from '../../store/application/applicationSelectors';
import { login } from '../../store/application/applicationActions';

import { BASE_ROUTE } from '../../constants/RouteConstants';

const StyledLoginPage = styled(Panel)(
	(props) => css`
		label: LoginPage;

		padding: ${spacings(props).compact}px;
	`,
);

const StyledLoginPage__Notification = styled(Notification)(
	(props) => css`
		label: LoginPage__Notification;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledLoginPage__Input = styled(Input)(
	(props) => css`
		label: LoginPage__Input;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledLoginPage__SubmitSection = styled(PlotElements)(
	(props) => css`
		label: LoginPage__SubmitSection;

		margin-top: ${spacings(props).spacious}px;
	`,
);

const StyledLoginPage__LoginPanel = styled(Panel)(
	(props) => css`
		label: LoginPage__LoginPanel;

		max-width: 400px;
		margin: auto;
		background: ${ui(props).color_3};
	`,
);

class LoginPage extends React.Component {
	state = {
		error: '',
		username: '',
		password: '',
		buttonLoading: false,
	};

	/**
	 * Handle the change of the inputs fields in the form
	 *
	 * @param inputName
	 * @param value
	 */
	handleInputChange = (inputName, value) => {
		this.setState({
			[inputName]: value,
		});
	};

	/**
	 * Handle when the form is submit in some browsers
	 *
	 * @param e
	 * @returns {*}
	 */
	handleFormSubmit = (e) => e.preventDefault();

	/**
	 * Handle submitting an error
	 */
	handleSubmitError = (error) => {
		const nextState = {
			buttonLoading: false,
		};

		const statusCode = error && error.response ? error.response.status : 0;

		switch (statusCode) {
			case 401:
				nextState.error = 'Login Failed - Please check username and password';
				break;
			default:
				nextState.error = `${error.message}: ${error.response.data.errors}`;
		}

		this.setState(nextState);
	};

	/**
	 * Handle the submit of the login form
	 *
	 * @param e
	 */
	handleSubmit = (e) => {
		e.preventDefault();

		const { username, password } = this.state;

		if (username && password) {
			this.setState({
				buttonLoading: true,
			});

			this.props
				.onSubmitLogin(username, password)
				.then(() => this.props.history.push('/subscriptions'))
				.catch(this.handleSubmitError);
		}
	};

	/**
	 * Render
	 */
	render() {
		const { error, username, password, buttonLoading } = this.state;

		if (this.props.authenticatedUser) {
			return <Redirect to={BASE_ROUTE} />;
		}

		return (
			<StyledLoginPage>
				<StyledLoginPage__LoginPanel padding={Panel.padding.SPACING_COMFORTABLE} type={Panel.types.ONE}>
					{error && (
						<StyledLoginPage__Notification type={Notification.types.COLOUR_DANGER}>
							{error}
						</StyledLoginPage__Notification>
					)}
					<form onSubmit={this.handleFormSubmit}>
						<StyledLoginPage__Input
							onChange={this.handleInputChange}
							value={username}
							name="username"
							label="Username:"
						/>

						<StyledLoginPage__Input
							onChange={this.handleInputChange}
							value={password}
							name="password"
							type="password"
							label="Password:"
						/>

						<StyledLoginPage__SubmitSection align="space-between">
							<Button action={this.handleSubmit} type={Button.types.PRIMARY} loading={buttonLoading} block>
								Login
							</Button>
						</StyledLoginPage__SubmitSection>
					</form>
				</StyledLoginPage__LoginPanel>
			</StyledLoginPage>
		);
	}
}

LoginPage.propTypes = {
	/** Login action */
	onSubmitLogin: PropTypes.func.isRequired,

	/** The currently authenticated user */
	authenticatedUser: PropTypes.object,
};

LoginPage.defaultProps = {
	authenticatedUser: null,
};

const mapStateToProps = (state) => {
	const authenticatedUser = getAuthenticatedUser(state);

	return {
		authenticatedUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSubmitLogin: (username, password) => {
			return dispatch(login(username, password));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LoginPage);

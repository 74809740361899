import React from 'react';
import styled, { css } from 'react-emotion';
import SqsQueueListItem from './SqsQueueListItem';
import { spacings } from '@tbh/ui-kit';
import { SQS_QUEUE_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const Styled__SqsQueueListItem = styled(SqsQueueListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);

const SqsQueueList = ({ sqsQueues, onDelete }) => {
	let sqsListItem= sqsQueues.map((sqsQueue) => {
		return <Styled__SqsQueueListItem key={sqsQueue.id} onDelete={onDelete} {...sqsQueue} editLink={`${SQS_QUEUE_ROUTE}/edit/${sqsQueue.id}`} />;
	});
	if(sqsListItem.length>0){

		$(document).ready(function () {
			$('#sqsTable').DataTable();
		});
		return sqsListItem;
	}else{


		return null;
	}
};

export default SqsQueueList;

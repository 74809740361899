import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import YoutubeStreamList from '../../components/YoutubeStream/YoutubeStreamList';
import { fetchAllYoutubeStreams, deleteYoutubeStream, resetYoutubeStreams } from '../../store/entities/actions/YoutubeStreamAction';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Button from "../../components/Ui/Button"

const YoutubeStreamListPage = (props) => {
	const { youtubeStreams, deleteYoutubeStream, fetchYoutubeStreams } = props;

	useEffect(() => {
		fetchYoutubeStreams();
	}, []);

	return (
		<div>
			<FlexText>
				<Heading>Youtube Streams List</Heading>
				<Link to="/youtube-streams/create">
					<Button color="primary" round>Create new Youtube Stream</Button>
				</Link>
			</FlexText>
            <Table bordered  responsive={true} id="ysTable">
                <thead>
                    <tr>
                    <th>Youtube Live Id</th>
                    <th>Venue Key</th>
                    <th>Stream Url</th>
                    <th>Action</th>
                    </tr>
                </thead>
				<tbody>
		         	<YoutubeStreamList youtubeStreams={youtubeStreams} onDelete={deleteYoutubeStream} />
				</tbody>
				</Table>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		youtubeStreams: Controller.denormalize('youtubeStreams', state.entities),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteYoutubeStream: (id) => {
			return dispatch(deleteYoutubeStream(id)).finally(() => {
				dispatch(resetYoutubeStreams());
			});
		},

		fetchYoutubeStreams: () => {
			dispatch(fetchAllYoutubeStreams());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(YoutubeStreamListPage);

import React, { useState, useEffect } from 'react';
import { typography, Checkbox, Input, Textarea, FlexText, Button, spacings, Select, SelectOption } from '@tbh/ui-kit';
import SourceLabel from './SourceLabel';
import styled, { css, injectGlobal } from 'react-emotion';
import Switch from '../../components/Ui/Switch';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const Styled__Textarea = styled(Textarea)(
	() => css`
		width: 100%;
	`,
);

const SubscriptionForm = (props) => {
	const { sources, onSubmit, onCancel, subscription, deliveryTypes } = props;
    const[btn_load,setBtnLoad] = useState();
	const [racing, setRacing] = useState();
	const [sport, setSport] = useState();
	const [description, setDescription] = useState();
	const [racing_field_priorities, setRacingPriorities] = useState();
	const [sport_field_priorities, setSportPriorities] = useState();
	const [selectedSources, setSelectedSources] = useState(null); // update
	const [delivery_type_id, setDeliveryTypeId] = useState();

	const allSelectedSources = selectedSources || [];

	const selectedSourceIds = allSelectedSources.map((source) => source.id);

	const filteredSources = sources.filter((source) => selectedSourceIds.indexOf(source.id) === -1);

	// Generate a sort function for sources based on an array of ordered id's
	const sortByIds = (orderedSourceIds = []) => {
		return (a, b) => {
			const aIndex = orderedSourceIds.indexOf(a.id);
			const bIndex = orderedSourceIds.indexOf(b.id);

			if (aIndex < bIndex) {
				return -1;
			}
			if (aIndex > bIndex) {
				return 1;
			}

			return 0;
		};
	};

	useEffect(() => {
        setBtnLoad(false);
		setDescription(subscription.description);
		setRacing(subscription.racing);
		setSport(subscription.sport);
		setRacingPriorities(subscription.racing_field_priorities);
		setSportPriorities(subscription.sport_field_priorities);
		setDeliveryTypeId(subscription.delivery_type_id);

		if (!selectedSources && subscription.sources) {
			setSelectedSources(subscription.sources.sort(sortByIds(subscription.sourceOrder))); // Sort the initial sources by the supplied source id.
		}
	}, [subscription]);

	function updatePriorities(type) {
		const updateMethod = type === 'racing' ? setRacingPriorities : setSportPriorities;

		return (e) => {
			updateMethod(e.target.value);
		};
	}

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
        setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			racing,
			sport,
			racing_field_priorities:
				typeof racing_field_priorities === 'string' ? racing_field_priorities : JSON.stringify(racing_field_priorities),
			sport_field_priorities:
				typeof sport_field_priorities === 'string' ? sport_field_priorities : JSON.stringify(sport_field_priorities),
			description,
            sources: allSelectedSources,
            delivery_type_id,
		});
	}

	/**
	 * Add a source to the list of selected sources
	 *
	 * @param {} source
	 */
	function addSource(source) {
		if (allSelectedSources.indexOf(source) === -1) {
			let newSources = [...allSelectedSources];
			newSources.push(source);
			setSelectedSources(newSources);
		}
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
	}

    /**
     * Create the delivery types select items
     * 
     * @return array
     */
    function createDeliveryTypeSelectItems() {
        return deliveryTypes.map((item, key) =>
            <SelectOption value={item.id}>{item.description}</SelectOption>
        );
    }

	/**
	 * Remove a source from the list of selected sources
	 *
	 * @param {*} source
	 */
	function removeSource(source) {
		const newSelectedSources = selectedSources.reduce((acc, item) => {
			if (item !== source) {
				acc.push(item);
			}
			return acc;
		}, []);

		setSelectedSources(newSelectedSources);
	}

	const decodeFieldPriority = (field) => {
		if (typeof field === 'string') {
			return field;
		}

		return JSON.stringify(field);
	};

	return (
		<form onSubmit={handleSubmit}>
			<Styled__Label>Name</Styled__Label>

			<Input value={description} onChange={(name, value) => setDescription(value)} />
			<Styled__Label>
				<FlexText flexGrow={false}>
					<span>Racing:</span>
					<Switch checked={racing} onChange={() => setRacing(!racing)} />
				</FlexText>
			</Styled__Label>

			<Styled__Label>
				<FlexText flexGrow={false}>
					<span>Sport:</span>
					<Switch checked={sport} onChange={() => setSport(!sport)} />
				</FlexText>
			</Styled__Label>

			<Styled__Label>Selected Sources: </Styled__Label>
			<div>
				{allSelectedSources.length > 0 ? (
					allSelectedSources.map((source) => (
						<SourceLabel hasArrow label={source.name} onClick={() => removeSource(source)} />
					))
				) : (
					<span>None</span>
				)}
			</div>
			<hr />
			<Styled__Label>Available Soruces: </Styled__Label>
			<div>
				{filteredSources &&
					filteredSources.map((source) => (
						<SourceLabel hasArrow={false} onClick={() => addSource(source)} label={source.name} />
					))}
			</div>

			<Styled__Label>Sports Priorities</Styled__Label>
			<Styled__Textarea value={decodeFieldPriority(sport_field_priorities)} onChange={updatePriorities('sport')} />
			<Styled__Label>Racing Priorities</Styled__Label>
			<Styled__Textarea value={decodeFieldPriority(racing_field_priorities)} onChange={updatePriorities('racing')} />

            <Styled__Label>Delivery Type</Styled__Label>
            <Select value={delivery_type_id} onChange={(name, value) => setDeliveryTypeId(value)}>
                {createDeliveryTypeSelectItems()}
            </Select>

			<div>
				<Button action={handleCancel} size="medium" square={true}  type="secondary"> Cancel</Button>

				<Button onClick={handleSubmit} type={Button.types.PRIMARY} square={true} size="medium" loading={btn_load} onSubmit={handleSubmit}>
					Submit
				</Button>
			</div>
		</form>
	);
};

SubscriptionForm.defaultProps = {
	subscription: {
		racing: false,
		sport: false,
		racing_field_priorities: '[]',
		sport_field_priorities: '[]',
		delivery_type_id: 1,
	},
};

export default SubscriptionForm;

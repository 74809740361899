import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Hr1StreamForm from '../../components/Hr1streamMap/Hr1StreamForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { createHr1stream } from '../../store/entities/actions/Hr1StreamAction';
import Card from '../../components/Ui/Card';

const Hr1streamCreateContainer = ({ createHr1stream, history, ...rest }) => {

	function handleFormSubmit(formData) {
		createHr1stream(formData).then(() => {
			history.push('/manage-hr1-stream');
		});
	}

	function handleCancel() {
		history.push('/manage-hr1-stream');
	}

	return (
		<div>
			<Heading>Create Hr1 Stream</Heading>
			<Card>
				<Hr1StreamForm onSubmit={handleFormSubmit} onCancel={handleCancel} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = (dispatch) => {
	return {
		createHr1stream: (formData) => {
			return dispatch(createHr1stream(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Hr1streamCreateContainer);

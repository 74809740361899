import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import GlobalEndpointListContainer from './GlobalEndpointListContainer';
import GlobalEndpointCreateContainer from './GlobalEndpointCreateContainer';
import GlobalEndpointEditContainer from './GlobalEndpointEditContainer';

const GlobalEndpointPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={GlobalEndpointListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={GlobalEndpointCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={GlobalEndpointEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		globalEndpoints: Controller.denormalize('globalEndpoints', state.entities),
	};
};

export default connect(mapStateToProps)(GlobalEndpointPage);

import React from 'react';
import {editPriority} from "../../store/entities/actions/MeetingPrioritiesActions";
import {Heading, LoadingMask} from '@tbh/ui-kit';
import Card from "../../components/Ui/Card";
import EditMeetingPriorityForm from "../../components/MeetingPriorities/EditMeetingPriorityForm";

class EditMeetingPriority extends React.Component {

    state = {
        loading: true,
    };

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.hideLoading();
    }

    handleCancel() {
        this.props.history.push('/meeting-priorities');
    }

    handleSubmit(data) {
        this.showLoading();
        editPriority(data).then(() => {
            this.props.history.push('/meeting-priorities');
        }).catch((e) => {
            alert(e);
        })
    }

    showLoading(){
        this.setState({
            loading: true,
        });
    }

    hideLoading(){
        this.setState({
            loading: false,
        });
    }

    render() {

        const LoadingStyles = {
            left: '260px',
            background: '#ffffff4f',
        }

        return <div>
            <LoadingMask loading={this.state.loading} style={LoadingStyles}/>
            <Heading>Edit Priority</Heading>
            <Card>
                <EditMeetingPriorityForm priorityId={this.props.match.params['id']} onSubmit={this.handleSubmit} onCancel={this.handleCancel}/>
            </Card>
        </div>
    }
}

export default EditMeetingPriority;
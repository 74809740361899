import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import YoutubeStreamForm from '../../components/YoutubeStream/YoutubeStreamForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { createYoutubeStream } from '../../store/entities/actions/YoutubeStreamAction';
import Card from '../../components/Ui/Card';

const YoutubeStreamCreateContainer = ({ createYoutubeStream, history, ...rest }) => {
    
	function handleFormSubmit(formData) {
		createYoutubeStream(formData).then(() => {
			history.push('/youtube-streams');
		});
	}

	function handleCancel() {
		history.push('/youtube-streams');
	}

	return (
		<div>
			<Heading>Create Youtube Stream</Heading>
			<Card>
				<YoutubeStreamForm onSubmit={handleFormSubmit} onCancel={handleCancel}  />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = (dispatch) => {
	return {
		createYoutubeStream: (formData) => {
			return dispatch(createYoutubeStream(formData));
        },


	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(YoutubeStreamCreateContainer);

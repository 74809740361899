import React from 'react';
import styled, { css } from 'react-emotion';
import YoutubeStreamListItem from './YoutubeStreamListItem';
import { spacings } from '@tbh/ui-kit';
import { YOUTUBE_STREAM_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

const Styled__YoutubeStreamListItem = styled(YoutubeStreamListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);

const YoutubeStreamList = ({ youtubeStreams, onDelete }) => {
    console.log('youtubeStreams');
    console.log(youtubeStreams);
	let ysListItem= youtubeStreams.map((youtubeStream) => {
		return <Styled__YoutubeStreamListItem  onDelete={onDelete} key={youtubeStream.r_youtube_livestream_id} {...youtubeStream} editLink={`${YOUTUBE_STREAM_ROUTE}/edit/${youtubeStream.r_youtube_livestream_id}`} />;
	});

    //Data List
	if(ysListItem.length>0){

		$(document).ready(function () {
			$('#ysTable').DataTable();
        });
        
		return ysListItem;
	}else{

		return null;
	}
};

export default YoutubeStreamList;

import { get, post } from '../../../common/ajax';

export const fetchMeetingPriorityData = () => {
    return get('priorities/view/data')
        .then(result => {
            return result.data.data;
        })
        .catch(e => {
            throw new Error(`Couldn't load meetings: ${e}`);
        });
}

export const getPriorityData = (priorityId) => {
    return get('priorities/' + priorityId)
        .then(result => {
            return result.data.data;
        })
        .catch((e) => {
            throw new Error(`Couldn't load priority: ${e}`);
        });
}

export const createPriority = (data) => {
    return post('priorities/create', data);
}

export const editPriority = (data) => {
    return post('priorities/update', data);
}

export const updatePriorities = (data) => {
    return post('priorities/update/meetings', data, {
        headers: {
            'Access-Control-Allow-Origin' : '*'
        }
    });
}

export const updatePriorityOrder = (data) => {
    return post('priorities/update/order', data);
}

export const deletePriority = (data) => {
    return post('priorities/delete', data);
}
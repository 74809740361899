import React, { useState, useEffect } from 'react';
import { typography, Input, Button, Select, SelectOption, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const GlobalEndpointForm = (props) => {
	const { onSubmit, onCancel, globalEndpoint, clients } = props;
    const[btn_load,setBtnLoad] = useState();
	const [endpoint, setEndpoint] = useState();
	const [params, setParams] = useState();

	useEffect(() => {
		setBtnLoad(false);
		setEndpoint(globalEndpoint.endpoint);
		setParams(globalEndpoint.params);
	}, [globalEndpoint]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			endpoint,
			params,
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
	}
    
    /**
     * Create the client select items
     * 
     * @return array
     */
    function createClientSelectItems() {
        return clients.map((item, key) =>
            <SelectOption value={item.id}>{item.name}</SelectOption>
        );
    }

	return (
		<form onSubmit={handleSubmit}>

			<Styled__Label>Endpoint</Styled__Label>
			<Input value={endpoint} onChange={(name, value) => setEndpoint(value)} />

			<Styled__Label>Parameters</Styled__Label>
			<Input value={params} onChange={(name, value) => setParams(value)} />

			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} size="large" type={Button.types.PRIMARY} loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>

		</form>
	);
};

GlobalEndpointForm.defaultProps = {
	globalEndpoint: {
		endpoint: '',
		params: '',
		auth_password: '',
	},
};

export default GlobalEndpointForm;

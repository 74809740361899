import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import YoutubeStreamListContainer from './YoutubeStreamListContainer';
import YoutubeStreamCreateContainer from './YoutubeStreamCreateContainer';
import YoutubeStreamEditContainer from './YoutubeStreamEditContainer';

const YoutubeStreamPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={YoutubeStreamListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={YoutubeStreamCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:r_youtube_livestream_id`} component={YoutubeStreamEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
        youtubeStreams: Controller.denormalize('youtubeStreams', state.entities),
	};
};

export default connect(mapStateToProps)(YoutubeStreamPage);

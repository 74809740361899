import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import SisList from '../../components/Sis/SisList';
import { fetchAllVenueMaps, deleteSisVenueMap, resetVenueMaps } from '../../store/entities/actions/SisActions';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../../components/Ui/Button";
import Table from 'react-bootstrap/Table'

const SisListPage = (props) => {
	const { siss, deleteSisVenueMap, fetchVenueMaps } = props;

	useEffect(() => {
		fetchVenueMaps();
	}, []);

	return (
		<div>
			<FlexText>
				<Heading>Sis Venue Map List</Heading>
				<ToastContainer autoClose={2000} />
				<Link to="/sis/create">
					<Button color="primary" round>Create new Sis Venue Map</Button>
				</Link>
			</FlexText>
			<Table  bordered  responsive={true} id="sisTable">
				<thead>
				<tr>
					<th>Venue Key</th>
					<th>Secure Protocol</th>
					<th>Low Latency</th>
					<th>Stream To Pick</th>
					<th>Action</th>



				</tr>
				</thead>
				<tbody>
				<SisList siss={siss} onDelete={deleteSisVenueMap} />
				</tbody>

			</Table>

		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		siss: Controller.denormalize('siss', state.entities),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteSisVenueMap: (venueMapId) => {
			return dispatch(deleteSisVenueMap(venueMapId)).finally(() => {
				dispatch(resetVenueMaps());
			});
		},

		fetchVenueMaps: () => {
			dispatch(fetchAllVenueMaps());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SisListPage);

import React, { useState, useEffect } from 'react';
import { typography, Select, SelectOption, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const GwsForm = (props) => {
	const { onSubmit, onCancel, gwss } = props;
	const [btn_load, setBtnLoad] = useState();
	const [venue_key, setGwsVenueKey] = useState();
	const [track_id, setGwsTrckId] = useState();
	const [track_name, setGwsTrackName] = useState();
	const [track_country_code, setTrackCountryCode] = useState();
	const [track_type, setGwsTrackType] = useState();
	const [track_code, setGwsTrackCode] = useState();
	const [track_video_code, setGwsTrackVideoCode] = useState();

	useEffect(() => {
		setBtnLoad(false);

		setGwsVenueKey(gwss.venue_key);
		setGwsTrckId(gwss.track_id);
		setGwsTrackName(gwss.track_name);
		setTrackCountryCode(gwss.track_country_code);
		setGwsTrackType(gwss.track_type);
		setGwsTrackCode(gwss.track_code);
		setGwsTrackVideoCode(gwss.track_video_code);
	}, [gwss]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			venue_key,
			track_id,
			track_name,
			track_country_code,
			track_type,
			track_code,
			track_video_code
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
	}


	return (
		<form onSubmit={handleSubmit}>

			<Styled__Label>Venue Key</Styled__Label>
			<Input value={venue_key} onChange={(name, value) => setGwsVenueKey(value)} />

			<Styled__Label>Track ID</Styled__Label>
			<Input value={track_id} onChange={(name, value) => setGwsTrckId(value)} />

			<Styled__Label>Track Name</Styled__Label>
			<Input value={track_name} onChange={(name, value) => setGwsTrackName(value)} />

			<Styled__Label>Track Country Code</Styled__Label>
			<Input value={track_country_code} onChange={(name, value) => setTrackCountryCode(value)} />

			<Styled__Label>Track Type</Styled__Label>
			<Input value={track_type} onChange={(name, value) => setGwsTrackType(value)} />

			<Styled__Label>Track Code</Styled__Label>
			<Input value={track_code} onChange={(name, value) => setGwsTrackCode(value)} />

			<Styled__Label>Track Video Code</Styled__Label>
			<Input value={track_video_code} onChange={(name, value) => setGwsTrackVideoCode(value)} />

			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} size="large" loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>

		</form>
	);
};

GwsForm.defaultProps = {
	gwss: {
		venue_key:'',
		track_id:'',
		track_name:'',
		track_country_code:'',
		track_type:'',
		track_code:'',
		track_video_cod:''
	},
};

export default GwsForm;

import React, {useEffect} from 'react';
import { FlexText, Heading,Button} from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { css } from 'react-emotion/macro';
import Card from '../Ui/Card';
import Table from 'react-bootstrap/Table';
import ButtonMaterial from '../Ui/Button';


const RcnListItem = ({
	id,
	editLink,
	onDelete,
	venue_key,
	private_key,
	referer,
	stream_name,
	extra_params,
	feed,
	hd,
	volume,
	...rest
}) => {
	const handleDelete = (e) => {
		e.stopPropagation();
		onDelete && onDelete(id);
	};

	return (

		   <tr>
			   <td>	<Heading type="6">{venue_key ||<span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{private_key || <span className="badge badge-danger">N/A</span>}</Heading></td>
			   <td><Heading type="6">{referer || <span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{stream_name ||<span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{extra_params || <span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{feed || <span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{hd || <span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{volume ||<span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><div>
				   <Link to={editLink}><ButtonMaterial color="primary">Edit</ButtonMaterial></Link><i onClick={handleDelete} ><ButtonMaterial color="danger" >Delete</ButtonMaterial></i>
			   </div>
			   </td>
		   </tr>
	);
};

export default RcnListItem;

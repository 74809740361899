import React from 'react';
import { connect } from 'react-redux';
import SisForm from '../../components/Sis/SisForm';
import { Heading } from '@tbh/ui-kit';
import { createSisVenueMap } from '../../store/entities/actions/SisActions';
import Card from '../../components/Ui/Card';

const SisCreateContainer = ({ createSisVenueMap, history, ...rest }) => {
	function handleFormSubmit(formData) {
		createSisVenueMap(formData).then(() => {
			history.push('/sis');
		});
	}

	function handleCancel() {
		history.push('/sis');
	}

	return (
		<div>
			<Heading>Create Sis Venue Map</Heading>
			<Card>
				<SisForm onSubmit={handleFormSubmit} onCancel={handleCancel} />
			</Card>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		createSisVenueMap: (formData) => {
			return dispatch(createSisVenueMap(formData));
		},
	};
};

export default connect(
	null,
	mapDispatchToProps,
)(SisCreateContainer);

import React from 'react';
import styled, { css } from 'react-emotion';
import BetRadarVenueMapListItem from './BetRadarVenueMapListItem';
import { spacings } from '@tbh/ui-kit';
import { BET_RADAR_VENUE_MAP_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

const Styled__BetRadarVenueMapListItem = styled(BetRadarVenueMapListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);

const BetRadarVenueMapList = ({ betRadarVenueMaps, onDelete }) => {
	let betRadartableItems= betRadarVenueMaps.map((betRadarVenueMap) => {
		return <Styled__BetRadarVenueMapListItem  onDelete={onDelete} key={betRadarVenueMap.id} {...betRadarVenueMap} editLink={`${BET_RADAR_VENUE_MAP_ROUTE}/edit/${betRadarVenueMap.id}`} />;
	});

	if(betRadartableItems.length>0){

		$(document).ready(function () {
			$('#betRadartable').DataTable();
		});
		return betRadartableItems;
	}else{

		return null;
	}
};

export default BetRadarVenueMapList;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import GlobalEndpointList from '../../components/GlobalEndpoint/GlobalEndpointList';
import { fetchAllGlobalEndpoints, deleteGlobalEndpoint, resetGlobalEndpoints } from '../../store/entities/actions/GlobalEndpointActions';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';

import Button from "../../components/Ui/Button";
import Table from 'react-bootstrap/Table'
const GlobalEndpointListPage = (props) => {
	const { globalEndpoints, deleteGlobalEndpoint, fetchGlobalEndpoints } = props;

	useEffect(() => {
		fetchGlobalEndpoints();
	}, []);

	return (
		<div>
			<FlexText>
				<Heading>Global Endpoint List</Heading>
				<Link to="/global-endpoints/create">
					<Button color="primary" round>Create new Global Endpoint</Button>
				</Link>
			</FlexText>
       <Table id="gepTable" bordered  responsive={true}>
		   <thead>
			   <tr>
			   <th>Client Id</th>
			   <th>Endpoint</th>
			   <th>Action</th>
			   </tr>
		   </thead>
		    <tbody>
			 <GlobalEndpointList globalEndpoints={globalEndpoints} onDelete={deleteGlobalEndpoint} />
			</tbody>
		    </Table>
       </div>

	);
};

const mapStateToProps = (state) => {
	return {
		globalEndpoints: Controller.denormalize('globalEndpoints', state.entities),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteGlobalEndpoint: (globalEndpointId) => {
			return dispatch(deleteGlobalEndpoint(globalEndpointId)).finally(() => {
				dispatch(resetGlobalEndpoints());
			});
		},

		fetchGlobalEndpoints: () => {
			dispatch(fetchAllGlobalEndpoints());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(GlobalEndpointListPage);

import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import RcnListContainer from './RcnListContainer';
import RcnCreateContainer from './RcnCreateContainer';
import RcnEditContainer from './RcnEditContainer';

const RcnPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={RcnListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={RcnCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={RcnEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		rcn: Controller.denormalize('rcns', state.entities),
	};
};

export default connect(mapStateToProps)(RcnPage);

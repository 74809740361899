import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Load venue maps for the logged in client
 *
 * @param {*} embed
 */
const loadVenueMaps = (embed = []) => {
	return get('/admin/sis', {
		params: { with: embed },
	}).catch((e) => {
		throw new Error(`Couldn't load sis: ${e}`);
	});
};

/**
 * Fetch all venue maps for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllVenueMaps = (embed) => (dispatch) => {
	loadVenueMaps(embed).then(({ data }) => dispatch(mergeEntities(Controller.normalize('siss', data.data).entities)));
};

/**
 * Reset venue maps for logged in client
 *
 * @param {*} embed
 */
export const resetVenueMaps = (embed) => (dispatch) => {
	loadVenueMaps(embed).then(({ data }) =>
		dispatch(
			resetEntities({
				siss: {},
				...Controller.normalize('siss', data.data).entities,
			}),
		),
	);
};

const validateSisVenueMap = (sis) => {
	return sis;
};

/**
 * Create a sis
 *
 * @param {*} formData
 */
export const createSisVenueMap = (sis) => (dispatch) => {
	return post('/admin/sis', validateSisVenueMap(sis));
};

/**
 * Edit a sis given a sis id
 *
 * @param {*} sis
 */
export const editSisVenueMap = (sis) => (dispatch) => {
	return put(`/admin/sis/${sis.id}`, validateSisVenueMap(sis));
};
//
/**
 * Delete sis
 *
 * @param {*} sisId
 */
export const deleteSisVenueMap = (sisId) => (disaptch) => {
	return remove(`/admin/sis/${sisId}`);
};

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import { mergeEntities, resetEntities } from 'redux-entities-reducer';

export const fetchRaceUpdates = () => {

    return get('race-updates')
    .then(result => {
        return result.data.data;
    })
    .catch((e) => {
        throw new Error(`Couldn't load subscriptions: ${e}`);
    });
};


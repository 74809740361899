import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GlobalEndpointForm from '../../components/GlobalEndpoint/GlobalEndpointForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllGlobalEndpoints, editGlobalEndpoint } from '../../store/entities/actions/GlobalEndpointActions';
import { fetchAllClients } from '../../store/entities/actions/ClientActions';
import Card from '../../components/Ui/Card';

const GlobalEndpointEditContainer = ({ globalEndpoint, fetchGlobalEndpoints, createGlobalEndpoint, fetchClients, clients, history, editGlobalEndpoint, match, ...rest }) => {
	useEffect(() => {
		fetchGlobalEndpoints();
		fetchClients();
	}, []);

	function handleFormSubmit(formData) {
		editGlobalEndpoint({ ...globalEndpoint, ...formData }).then(() => {
			history.push('/global-endpoints');
		});
	}

	function handleCancel() {
		history.push('/global-endpoints');
	}

	return (
		<div>
			<Heading>Edit Global Endpoint</Heading>
			<Card>
				<GlobalEndpointForm onSubmit={handleFormSubmit} onCancel={handleCancel} globalEndpoint={globalEndpoint} clients={clients} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const globalEndpoints = Controller.denormalize('globalEndpoints', state.entities);
	const clients = Controller.denormalize('clients', state.entities);

	const globalEndpoint = globalEndpoints.find((globalEndpoint) => {
		return globalEndpoint.id == ownProps.match.params.id;
	});

	return {
        globalEndpoint,
		clients,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchClients: () => {
			dispatch(fetchAllClients());
        },

		fetchGlobalEndpoints: () => {
			dispatch(fetchAllGlobalEndpoints());
		},

		editGlobalEndpoint: (formData) => {
			return dispatch(editGlobalEndpoint(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(GlobalEndpointEditContainer);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BetmakersForm from '../../components/Betmakers/BetmakersForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllVenueMaps, editBetmakerVenueMap } from '../../store/entities/actions/BetmakersActions';
import Card from '../../components/Ui/Card';

const BetmakersEditContainer = ({ betmaker, fetchVenueMaps, history, editBetmakerVenueMap, match, ...rest }) => {
	useEffect(() => {
		fetchVenueMaps();
	}, []);

	function handleFormSubmit(formData) {
		editBetmakerVenueMap({ ...betmaker, ...formData }).then(() => {
			history.push('/betmakers');
		});
	}

	function handleCancel() {
		history.push('/betmakers');
	}

	return (
		<div>
			<Heading>Edit Betmakers Venue Maps</Heading>
			<Card>
				<BetmakersForm onSubmit={handleFormSubmit} onCancel={handleCancel} betmaker={betmaker} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const betmakers = Controller.denormalize('betmakers', state.entities);

	const betmaker = betmakers.find((betmaker) => {
		return betmaker.id == ownProps.match.params.id;
	});

	return {
		betmaker,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchVenueMaps: () => {
			dispatch(fetchAllVenueMaps());
		},

		editBetmakerVenueMap: (formData) => {
			return dispatch(editBetmakerVenueMap(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BetmakersEditContainer);

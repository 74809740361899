import React from "react";

import DataForm from "../../DataForm/DataForm";

import { Input, Select, SelectOption } from "@tbh/ui-kit";

class Races extends DataForm {
  state = {
    fields: {
      meeting_id: "",
      identifier: "",
      start_time: "",
      name: "",
      number: "",
      type: "r",
      number_of_runners: "",
      distance: "",
      class: "",
      number_of_pools: "",
      pool_identifiers: "",
      race_status_id: "",
      field_priority: ""
    }
  };

  render() {
    return (
      <fieldset>
        <Input
          name="meeting_id"
          value={this.state.fields.meeting_id}
          placeholder="meeting_id"
          onChange={this.handleChange}
          type="number"
        />
        <Input
          name="identifier"
          value={this.state.fields.identifier}
          placeholder="identifier"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="start_time"
          value={this.state.fields.start_time}
          placeholder="start_time"
          onChange={this.handleChange}
          type="datetime-local"
        />
        <Input
          name="name"
          value={this.state.fields.name}
          placeholder="name"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="number"
          value={this.state.fields.number}
          placeholder="number"
          onChange={this.handleChange}
          type="text"
        />
        <Select
          name="type"
          value={this.state.fields.type}
          onChange={this.handleChange}
        >
          <SelectOption label="r" value="r">
            r
          </SelectOption>
          <SelectOption label="g" value="g">
            g
          </SelectOption>
          <SelectOption label="h" value="h">
            h
          </SelectOption>
        </Select>
        <Input
          name="number_of_runners"
          value={this.state.fields.number_of_runners}
          placeholder="number_of_runners"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="distance"
          value={this.state.fields.distance}
          placeholder="distance"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="class"
          value={this.state.fields.class}
          placeholder="class"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="number_of_pools"
          value={this.state.fields.number_of_pools}
          placeholder="number_of_pools"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="pool_identifiers"
          value={this.state.fields.pool_identifiers}
          placeholder="pool_identifiers"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="race_status_id"
          value={this.state.fields.race_status_id}
          placeholder="race_status_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="field_priority"
          value={this.state.fields.field_priority}
          placeholder="field_priority"
          onChange={this.handleChange}
          type="text"
        />
      </fieldset>
    );
  }
}

export default Races;

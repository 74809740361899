import React from 'react';
import { FlexText, Heading ,Button } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { css } from 'react-emotion/macro';
import Card from '../Ui/Card';
import Table from 'react-bootstrap/Table'
import ButtonMaterial from "../Ui/Button";

const YoutubeStreamListItem = ({
    r_youtube_livestream_id,
    r_youtube_livestream_venue_key,
    r_youtube_livestream_stream_url,
    // created_at,
    // updated_at,
	editLink,
	onDelete,
	...rest
}) => {
	const handleDelete = (e) => {
		e.stopPropagation();
		onDelete && onDelete(r_youtube_livestream_id);
	};

	return (
		<tr>
            <td><Heading type="6">{r_youtube_livestream_id || '[Untitled]'}</Heading></td>
            <td><Heading type="6">{r_youtube_livestream_venue_key || '[Untitled]'}</Heading></td>
            <td><Heading type="6">{r_youtube_livestream_stream_url || '[Untitled]'}</Heading></td>
            {/* <td><Heading type="6">{created_at || '[Untitled]'}</Heading></td> */}
            {/* <td><Heading type="6">{updated_at || '[Untitled]'}</Heading></td> */}
            <td><div>
                <Link to={editLink}><ButtonMaterial color="primary">Edit</ButtonMaterial></Link><i onClick={handleDelete} ><ButtonMaterial color="danger" >Delete</ButtonMaterial></i>
            </div></td>
        </tr>
	);
};

export default YoutubeStreamListItem;

import React from 'react';
import PrivateRoute from "../../components/Route/PrivateRoute";
import MeetingPrioritiesList from "./MeetingPrioritiesList";
import CreateMeetingPriority from "./CreateMeetingPriority";
import EditMeetingPriority from "./EditMeetingPriority";

const MeetingPrioritiesPage = (props) => {
    const { match } = props;
    return (
        <>
            <PrivateRoute exact path={`${match.path}`} component={MeetingPrioritiesList} />
            <PrivateRoute path={`${match.path}/create`} component={CreateMeetingPriority} />
            <PrivateRoute path={`${match.path}/edit/:id`} component={EditMeetingPriority} />
        </>
    );
}

export default MeetingPrioritiesPage;
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SPORT_RESOURCES from '../../common/sportResources';

import CodePush from '../../components/CodePush';

const SportsPage = (props) => {
	return <CodePush resources={props.sportResources} />;
};

const mapStateToProps = () => {
	return {
		sportResources: SPORT_RESOURCES,
	};
};

SportsPage.propTypes = {
	/** The list of sport resources */
	sportResources: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(SportsPage);

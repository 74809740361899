import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import Hr1StreamMapList from '../../components/Hr1streamMap/Hr1StreamMapList';
import { fetchAllHr1Streams, deleteHr1Stream, resetHr1Streams } from '../../store/entities/actions/Hr1StreamAction';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import Button from "../../components/Ui/Button";

const Hr1StreamListPage = (props) => {
	const { hr1Stream, deleteHr1Stream, fetchHr1Streams } = props;

	useEffect(() => {
		fetchHr1Streams();
	}, []);
	return (
		<div>
			<FlexText>
				<Heading>HR1 Stream List</Heading>
				<Link to="/manage-hr1-stream/create">
					<Button color="primary" round>Create new HR1 Stream</Button>
				</Link>
			</FlexText>

			<Table bordered responsive={true} id="hr1StreamTable" width="100%">
				<thead>
					<tr>
						<th>Id</th>
						<th>HR1 Live Stream Venue Key</th>
						<th>HR1 Live Stream Venue URL</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<Hr1StreamMapList hr1Streams={hr1Stream} onDelete={deleteHr1Stream} />
				</tbody>
			</Table>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		hr1Stream: Controller.denormalize('hr1Stream', state.entities),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteHr1Stream: (id) => {
			return dispatch(deleteHr1Stream(id)).finally(() => {
				dispatch(resetHr1Streams());
			});
		},

		fetchHr1Streams: () => {
			dispatch(fetchAllHr1Streams());
		},
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Hr1StreamListPage);

import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import BetmakersListContainer from './BetmakersListContainer';
import BetmakersCreateContainer from './BetmakersCreateContainer';
import BetmakersEditContainer from './BetmakersEditContainer';

const BetmakersPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={BetmakersListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={BetmakersCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={BetmakersEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		betmakers: Controller.denormalize('betmakers', state.entities),
	};
};

export default connect(mapStateToProps)(BetmakersPage);

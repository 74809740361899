import React from 'react';
import { connect } from 'react-redux';
import BetmakersForm from '../../components/Betmakers/BetmakersForm';
import { Heading } from '@tbh/ui-kit';
import { createBetmakerVenueMap } from '../../store/entities/actions/BetmakersActions';
import Card from '../../components/Ui/Card';

const BetmakersCreateContainer = ({ createBetmakerVenueMap, history, ...rest }) => {
	function handleFormSubmit(formData) {
		createBetmakerVenueMap(formData).then(() => {
			history.push('/betmakers');
		});
	}

	function handleCancel() {
		history.push('/betmakers');
	}

	return (
		<div>
			<Heading>Create Betmakers Veneue Map</Heading>
			<Card>
				<BetmakersForm onSubmit={handleFormSubmit} onCancel={handleCancel} />
			</Card>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		createBetmakerVenueMap: (formData) => {
			return dispatch(createBetmakerVenueMap(formData));
		},
	};
};

export default connect(
	null,
	mapDispatchToProps,
)(BetmakersCreateContainer);

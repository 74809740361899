import React from "react";

import DataForm from "../../DataForm/DataForm";

import { Input } from "@tbh/ui-kit";

class Owners extends DataForm {
  state = {
    fields: {
      name: "",
      location: "",
      state: "",
      postcode: "",
      initials: ""
    }
  };

  render() {
    return (
      <fieldset>
        <Input
          name="name"
          value={this.state.fields.name}
          placeholder="name"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="location"
          value={this.state.fields.location}
          placeholder="location"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="state"
          value={this.state.fields.state}
          placeholder="state"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="postcode"
          value={this.state.fields.postcode}
          placeholder="postcode"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="initials"
          value={this.state.fields.initials}
          placeholder="initials"
          onChange={this.handleChange}
          type="text"
        />
      </fieldset>
    );
  }
}

export default Owners;

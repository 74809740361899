import React from "react";

import DataForm from "../../DataForm/DataForm";

import { Input } from "@tbh/ui-kit";

class Owners extends DataForm {
  state = {
    fields: {
      identifier: "",
      race_id: "",
      source: "",
      status: "",
      dividend_id: "",
      win_payout: "",
      place_payout: "",
      exotic_payout: "",
      first_position: "",
      second_position: "",
      third_position: "",
      fourth_position: ""
    }
  };

  render() {
    return (
      <fieldset>
        <Input
          name="identifier"
          value={this.state.fields.identifier}
          placeholder="identifier"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="race_id"
          value={this.state.fields.race_id}
          placeholder="race_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="source"
          value={this.state.fields.source}
          placeholder="source"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="status"
          value={this.state.fields.status}
          placeholder="status"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="dividend_id"
          value={this.state.fields.dividend_id}
          placeholder="dividend_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="win_payout"
          value={this.state.fields.win_payout}
          placeholder="win_payout"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="place_payout"
          value={this.state.fields.place_payout}
          placeholder="place_payout"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="exotic_payout"
          value={this.state.fields.exotic_payout}
          placeholder="exotic_payout"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="first_position"
          value={this.state.fields.first_position}
          placeholder="first_position"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="second_position"
          value={this.state.fields.second_position}
          placeholder="second_position"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="third_position"
          value={this.state.fields.third_position}
          placeholder="third_position"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="fourth_position"
          value={this.state.fields.fourth_position}
          placeholder="fourth_position"
          onChange={this.handleChange}
          type="text"
        />
      </fieldset>
    );
  }
}

export default Owners;

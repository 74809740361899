import React from 'react';
import { Heading, typography, spacings, LoadingMask} from '@tbh/ui-kit';
import Card from '../../components/Ui/Card';
import styled, {css} from "react-emotion";
import CreateMeetingPriorityForm from "../../components/MeetingPriorities/CreateMeetingPriorityForm";
import {createPriority} from "../../store/entities/actions/MeetingPrioritiesActions";

const Styled__Label = styled('label')(
    (props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

class CreateMeetingPriority extends React.Component
{
    state = {
        loading: true,
    };

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.hideLoading();
    }

    handleCancel() {
        this.props.history.push('/meeting-priorities');
    }

    handleSubmit(data) {
        this.showLoading();
        createPriority(data).then(() => {
            this.props.history.push('/meeting-priorities');
        }).catch((e) => {
            alert(e);
        })
    }

    showLoading(){
        this.setState({
            loading: true,
        });
    }

    hideLoading(){
        this.setState({
            loading: false,
        });
    }

    render() {

        const LoadingStyles = {
            left: '260px',
            background: '#ffffff4f',
        }

        return <div>
            <LoadingMask loading={this.state.loading} style={LoadingStyles}/>
            <Heading>Create Priority</Heading>
            <Card>
                <CreateMeetingPriorityForm onSubmit={this.handleSubmit} onCancel={this.handleCancel}/>
            </Card>
        </div>
    }
}

export default CreateMeetingPriority;
import React, { useState, useEffect } from 'react';
import { typography, Select, SelectOption, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const YoutubeStreamForm = (props) => {
	const { onSubmit, onCancel, youtubeStream } = props;
    const[btn_load,setBtnLoad] = useState();
	const [r_youtube_livestream_venue_key, setYoutubeStreamVenueKey] = useState();
	const [r_youtube_livestream_stream_url, setYoutubeStreamUrl] = useState();

	useEffect(() => {
		setBtnLoad(false);

		setYoutubeStreamVenueKey(youtubeStream.r_youtube_livestream_venue_key);
		setYoutubeStreamUrl(youtubeStream.r_youtube_livestream_stream_url);
	}, [youtubeStream]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			r_youtube_livestream_venue_key,
			r_youtube_livestream_stream_url,
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
    }


	return (
		<form onSubmit={handleSubmit}>

			<Styled__Label>Venue Key</Styled__Label>
			<Input value={r_youtube_livestream_venue_key} onChange={(r_youtube_livestream_venue_key, value) => setYoutubeStreamVenueKey(value)} />

			<Styled__Label>Stream Url</Styled__Label>
			<Input value={r_youtube_livestream_stream_url} onChange={(r_youtube_livestream_stream_url, value) => setYoutubeStreamUrl(value)} />
			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} size="large" loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>

		</form>
	);
};

YoutubeStreamForm.defaultProps = {
	youtubeStream: {
		r_youtube_livestream_venue_key: '',
		r_youtube_livestream_stream_url: '',
	},
};

export default YoutubeStreamForm;

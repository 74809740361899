import React from 'react';
import styled, { css } from 'react-emotion';
import GwsListItem from './GwsListItem';
import { MANAGE_GWS_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const Styled__GwsListItem = styled(GwsListItem)(
	(props) => css`
		padding-top: 20px;
		border-bottom: 1px solid grey;
	`,
);
const GwsList = ({ gwss, onDelete }) => {

	let gwsListItem= gwss.map((gws) => {
		return <Styled__GwsListItem key={gws.id} onDelete={onDelete} {...gws} editLink={`${MANAGE_GWS_ROUTE}/edit/${gws.id}`} />;
	});
	if(gwsListItem.length>0){

		$(document).ready(function () {
			$('#gwsTable').DataTable();
		});
		return gwsListItem;
	}else{

		return null;
	}
};

export default GwsList;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BetRadarVenueMapForm from '../../components/BetRadarVenueMap/BetRadarVenueMapForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { createBetRadarVenueMap } from '../../store/entities/actions/BetRadarVenueMapAction';
import Card from '../../components/Ui/Card';

const BetRadarVenueCreateContainer = ({ createBetRadarVenueMap, history, ...rest }) => {
    
	function handleFormSubmit(formData) {
		createBetRadarVenueMap(formData).then(() => {
			history.push('/betradar-venue-map');
		});
	}

	function handleCancel() {
		history.push('/betradar-venue-map');
	}

	return (
		<div>
			<Heading>Map BetRadar Venue</Heading>
			<Card>
				<BetRadarVenueMapForm onSubmit={handleFormSubmit} onCancel={handleCancel}  />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = (dispatch) => {
	return {
		createBetRadarVenueMap: (formData) => {
			return dispatch(createBetRadarVenueMap(formData));
        },
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BetRadarVenueCreateContainer);

import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Link } from 'react-router-dom';

import { getAuthenticatedUser } from './store/application/applicationSelectors';
import { fetchAuthenticatedUser, logout } from './store/application/applicationActions';

import { Notification, HeaderBar, Text, PlotElements, LoadingMask } from '@tbh/ui-kit';

import {
	BASE_ROUTE,
	LOGIN_ROUTE,
	LOGOUT_ROUTE,
	RACING_ROUTE,
	SPORTS_ROUTE,
	USER_ROUTE,
	SUBSCRIPTIONS_ROUTE,
	SUBSCRIPTION_RACE_CARD_ROUTE,
	RCN_ROUTE,
	RCN_GBS_ROUTE,
	GLOBAL_ENDPOINT_ROUTE,
    INDIVIDUAL_ENDPOINT_ROUTE,
    SQS_QUEUE_ROUTE,
    BETMAKERS_ROUTE,
	SIS_ROUTE,
	PRICES_ROUTE,
	MEETING_PRIORITIES_ROUTE,
	MANAGE_PRODUCT_ROUTE,
	BET_RADAR_VENUE_MAP_ROUTE,
	MANAGE_HR1_STREAM_ROUTE,
	MANAGE_GWS_ROUTE,
	YOUTUBE_STREAM_ROUTE,
	GENERATE_GBS_TOKEN_ROUTE,
	SETTINGS_ROUTE
} from './constants/RouteConstants';

import AdminPortal from './components/Layouts/AdminPortal';
import CodePush from './components/CodePush';
import PrivateRoute from './components/Route/PrivateRoute';

import UserPage from './pages/User/UserPage';
import LoginPage from './pages/User/LoginPage';
import LogoutPage from './pages/User/LogoutPage';
import RacingPage from './pages/Racing/RacingPage';
import SubscriptionRaceCardPage from './pages/SubscriptionRaceCard/SubscriptionRaceCardPage';
import SportsPage from './pages/Sports/SportsPage';
import HomePage from './pages/Home/HomePage';
import SubscriptionsPage from './pages/Subscriptions/SubscriptionsPage';
import RcnPage from './pages/Rcn/RcnPage';
import RcnGbsMapPage from './pages/RcnGbsMap/RcnGbsMapPage';
import SisPage from './pages/Sis/SisPage';
import GlobalEndpointPage from './pages/GlobalEndpoint/GlobalEndpointPage';
import IndividualEndpointPage from './pages/IndividualEndpoint/IndividualEndpointPage';
import SqsQueuePage from './pages/SqsQueue/SqsQueuePage';
import BetmakersPage from './pages/Betmakers/BetmakersPage';
import PricesPage from './pages/Prices/PricesPage';
import MeetingPrioritiesPage from './pages/MeetingPriorities/MeetingPrioritiesPage';
import ProductPage from './pages/Products/ProductPage';
import BetRadarVenueMapPage from './pages/BetRadarVenueMap/BetRadarVenueMapPage';
import Hr1StreamPage from './pages/Hr1/Hr1StreamPage';
import GwsPage from './pages/Gws/GwsPage';
import YoutubeStreamPage from './pages/YoutubeStream/YoutubeStreamPage';
import GenerateGbsTokenPage from './pages/GenerateGbsToken/GenerateGbsTokenPage';
import SettingsPage from './pages/Settings/SettingsPage';

class App extends React.Component {
	state = {
		loading: true,
		hasError: false,
	};

	componentDidMount() {
		this.props.fetchAuthenticatedUser().finally(() => {
			this.setState({
				loading: false,
			});
		});
	}

	componentDidCatch(error, info) {
		console.error(error, info);
		this.setState({ hasError: true });
	}

	get404() {
		if (process.env.NODE_ENV !== 'production') {
			return (
				<div>
					<HeaderBar type={HeaderBar.types.PRIMARY}>Whoops - 404</HeaderBar>
					<Notification type={Notification.types.COLOUR_DANGER} />
				</div>
			);
		}

		return <div>Route Not Found</div>;
	}

	render() {
		const { theme, authenticatedUser } = this.props;
		const { loading, hasError } = this.state;

		if (hasError) {
			return (
				<PlotElements align="middle" alignElements="middle">
					<Text>Something has gone wrong.</Text>
				</PlotElements>
			);
		}

		// Don't render the admin wrapper if there is no authenticated user
		const AdminWrapper = authenticatedUser ? AdminPortal : 'div';

		return (
			<ThemeProvider theme={theme}>
				<AdminWrapper>
					<LoadingMask loading={loading} solid />
					{!loading && (
						<Switch>
							<Route path={LOGIN_ROUTE} component={LoginPage} />
							<PrivateRoute path="/crud" component={CodePush} />
							<PrivateRoute path={RACING_ROUTE} component={RacingPage} />
							<PrivateRoute path={SUBSCRIPTIONS_ROUTE} component={SubscriptionsPage} />
							<PrivateRoute
								exact={false}
								path={`${SUBSCRIPTION_RACE_CARD_ROUTE}/:id/:name?/:raceId?`}
								component={SubscriptionRaceCardPage}
							/>
							<PrivateRoute path={MANAGE_GWS_ROUTE} component={GwsPage} />
							<PrivateRoute path={BET_RADAR_VENUE_MAP_ROUTE} component={BetRadarVenueMapPage} />
							<PrivateRoute path={MANAGE_HR1_STREAM_ROUTE} component={Hr1StreamPage} />
							<PrivateRoute path={MANAGE_PRODUCT_ROUTE} component={ProductPage} />
							<PrivateRoute path={MEETING_PRIORITIES_ROUTE} component={MeetingPrioritiesPage} />
							<PrivateRoute path={PRICES_ROUTE} component={PricesPage} />
							<PrivateRoute path={RCN_ROUTE} component={RcnPage} />
							<PrivateRoute path={RCN_GBS_ROUTE} component={RcnGbsMapPage} />
							<PrivateRoute path={SIS_ROUTE} component={SisPage} />
							<PrivateRoute path={YOUTUBE_STREAM_ROUTE} component={YoutubeStreamPage} />
							<PrivateRoute path={GLOBAL_ENDPOINT_ROUTE} component={GlobalEndpointPage} />
							<PrivateRoute path={INDIVIDUAL_ENDPOINT_ROUTE} component={IndividualEndpointPage} />
							<PrivateRoute path={SQS_QUEUE_ROUTE} component={SqsQueuePage} />
							<PrivateRoute path={BETMAKERS_ROUTE} component={BetmakersPage} />
							<PrivateRoute path={SPORTS_ROUTE} component={SportsPage} />
							<PrivateRoute path={USER_ROUTE} component={UserPage} />
							<PrivateRoute path={LOGOUT_ROUTE} component={LogoutPage} />
							<PrivateRoute exact path={BASE_ROUTE} component={HomePage} />
							<PrivateRoute path={GENERATE_GBS_TOKEN_ROUTE} component={GenerateGbsTokenPage} />
							<PrivateRoute path={SETTINGS_ROUTE} component={SettingsPage} />

							<Route>
								<ul>
									{!authenticatedUser && (
										<li>
											<Link to={LOGIN_ROUTE}>Login</Link>
										</li>
									)}
									{authenticatedUser && (
										<li>
											<Link to={BASE_ROUTE}>Home</Link>
										</li>
									)}
								</ul>
							</Route>
						</Switch>
					)}
				</AdminWrapper>
			</ThemeProvider>
		);
	}
}

App.propTypes = {
	/** The app theme */
	theme: PropTypes.object.isRequired,

	/** Action to fetch the currently authenticated user */
	fetchAuthenticatedUser: PropTypes.func.isRequired,

	/** Logout action */
	logout: PropTypes.func.isRequired,

	/** The currently authenticated user */
	authenticatedUser: PropTypes.object,
};

App.defaultProps = {
	authenticatedUser: null,
};

const mapStateToProps = (state) => {
	const authenticatedUser = getAuthenticatedUser(state);

	return {
		authenticatedUser,
		theme: state.theme,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAuthenticatedUser: () => {
			return dispatch(fetchAuthenticatedUser());
		},

		logout: () => dispatch(logout()),
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(App),
);

export const BASE_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const RACING_ROUTE = '/racing';
export const SPORTS_ROUTE = '/sports';
export const USER_ROUTE = '/user';
export const SUBSCRIPTIONS_ROUTE = '/subscriptions';
export const SUBSCRIPTION_RACE_CARD_ROUTE = '/race-card';
export const RCN_ROUTE = '/rcn';
export const RCN_GBS_ROUTE = '/rcn-gbs-map';
export const GLOBAL_ENDPOINT_ROUTE = '/global-endpoints';
export const INDIVIDUAL_ENDPOINT_ROUTE = '/individual-endpoints';
export const SQS_QUEUE_ROUTE = '/sqs-queues';
export const BETMAKERS_ROUTE = '/betmakers';
export const SIS_ROUTE = '/sis';
export const YOUTUBE_STREAM_ROUTE = '/youtube-streams';
export const PRICES_ROUTE = '/prices';
export const MEETING_PRIORITIES_ROUTE = '/meeting-priorities';
export const MANAGE_PRODUCT_ROUTE = '/manage-products';
export const BET_RADAR_VENUE_MAP_ROUTE = '/betradar-venue-map';
export const MANAGE_HR1_STREAM_ROUTE = '/manage-hr1-stream';
export const MANAGE_GWS_ROUTE = '/manage-gws';
export const SETTINGS_ROUTE = '/settings';
export const GENERATE_GBS_TOKEN_ROUTE = '/generate-gbs-token';
import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import SubscriptionsListContainer from './SubscriptionsListContainer';
import SubscriptionsCreateContainer from './SubscriptionsCreateContainer';
import SubscriptionsEditContainer from './SubscriptionsEditContainer';
import SubscriptionsTestContainer from './SubscriptionsTestContainer';

const SubscriptionsPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={SubscriptionsListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={SubscriptionsCreateContainer} />
			<PrivateRoute path={`${match.path}/test/:id`} component={SubscriptionsTestContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={SubscriptionsEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		subscriptions: Controller.denormalize('subscriptions', state.entities),
	};
};

export default connect(mapStateToProps)(SubscriptionsPage);

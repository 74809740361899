import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import IndividualEndpointForm from '../../components/IndividualEndpoint/IndividualEndpointForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllIndividualEndpoints, editIndividualEndpoint } from '../../store/entities/actions/IndividualEndpointActions';
import { fetchSubscriptionsForCurrentUser } from '../../store/entities/actions/SubscriptionActions';
import { fetchAllClients } from '../../store/entities/actions/ClientActions';
import Card from '../../components/Ui/Card';

const IndividualEndpointEditContainer = ({ individualEndpoint, subscriptions, fetchSubscriptions, clients, fetchClients, fetchIndividualEndpoints, createIndividualEndpoint, history, editIndividualEndpoint, match, ...rest }) => {
	useEffect(() => {
		fetchIndividualEndpoints();
		fetchSubscriptions();
		fetchClients();
	}, []);

	function handleFormSubmit(formData) {
		editIndividualEndpoint({ ...individualEndpoint, ...formData }).then(() => {
			history.push('/individual-endpoints');
		});
	}

	function handleCancel() {
		history.push('/individual-endpoints');
	}

	return (
		<div>
			<Heading>Edit Individual Endpoint</Heading>
			<Card>
				<IndividualEndpointForm onSubmit={handleFormSubmit} onCancel={handleCancel} individualEndpoint={individualEndpoint} subscriptions={subscriptions} clients={clients} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const individualEndpoints = Controller.denormalize('individualEndpoints', state.entities);
	const subscriptions = Controller.denormalize('subscriptions', state.entities);
	const clients = Controller.denormalize('clients', state.entities);

	const individualEndpoint = individualEndpoints.find((individualEndpoint) => {
		return individualEndpoint.id == ownProps.match.params.id;
	});

	return {
		individualEndpoint,
		subscriptions,
		clients,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchIndividualEndpoints: () => {
			dispatch(fetchAllIndividualEndpoints());
        },
        
		fetchSubscriptions: () => {
			dispatch(fetchSubscriptionsForCurrentUser());
		},
        
		fetchClients: () => {
			dispatch(fetchAllClients());
		},

		editIndividualEndpoint: (formData) => {
			return dispatch(editIndividualEndpoint(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndividualEndpointEditContainer);

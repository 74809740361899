import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { getAuthenticatedUser } from '../../store/application/applicationSelectors';

/**
 * Only authenticated users can access these routes otherwise redirect to the login page
 */
const PrivateRoute = ({ component: Component, authenticatedUser, children, ...rest }) => {
	const RouteComponent = (
		<Route
			{...rest}
			render={(props) =>
				authenticatedUser ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)
			}
		/>
	);

	if (children) {
		return (
			<div>
				{children}
				{RouteComponent}
			</div>
		);
	}

	return RouteComponent;
};

PrivateRoute.propTypes = {
	/** The route path */
	path: PropTypes.string.isRequired,

	/** The component to render */
	component: PropTypes.func.isRequired,

	/** Require an exact match for the path */
	exact: PropTypes.bool,

	/** The currently authenticated user */
	authenticatedUser: PropTypes.object,
};

PrivateRoute.defaultProps = {
	exact: false,
	authenticatedUser: null,
};

const mapStateToProps = (state) => {
	const authenticatedUser = getAuthenticatedUser(state);

	return {
		authenticatedUser,
	};
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));

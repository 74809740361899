import React from 'react';
import styled, { css } from 'react-emotion';

const HEIGHT = 20;
const WIDTH = 30;

const Styled__Knob = styled('span')(
	(props) => css`
		@keyframes colorChange {
			from {
				background-color: #ccc;
			}
			50% {
				background-color: #a4d9ad;
			}
			to {
				background-color: #4bd663;
			}
		}
		@keyframes turnON {
			from {
				transform: translateX(0px);
			}
			to {
				transform: translateX(${WIDTH - HEIGHT * 0.99}px);
				/* box-shadow: -10px 0px 44px 0px #434343; */
			}
		}
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		border-radius: ${HEIGHT}px;

		:before {
			position: absolute;
			background-color: white;
			content: '';
			left: ${HEIGHT * 0.1}px;
			top: ${HEIGHT * 0.1}px;
			width: ${HEIGHT * 0.8}px;
			height: ${HEIGHT * 0.8}px;
			border-radius: 50%;
		}

		${props.checked &&
			css`
				animation: colorChange 0.2s linear forwards;

				:before {
					animation: turnON 0.2s linear forwards;
				}
			`}
	`,
);

const Styled__Switch = styled('label')(
	() => css`
		position: relative;
		display: inline-block;
		width: ${WIDTH}px;
		height: ${HEIGHT}px;

		input[type='checkbox'] {
			display: none;
		}
	`,
);

const Switch = ({ label, checked, onChange, disabled = false }) => {
	const handleChange = (...rest) => {
		if (!disabled) {
			onChange(...rest);
		}
	};

	return (
		<Styled__Switch>
			<input type="checkbox" checked={checked} value={checked} defaultChecked={checked} onChange={onChange} />
			<Styled__Knob checked={checked} />
			<br />
			<br />
			{label && <label>{label}</label>}
		</Styled__Switch>
	);
};

export default Switch;

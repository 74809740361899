import React from 'react';
import { FlexText, Heading ,Button } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { css } from 'react-emotion/macro';
import Card from '../Ui/Card';
import Table from 'react-bootstrap/Table'
import ButtonMaterial from "../Ui/Button";
const ProductListItem = ({
	id,
	venue_key,
	gbs_name,
	name,
	editLink,
	onDelete,
	...rest
}) => {
	const handleDelete = (e) => {
		e.stopPropagation();
		onDelete && onDelete(id);
	};

	return (
		<tr>
			<td><Heading type="6">{id|| '[Untitled]'}</Heading></td>
			<td><Heading type="6">{venue_key || '[Untitled]'}</Heading></td>
			<td>
				<Heading type="6">{gbs_name || '[Untitled]'}</Heading></td>
			<td>
				<Heading type="6">{name || '[Untitled]'}</Heading></td>

			<td><div>
				<Link to={editLink}><ButtonMaterial color="primary">Edit</ButtonMaterial></Link><i onClick={handleDelete} ><ButtonMaterial color="danger" >Delete</ButtonMaterial></i>
			</div></td>
		</tr>
	);
};

export default ProductListItem;

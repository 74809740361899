import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import { fetchAllSources } from '../../store/entities/actions/SourceActions';
import { Heading, Button, Tabs, TabsItem } from '@tbh/ui-kit';
import { fetchSubscriptionsForCurrentUser, editSubscription } from '../../store/entities/actions/SubscriptionActions';
import Card from '../../components/Ui/Card';
import { get, post, put, remove } from '../../common/ajax';
import JSONTree from 'react-json-tree';

const TABS = [
	{
		id: 'meeting',
		url: '/meeting',
	},
	{
		id: 'race',
		url: '/race',
	},
	{
		id: 'runner',
		url: '/runner',
	},
	{
		id: 'results',
		url: '/results',
	},
	{
		id: 'selection',
		url: '/selection',
	},
	{
		id: 'trainer',
		url: '/trainer',
	},
	{
		id: 'owner',
		url: '/owner',
	},
];

const SubscriptionsTestContainer = ({ subscription, fetchSubscriptions, history }) => {
	const [data, setSubscriptionData] = useState({});
	const [selectedTab, setSelectedTab] = useState(TABS[0].id);

	useEffect(fetchSubscriptions, []);
	useEffect(() => {
		// If no subscription is set yet, then just wait until it changes
		if (!subscription.id) {
			return;
		}

		post(`/racing/subscriptions/${subscription.id}/${selectedTab}`, { params: { with: 'races' } }).then((response) => {
			setSubscriptionData(response.data.data);
		});
	}, [subscription.id, selectedTab]);

	function handleCancel() {
		history.push('/subscriptions');
	}

	return (
		<div>
			<Heading>Test Subscription</Heading>
			<Tabs>
				{TABS.map((tab) => (
					<TabsItem action={() => setSelectedTab(tab.id)} active={tab.id === selectedTab}>
						{tab.id}
					</TabsItem>
				))}
			</Tabs>
			<Card>
				<JSONTree hideRoot data={data} theme={'google'} />
				<Button action={handleCancel} size="large" type="secondary">Back</Button>
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const subscriptions = Controller.denormalize('subscriptions', state.entities);

	const subscription =
		subscriptions.find((subscription) => {
			return subscription.id == ownProps.match.params.id;
		}) || {};

	return {
		subscription,
		sources: Controller.denormalize('sources', state.entities),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSubscriptions: () => {
			dispatch(fetchAllSources());
			dispatch(fetchSubscriptionsForCurrentUser());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SubscriptionsTestContainer);

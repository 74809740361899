import React, { useState, useEffect } from 'react';
import { typography, Select, SelectOption, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const BetRadarVenueMapForm = (props) => {
	const { onSubmit, onCancel, betRadarVenueMaps } = props;
    const[btn_load,setBtnLoad] = useState();
	const [venue_key, setVenueKey] = useState();
	const [gbs_name, setGbsName] = useState();
	const [name, setName] = useState();

	useEffect(() => {
		setBtnLoad(false);

		setVenueKey(betRadarVenueMaps.venue_key);
		setGbsName(betRadarVenueMaps.gbs_name);
		setName(betRadarVenueMaps.name);
	}, [betRadarVenueMaps]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			venue_key,
			gbs_name,
			name,
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
    }


	return (
		<form onSubmit={handleSubmit}>

			<Styled__Label>Venue Key</Styled__Label>
			<Input value={venue_key} onChange={(name, value) => setVenueKey(value)} />

			<Styled__Label>GBS Name</Styled__Label>
			<Input value={gbs_name} onChange={(name, value) => setGbsName(value)} />
			<Styled__Label>Name</Styled__Label>
			<Input value={name} onChange={(name, value) => setName(value)} />
			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} size="large" loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>

		</form>
	);
};

BetRadarVenueMapForm.defaultProps = {
	betRadarVenueMaps: {
		venue_key: '',
		gbs_name: '',
		name: '',
	},
};

export default BetRadarVenueMapForm;

import { createController, hasOne, hasMany, Entity } from 'redux-entities-reducer';

import strategies, { renameKeys } from 'normalizr-strategies';

// import { addServerTimezone } from './strategies';

const Controller = createController();

if (process.env.NODE_ENV !== 'production') {
	window.EntitiesController = Controller;
}

/**
 *
 * @param {*} keys
 * @param {*} comparator
 */
const setSourceOrder = (keys, comparator) => {
	return {
		strategy: function(config, subscription) {
			let newEntity = {
				...subscription,
			};

			// If there are no sources, just return the entity
			if (!subscription.sources) {
				newEntity.sourceOrder = []; // Set the property so it always exists
				return newEntity;
			}

			// Take a copy of the sources
			let sourceList = subscription.sources.slice();

			// Sort the sources by their pivot ordinal
			sourceList.sort((a, b) => {
				return a.pivot.source_subscriptions_ordinal < b.pivot.source_subscriptions_ordinal ? 1 : -1;
			});

			// Extract the ID's into an array
			newEntity.sourceOrder = sourceList.map((source) => source.id);

			return newEntity;
		},
	};
};

const deleteKey = (keys, comparator) => {
	return {
		strategy: function(config, value) {
			let newEntity = {
				...value,
			};

			delete newEntity[keys];

			return newEntity;
		},
	};
};

const USER = 'user';
const MEETINGS = 'meetings';
const RACES = 'races';
const RUNNERS = 'runners';
const SELECTIONS = 'selections';
const SUBSCRIPTIONS = 'subscriptions';
const SOURCES = 'sources';
const CLIENT = 'client';
const RCN = 'rcns';
const SIS = 'siss';
const GLOBAL_ENDPOINTS = 'globalEndpoints';
const INDIVIDUAL_ENDPOINTS = 'individualEndpoints';
const CLIENTS = 'clients';
const BETMAKERS = 'betmakers';
const DELIVERY_TYPES = 'deliveryTypes';
const SQS_QUEUES = 'sqsQueues';
const PRODUCTS = 'products';
const RCN_GBS_MAPS = 'rcnGbsMaps';
const BET_RADAR_VENUE_MAPS = 'betRadarVenueMaps';
const HR1_STREAM = 'hr1Stream';
const GWS = 'gwss';
const YOUTUBE_STREAMS = 'youtubeStreams';

Controller.register(USER);
Controller.register(CLIENT);
Controller.register(
	SOURCES,
	{},
	{
		processStrategy: strategies(deleteKey('pivot')),
	},
);

Controller.register(
	SUBSCRIPTIONS,
	{
		sources: hasMany(SOURCES),
		client: hasOne(CLIENT),
	},
	{
		processStrategy: strategies(setSourceOrder()),
	},
);

Controller.register(
	MEETINGS,
	{
		races: hasMany(RACES),
	},
	{
		idAttribute: 'meetings_id',
	},
);

Controller.register(
	RACES,
	{
		runners: hasMany(RUNNERS),
		meeting: hasOne(MEETINGS),
	},
	{
		idAttribute: 'races_id',
	},
);

Controller.register(
	RUNNERS,
	{
		selections: hasMany(SELECTIONS),
	},
	{ idAttribute: 'runners_id' },
);

Controller.register(SELECTIONS);

Controller.register(RCN);
Controller.register(GLOBAL_ENDPOINTS);
Controller.register(INDIVIDUAL_ENDPOINTS);
Controller.register(DELIVERY_TYPES);
Controller.register(CLIENTS);
Controller.register(SQS_QUEUES);
Controller.register(BETMAKERS);
Controller.register(SIS);

Controller.register(
	PRODUCTS,
	{
		selections: hasMany(PRODUCTS),
	},
	{ idAttribute: 'id' },
);

Controller.register(
	YOUTUBE_STREAMS,
	{
		selections: hasMany(YOUTUBE_STREAMS),
	},
	{ idAttribute: 'r_youtube_livestream_id' },
);

Controller.register(RCN_GBS_MAPS);
Controller.register(BET_RADAR_VENUE_MAPS);
Controller.register(HR1_STREAM);
Controller.register(GWS);

export default Controller.init();

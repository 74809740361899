import React from 'react';
import styled, { css } from 'react-emotion';
import Hr1StreamMapListItem from './Hr1StreamMapListItem';
import { MANAGE_HR1_STREAM_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import { spacings } from '@tbh/ui-kit';

const Styled__Hr1StreamListItem = styled(Hr1StreamMapListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);
const Hr1StreamMapList = ({ hr1Streams, onDelete }) => {

	let hr1StreamListItem= hr1Streams.map((hr1Stream) => {
		return <Styled__Hr1StreamListItem key={hr1Stream.id} onDelete={onDelete} {...hr1Stream} editLink={`${MANAGE_HR1_STREAM_ROUTE}/edit/${hr1Stream.id}`} />;
	});
	if (hr1StreamListItem.length>0){

		$(document).ready(function () {
		 	$('#hr1StreamTable').DataTable();
		});
		return hr1StreamListItem;
	}else{

		return null;
	}
};

export default Hr1StreamMapList;

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import { mergeEntities, resetEntities } from 'redux-entities-reducer';

/**
 * Load venue maps for the logged in client
 *
 * @param {*} embed
 */
const loadVenueMaps = (embed = []) => {
	return get('/admin/betmakers', {
		params: { with: embed },
	}).catch((e) => {
		throw new Error(`Couldn't load subscriptions: ${e}`);
	});
};

/**
 * Fetch all venue maps for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllVenueMaps = (embed) => (dispatch) => {
	loadVenueMaps(embed).then(({ data }) =>
		dispatch(mergeEntities(Controller.normalize('betmakers', data.data).entities)),
	);
};

/**
 * Reset venue maps for logged in client
 *
 * @param {*} embed
 */
export const resetVenueMaps = (embed) => (dispatch) => {
	loadVenueMaps(embed).then(({ data }) =>
		dispatch(
			resetEntities({
				betmakers: {},
				...Controller.normalize('betmakers', data.data).entities,
			}),
		),
	);
};

const validateBetmakerVenueMap = (betmakers) => {
	return betmakers;
};

/**
 * Create a betmakers venue map
 *
 * @param {*} formData
 */
export const createBetmakerVenueMap = (betmakers) => (dispatch) => {
	return post('/admin/betmakers', validateBetmakerVenueMap(betmakers));
};

/**
 * Edit a betmakers venue map given a betmakers venue id
 *
 * @param {*} betmakers
 */
export const editBetmakerVenueMap = (betmaker) => (dispatch) => {
	return put(`/admin/betmakers/${betmaker.id}`, validateBetmakerVenueMap(betmaker));
};
//
/**
 * Delete betmaker venue map
 *
 * @param {*} betmakerId
 */
export const deleteBetmakerVenueMap = (betmakerId) => (disaptch) => {
	return remove(`/admin/betmakers/${betmakerId}`);
};

import React, { useEffect, useState } from 'react';
import '../../assets/css/prices/single.css';

const showBlock = (update) => {
    if (update) {
        if (update.status == 'error') {
            return <td className="td-error">
                        {update.timestamp}
                        <div className="popup-error">
                            <p>{update.message}</p>
                        </div>
                    </td>;
        }else{
            return <td className="td-success">{update.timestamp}</td>;
        }
    }

    return '';
}

const PriceUpdatesBlock = (props) => {

    let update = props.updates;

    return  (
        <tbody>
            <tr>
                {showBlock(update.checked)}
            </tr>
        </tbody>
    );
};

export default PriceUpdatesBlock;

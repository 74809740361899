import React, { useState, useEffect } from 'react';
import { typography, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const BetmakersForm = (props) => {
	const { onSubmit, onCancel, betmaker } = props;
    const[btn_load,setBtnLoad] = useState();
	const [venue_key, setVenueKey] = useState();
	const [code, setCode] = useState();
	const [volume, setVolume] = useState();
	const [wowza_ull_flag, setWowzaUllFlag] = useState();

	useEffect(() => {
		setBtnLoad(false);
		setWowzaUllFlag(betmaker.wowza_ull_flag);
		setVenueKey(betmaker.venue_key);
		setCode(betmaker.code);
		setVolume(betmaker.volume);
	}, [betmaker]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			wowza_ull_flag,
			venue_key,
			code,
			volume
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Styled__Label>Venue Key</Styled__Label>
			<Input value={venue_key} onChange={(name, value) => setVenueKey(value)} />
			<Styled__Label>Code</Styled__Label>
			<Input value={code} onChange={(name, value) => setCode(value)} />
			<Styled__Label>Volume</Styled__Label>
            <Input value={volume} onChange={(name, value) => setVolume(parseFloat(value? value : 0))}  type="number"  />
			<Styled__Label>Wowza ULL Flag</Styled__Label>
			<Input value={wowza_ull_flag} onChange={(name, value) => setWowzaUllFlag(value)}  />
			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} loading={btn_load} size="large" onSubmit={handleSubmit}>
				Submit
			</Button>
		</form>
	);
};

BetmakersForm.defaultProps = {
	betmaker: {
		venue_key: '',
		code: '',
		volume:0,
		wowza_ull_flag:0
	},
};

export default BetmakersForm;

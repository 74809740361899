import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';

/**
 * Load delivery types for the logged in client
 *
 * @param {*} embed
 */
const loadDeliveryTypes = (embed = []) => {
    return get('/admin/delivery-types', {
        params: { with: embed },
    }).catch((e) => {
        throw new Error(`Couldn't load delivery types: ${e}`);
    });
};

/**
 * Fetch all delivery types for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllDeliveryTypes = (embed) => (dispatch) => {
    loadDeliveryTypes(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('deliveryTypes', data.data).entities)),
    );
};

/**
 * Reset delivery types for logged in client
 *
 * @param {*} embed
 */
export const resetDeliveryTypes = (embed) => (dispatch) => {
    loadDeliveryTypes(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                deliveryTypes: {},
                ...Controller.normalize('deliveryTypes', data.data).entities,
            }),
        ),
    );
};

import { get, post } from '../../../common/ajax';
import Controller from '../controller';
import { mergeEntities } from 'redux-entities-reducer';

export const fetchRaceForSubscription = (subscriptionId, raceId, embed = ['runners']) => (dispatch) => {
	return post(`/racing/subscriptions/${subscriptionId}/race/${raceId}`, { prices: true, with: embed })
		.catch((e) => {
			throw new Error(`Couldn't load next to jump: ${e}`);
		})
		.then(({ data }) => dispatch(mergeEntities(Controller.normalize('races', data.data).entities)));
};

export const fetchNextToJumpRacesForSubscription = (subscriptionId, limit = 500, embed = ['meeting']) => (dispatch) => {
	return post(`/racing/subscriptions/${subscriptionId}/nextToJump`, {
		limit,
		with: embed,
	})
		.catch((e) => {
			throw new Error(`Couldn't load next to jump: ${e}`);
		})
		.then(({ data }) => dispatch(mergeEntities(Controller.normalize('races', data.data).entities)));
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RcnForm from '../../components/Rcn/RcnForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllVenueMaps, editRcnVenueMap } from '../../store/entities/actions/RcnActions';
import Card from '../../components/Ui/Card';

const RcnEditContainer = ({ rcn, fetchVenueMaps, createRcnVenueMap, history, editRcnVenueMap, match, ...rest }) => {
	useEffect(() => {
		fetchVenueMaps();
	}, []);

	function handleFormSubmit(formData) {
		editRcnVenueMap({ ...rcn, ...formData }).then(() => {
			history.push('/rcn');
		});
	}

	function handleCancel() {
		history.push('/rcn');
	}

	return (
		<div>
			<Heading>Edit Venue Maps</Heading>
			<Card>
				<RcnForm onSubmit={handleFormSubmit} onCancel={handleCancel} rcn={rcn} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const rcns = Controller.denormalize('rcns', state.entities);

	const rcn = rcns.find((rcn) => {
		return rcn.id == ownProps.match.params.id;
	});

	return {
		rcn,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchVenueMaps: () => {
			dispatch(fetchAllVenueMaps());
		},

		editRcnVenueMap: (formData) => {
			return dispatch(editRcnVenueMap(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RcnEditContainer);

import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import RcnGbsMapListContainer from './RcnGbsMapListContainer';
import RcnGbsMapCreateContainer from './RcnGbsMapCreateContainer';
import RcnGbsMapEditContainer from './RcnGbsMapEditContainer';

const RcnGbsMapPage = (props) => {
	const { match } = props;

	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={RcnGbsMapListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={RcnGbsMapCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={RcnGbsMapEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		rcnGbsMap: Controller.denormalize('rcnGbsMaps', state.entities),
	};
};

export default connect(mapStateToProps)(RcnGbsMapPage);

import React, {useState} from 'react';
import styled from 'styled-components';
import {Droppable, Draggable} from "react-beautiful-dnd";
import Meeting from "./Meeting";
import {Link} from "react-router-dom";
import ConfirmationAlert from "../Common/ConfirmationAlert/ConfirmationAlert";
import {deletePriority} from "../../store/entities/actions/MeetingPrioritiesActions";
import {LoadingMask} from '@tbh/ui-kit';

const Panel = styled.div`
    background: #fff;
    border-radius: 3px;
    border: 1px solid #bfbebe;
    margin-bottom: 10px;
    position: relative;
`;

const PanelHeading = styled.div`
    padding: 8px;
    background: #5b5d5b;
    color: #fff;
    border-bottom: 1px solid #bfbebe;
    border-top-left-radius: 2px; 
    border-top-right-radius: 2px;  
    
    &.small{
        padding: 5px 8px;
    }
`;

const PanelContent = styled.div`
    padding: 8px;
    min-height: 50px;
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    min-height: 55px;
`;

const HeadingButton = styled.button`
    border: none;
    background: transparent;
    color: white;
    float: ${props => props.float ? props.float : 'unset'}; 
    margin: -2px;
    margin-left: 5px;
    border-radius: 3px;
    padding: 0;
    
    &:hover{
        background: gray;
    }  
 
    i{
        padding-top: 3px;
        font-size: 15px;
    }
`;

const DragHandle = styled.div`
    color: white;
    border-radius: 3px;
    margin-right: 5px;
    display: inline-block;
     
    &:hover{
        background: gray;
    }  
 
    i{
        padding-top: 3px;
        font-size: 15px;
    }
`;

const Priority = (props) => {
    let priority = props.priority;

    const [loading, setLoading] = useState(false);

    const showLoading = () =>{
        setLoading(true);
    }

    const hideLoading = () =>{
        setLoading(false);
    }

    const handleDeleteClick = (e, priorityId) => {
        e.preventDefault();

        ConfirmationAlert('Are you sure you want to delete this priority?', 'Delete priority')
            .then((res) => {

                showLoading();

                let data = {
                    priority_id: priorityId,
                };

                deletePriority(data)
                    .then((result) => {
                        if(result.status ==  200){
                            removePriorityFromList(priorityId);
                        }
                    })
                    .catch(error => {})

            })
            .catch((res) => {

            });
    }

    const removePriorityFromList = (priorityId) => {
        props.removePirotiyAction(priorityId);
    }

    return <Draggable draggableId={'priority-' + priority.meeting_priorities_id} index={props.index}>
            {(provided) => (
                <Panel ref={provided.innerRef} {...provided.draggableProps}>
                    <LoadingMask loading={loading}/>
                    <PanelHeading className="small">

                        <DragHandle {...provided.dragHandleProps}>
                            <i className="material-icons">drag_indicator</i>
                        </DragHandle>

                       {priority.meeting_priorities_name}

                        {/* Delete priority */}
                        <HeadingButton float="right" onClick={(e) => handleDeleteClick(e, priority.meeting_priorities_id)}>
                            <i className="material-icons">delete</i>
                        </HeadingButton>

                        {/* Edit priority */}
                        <Link to={'/meeting-priorities/edit/' + priority.meeting_priorities_id}>
                            <HeadingButton float="right">
                                <i className="material-icons">edit</i>
                            </HeadingButton>
                        </Link>

                    </PanelHeading>
                    <Droppable droppableId={'priority-' + priority.meeting_priorities_id} direction="horizontal" type="meeting">
                        { provided => (
                            <FlexContainer ref={provided.innerRef} {...provided.droppableProps}>
                                {
                                    priority.meetings.map((meeting, index) => {
                                        return <Meeting key={Math.random()} index={index} meeting={meeting} priorityId={priority.meeting_priorities_id} color={priority.meeting_priorities_color}/>
                                    })
                                }
                                {provided.placeholder}
                            </FlexContainer>
                        )}
                    </Droppable>
                </Panel>
            )}
        </Draggable>
}

export default Priority;
import React from 'react';
import styled from 'styled-components';
import Meeting from "./Meeting";
import { Droppable } from "react-beautiful-dnd";

const Panel = styled.div`
    background: #fff;
    border-radius: 3px;
    border: 1px solid #bfbebe;
    margin-bottom: 10px;
`;

const PanelHeading = styled.div`
    padding: 8px;
    background: #5b5d5b;
    color: #fff;
    border-bottom: 1px solid #bfbebe;
    border-top-left-radius: 2px; 
    border-top-right-radius: 2px;  
`;

const PanelContent = styled.div`
    padding: 8px;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-height: 55px;
`;

const MeetingList = (props) => {
    return <Panel>
        <PanelHeading className="small">Meetings</PanelHeading>
        <Droppable droppableId="meetings-list" direction="horizontal" type="meeting">
            { provided => (
                <FlexContainer ref={provided.innerRef} {...provided.droppableProps}>
                    {
                        props.meetingsList.meetings.map((meeting, index) => {
                            return <Meeting key={meeting.meeting_identifier} index={index} meeting={meeting}/>
                        })
                    }
                    {provided.placeholder}
                </FlexContainer>
            )}
        </Droppable>
    </Panel>
}

export default MeetingList;
import React, { useEffect, useState } from 'react';
import Controller from '../../store/entities/controller';
import { connect } from 'react-redux';
import { Heading, FlexText } from '@tbh/ui-kit';
import Table from 'react-bootstrap/Table'
import { fetchRaceUpdates } from '../../store/entities/actions/PriceUpdatesActions';
import PriceUpdatesList from '../../components/PriceUpdates/PriceUpdatesList';
import '../../assets/css/prices/page.css';
import Pusher from 'pusher-js';

class PricesPage extends React.Component {

    state = {
       raceUpdates:[],
       updated: false,
    };

    componentDidMount() {

        let self = this;

        // Fetch data from api
        fetchRaceUpdates().then(result => {            
            this.setState({
                raceUpdates: result
            }); 
        });

        // initialize and subscribe to pusher channel
        var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_APP_KEY}`, {
            cluster: `${process.env.REACT_APP_PUSHER_APP_CLUSTER}`,
            forceTLS: true
        });

        let channel = pusher.subscribe('race-card-updates');
        channel.bind('App\\Jobs\\PusherJob', function (response) {

            let raceUpdates = self.state.raceUpdates;
            let responseRaceData = JSON.parse(response.data);

            let existing = self.isRaceExists(responseRaceData.race_identifier);      
            
            if (existing === false) {
                let raceData = {
                    [responseRaceData.race_identifier]: responseRaceData.sources
                };

                raceUpdates.push(raceData);
            }else{
                let sources = raceUpdates[existing][responseRaceData.race_identifier];
                raceUpdates[existing][responseRaceData.race_identifier] = Object.assign(sources, responseRaceData.sources);
            }

            self.setState({
                raceUpdates: raceUpdates,
                updated: true,
            });

        });  
    }

    render() {

        return <div>
            <FlexText>
                <Heading>Prices</Heading>
            </FlexText>
            <Table bordered responsive="sm">
                <tbody className="tbody-race-updates">
                    <PriceUpdatesList updates={this.state.raceUpdates} updated={this.state.updated} />
                </tbody>
            </Table>
        </div>;
    }

    /**
     * Check if a object is exists in the race data with the given race identifier.
     * @param {*} raceIdentifier 
     * @return index of the object if exists, false otherwise
     */
    isRaceExists(raceIdentifier) {
        for (let key in this.state.raceUpdates) {
            if (Object.keys(this.state.raceUpdates[key]) == raceIdentifier) {
                return key;
            }
        }
        return false;
    }

}

export default PricesPage;

// import React from 'react';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import { Link } from 'react-router-dom';
import { Heading, FlexText } from '@tbh/ui-kit';
import { generateToken,pageRefresh} from '../../store/entities/actions/GenerateGbsTokenActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "../../components/Ui/Button"

class SettingsPage extends React.Component {

    render() {
        return <div>
            <FlexText>
				<Heading>Settings</Heading>
			</FlexText>
            <List aria-label="secondary">
                <ListItem>
                    <ListItemText primary="Generate GBS Token" />
                    <Button onClick={() => {
                        generateToken();
                        pageRefresh();
                    }} color="primary" round>Generate GBS Token</Button>
                </ListItem>
            </List>
        </div>;
    }
}



export default SettingsPage;
import React from 'react';
import { FlexText, Heading } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { css } from 'react-emotion/macro';
import Card from '../Ui/Card';

import Table from 'react-bootstrap/Table'
import ButtonMaterial from "../Ui/Button";
const SisListItem = ({
	id,
	editLink,
	onDelete,
	venue_key,
	secure_protocol,
	low_latency,
	stream_to_pick,
	...rest
}) => {
	const handleDelete = (e) => {
		e.stopPropagation();
		onDelete && onDelete(id);
	};

	return (
		<tr>
			<td width="30%"><Heading type="6">{venue_key || '[Untitled]'}</Heading></td>
			<td><Heading type="6">{secure_protocol ? 'Secure' : 'Not Secure'}</Heading></td>
			<td><Heading type="6">{low_latency ? 'Low Latency' : 'Normal'}</Heading></td>
			<td><Heading type="6">{stream_to_pick ? stream_to_pick : 'N/A' }</Heading></td>
			<td><div>
				<Link to={editLink}><ButtonMaterial color="primary">Edit</ButtonMaterial></Link><i onClick={handleDelete} ><ButtonMaterial color="danger" >Delete</ButtonMaterial></i>
			</div>
			</td>
		</tr>
	);
};

export default SisListItem;

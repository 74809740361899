import React from 'react';
import PriceUpdatesListItem from './PriceUpdatesListItem';

const PriceUpdatesList = (props) => {
    const listItems = [];

    for (let key in props.updates){
        let data = props.updates[key];
        listItems.push(<PriceUpdatesListItem data={data}/>);
    }
   
   return listItems;
    
};

export default PriceUpdatesList;

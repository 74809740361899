import React from 'react';
import styled, { css } from 'react-emotion';
import GlobalEndpointListItem from './GlobalEndpointListItem';
import { spacings } from '@tbh/ui-kit';
import { GLOBAL_ENDPOINT_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const Styled__GlobalEndpointListItem = styled(GlobalEndpointListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);

const GlobalEndpointList = ({ globalEndpoints, onDelete }) => {
	let globalEPListItem= globalEndpoints.map((globalEndpoint) => {
		return <Styled__GlobalEndpointListItem key={globalEndpoint.id} onDelete={onDelete} {...globalEndpoint} editLink={`${GLOBAL_ENDPOINT_ROUTE}/edit/${globalEndpoint.id}`} />;
	});
	if(globalEPListItem.length>0){

		$(document).ready(function () {
			$('#gepTable').DataTable();
		});
		return globalEPListItem;
	}else{


		return null;
	}
};

export default GlobalEndpointList;

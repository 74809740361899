import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';

/**
 * Load gws for the logged in client
 *
 * @param {*} embed
 */
const loadGwsMaps = (embed = []) => {

	return get('/admin/manage-gws', {
		params: { with: embed },
	}).catch((e) => {
		throw new Error(`Couldn't load Gws: ${e}`);
	});
};

/**
 * Create a resource
 *
 * @param Gws
 */
export const createGws = (gws) => (dispatch) => {
	return post('/admin/manage-gws', validateGws(gws));
};

/**
 * Edit a resource
 *
 * @param Gws
 */

export const editGws = (gws) => (dispatch) => {
	return put(`/admin/manage-gws/${gws.id}`, validateGws(gws));
};


/**
 * Delete the resource
 *
 * @param id
 */
export const deleteGws = (id) => (disaptch) => {
    return remove(`/admin/manage-gws/${id}`);
};


const validateGws = (gws) => {
	return gws;
};


/**
 * Fetch all individual endpoints for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllGws = (embed) => (dispatch) => {
    loadGwsMaps(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('gwss', data.data).entities)),
    );
};

/**
 * Reset individual endpoints for logged in client
 *
 * @param {*} embed
 */
export const resetGws = (embed) => (dispatch) => {
    loadGwsMaps(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                gwss: {},
                ...Controller.normalize('gwss', data.data).entities,
            }),
        ),
    );
};


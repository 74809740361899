import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import SqsQueueListContainer from './SqsQueueListContainer';
import SqsQueueCreateContainer from './SqsQueueCreateContainer';
import SqsQueueEditContainer from './SqsQueueEditContainer';

const SqsQueuePage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={SqsQueueListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={SqsQueueCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={SqsQueueEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		sqsQueues: Controller.denormalize('sqsQueues', state.entities),
	};
};

export default connect(mapStateToProps)(SqsQueuePage);

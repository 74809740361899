import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Load venue maps for the logged in client
 *
 * @param {*} embed
 */
const loadVenueMaps = (embed = []) => {

	return get('/admin/rcns', {
		params: { with: embed },
	}).catch((e) => {
		throw new Error(`Couldn't load rcns: ${e}`);
	});
};

/**
 * Fetch all venue maps for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllVenueMaps = (embed) => (dispatch) => {
	loadVenueMaps(embed).then(({ data }) => dispatch(mergeEntities(Controller.normalize('rcns', data.data).entities)));
};

/**
 * Reset venue maps for logged in client
 *
 * @param {*} embed
 */
export const resetVenueMaps = (embed) => (dispatch) => {
	loadVenueMaps(embed).then(({ data }) =>
		dispatch(
			resetEntities({
				rcns: {},
				...Controller.normalize('rcns', data.data).entities,
			}),
		),
	);
};

const validateRcnVenueMap = (rcn) => {
	return rcn;
};

/**
 * Create a rcn
 *
 * @param {*} formData
 */
export const createRcnVenueMap = (rcn) => (dispatch) => {
	return post('/admin/rcns', validateRcnVenueMap(rcn));
};

/**
 * Edit a rcn given a rcn id
 *
 * @param {*} rcn
 */
export const editRcnVenueMap = (rcn) => (dispatch) => {
	return put(`/admin/rcns/${rcn.id}`, validateRcnVenueMap(rcn));
};
//
/**
 * Delete rcn
 *
 * @param {*} rcnId
 */
export const deleteRcnVenueMap = (rcnId) => (disaptch) => {
	return remove(`/admin/rcns/${rcnId}`);
};

import React from 'react';
import styled from 'react-emotion';

const fontSize = (props) => {
	switch (props.size) {
		case 'small':
			return '12px';
		case 'large':
			return '24px';
		default:
			return 'inherit';
	}
};

const Styled_PriceTableTD = styled('td')`
	border: 1px solid #adadad;
	margin: 0;
	padding: 3px;
	text-align: center;
	font-weight: ${(props) => (props.head ? 'bold' : 'inherit')};
	font-size: ${fontSize};
`;

const Styled_PriceTableTR = styled('tr')`
	margin: 0;
	padding: 0;
	border: none;
`;

const Styled_PriceTable = styled('table')`
	width: 100%;
	border-collapse: collapse;
`;

const PricesTable = ({ prices }) => {
	// Group prices by source
	let pricesBySource = {};

	prices.forEach((price) => {
		if (!pricesBySource[price.source]) {
			pricesBySource[price.source] = {};
		}

		if (!pricesBySource[price.source][price.product]) {
			pricesBySource[price.source][price.product] = [];
		}

		pricesBySource[price.source][price.product].push(price);
	});

	const sourceNames = Object.keys(pricesBySource);

	return (
		<Styled_PriceTable>
			<Styled_PriceTableTR>
				{sourceNames.map((sourceName) => {
					const priceCountForSource = prices.filter((price) => price.source === sourceName).length;
					return (
						<Styled_PriceTableTD head colSpan={priceCountForSource}>
							{sourceName}
						</Styled_PriceTableTD>
					);
				})}
			</Styled_PriceTableTR>

			{sourceNames.map((sourceName) => {
				const sourceProducts = pricesBySource[sourceName];
				let priceItems = [];
				const productItems = Object.keys(sourceProducts).map((productName) => {
					const productPriceCount = prices.filter(
						(price) => price.source === sourceName && price.product === productName,
					).length;

					const productPrices = sourceProducts[productName];
					productPrices.forEach(({ price, bet_type }) => {
						const lastPrice = (price.split(';')[price.split(';').length - 1] / 100).toFixed(2); // TODO: do better
						priceItems.push(
							<Styled_PriceTableTD size="small">
								{bet_type}
								<br /> {lastPrice}
							</Styled_PriceTableTD>,
						);
					});

					return <Styled_PriceTableTD colSpan={productPriceCount}>{productName}</Styled_PriceTableTD>;
				});

				return (
					<>
						<Styled_PriceTableTR>{productItems}</Styled_PriceTableTR>
						<Styled_PriceTableTR>{priceItems}</Styled_PriceTableTR>
					</>
				);
			})}
		</Styled_PriceTable>
	);
};

export default PricesTable;

import Meetings from '../components/Forms/Racing/Meetings';
import Owners from '../components/Forms/Racing/Owners';
import Races from '../components/Forms/Racing/Races';
import RaceStatus from '../components/Forms/Racing/RaceStatus';
import Results from '../components/Forms/Racing/Results';
import Runners from '../components/Forms/Racing/Runners';
import Selections from '../components/Forms/Racing/Selections';
import Trainers from '../components/Forms/Racing/Trainers';

export default [
	{
		id: 'GET/racing/base/meetings',
		url: '/racing/base/meetings',
		type: 'GET',
		hasIdentifier: true,
		optionGroup: 'meetings',
	},
	{
		id: 'POST/racing/base/meetings',
		url: '/racing/base/meetings',
		type: 'POST',
		hasIdentifier: false,
		optionGroup: 'meetings',
		dataForm: Meetings,
	},
	{
		id: 'PUT/racing/base/meetings',
		url: '/racing/base/meetings',
		type: 'PUT',
		hasIdentifier: true,
		optionGroup: 'meetings',
		dataForm: Meetings,
	},
	{
		id: 'DELETE/racing/base/meetings',
		url: '/racing/base/meetings',
		type: 'DELETE',
		hasIdentifier: true,
		optionGroup: 'meetings',
	},
	{
		id: 'GET/racing/base/owners',
		url: '/racing/base/owners',
		type: 'GET',
		hasIdentifier: true,
		optionGroup: 'owners',
	},
	{
		id: 'POST/racing/base/owners',
		url: '/racing/base/owners',
		type: 'POST',
		hasIdentifier: false,
		optionGroup: 'owners',
		dataForm: Owners,
	},
	{
		id: 'PUT/racing/base/owners',
		url: '/racing/base/owners',
		type: 'PUT',
		hasIdentifier: true,
		optionGroup: 'owners',
		dataForm: Owners,
	},
	{
		id: 'DELETE/racing/base/owners',
		url: '/racing/base/owners',
		type: 'DELETE',
		hasIdentifier: true,
		optionGroup: 'owners',
	},
	{
		id: 'GET/racing/base/races',
		url: '/racing/base/races',
		type: 'GET',
		hasIdentifier: true,
		optionGroup: 'races',
	},
	{
		id: 'POST/racing/base/races',
		url: '/racing/base/races',
		type: 'POST',
		hasIdentifier: false,
		optionGroup: 'races',
		dataForm: Races,
	},
	{
		id: 'PUT/racing/base/races',
		url: '/racing/base/races',
		type: 'PUT',
		hasIdentifier: true,
		optionGroup: 'races',
		dataForm: Races,
	},
	{
		id: 'DELETE/racing/base/races',
		url: '/racing/base/races',
		type: 'DELETE',
		hasIdentifier: true,
		optionGroup: 'races',
	},
	{
		id: 'GET/racing/base/status',
		url: '/racing/base/status',
		type: 'GET',
		hasIdentifier: true,
		optionGroup: 'status',
	},
	{
		id: 'POST/racing/base/status',
		url: '/racing/base/status',
		type: 'POST',
		hasIdentifier: false,
		optionGroup: 'status',
		dataForm: RaceStatus,
	},
	{
		id: 'PUT/racing/base/status',
		url: '/racing/base/status',
		type: 'PUT',
		hasIdentifier: true,
		optionGroup: 'status',
		dataForm: RaceStatus,
	},
	{
		id: 'DELETE/racing/base/status',
		url: '/racing/base/status',
		type: 'DELETE',
		hasIdentifier: true,
		optionGroup: 'status',
	},
	{
		id: 'GET/racing/base/results',
		url: '/racing/base/results',
		type: 'GET',
		hasIdentifier: true,
		optionGroup: 'results',
	},
	{
		id: 'POST/racing/base/results',
		url: '/racing/base/results',
		type: 'POST',
		hasIdentifier: false,
		optionGroup: 'results',
		dataForm: Results,
	},
	{
		id: 'PUT/racing/base/results',
		url: '/racing/base/results',
		type: 'PUT',
		hasIdentifier: true,
		optionGroup: 'results',
		dataForm: Results,
	},
	{
		id: 'DELETE/racing/base/results',
		url: '/racing/base/results',
		type: 'DELETE',
		hasIdentifier: true,
		optionGroup: 'results',
	},
	{
		id: 'GET/racing/base/runners',
		url: '/racing/base/runners',
		type: 'GET',
		hasIdentifier: true,
		optionGroup: 'runners',
	},
	{
		id: 'POST/racing/base/runners',
		url: '/racing/base/runners',
		type: 'POST',
		hasIdentifier: false,
		optionGroup: 'runners',
		dataForm: Runners,
	},
	{
		id: 'PUT/racing/base/runners',
		url: '/racing/base/runners',
		type: 'PUT',
		hasIdentifier: true,
		optionGroup: 'runners',
		dataForm: Runners,
	},
	{
		id: 'DELETE/racing/base/runners',
		url: '/racing/base/runners',
		type: 'DELETE',
		hasIdentifier: true,
		optionGroup: 'runners',
	},
	{
		id: 'GET/racing/base/selections',
		url: '/racing/base/selections',
		type: 'GET',
		hasIdentifier: true,
		optionGroup: 'selections',
	},
	{
		id: 'POST/racing/base/selections',
		url: '/racing/base/selections',
		type: 'POST',
		hasIdentifier: false,
		optionGroup: 'selections',
		dataForm: Selections,
	},
	{
		id: 'PUT/racing/base/selections',
		url: '/racing/base/selections',
		type: 'PUT',
		hasIdentifier: true,
		optionGroup: 'selections',
		dataForm: Selections,
	},
	{
		id: 'DELETE/racing/base/selections',
		url: '/racing/base/selections',
		type: 'DELETE',
		hasIdentifier: true,
		optionGroup: 'selections',
	},
	{
		id: 'GET/racing/base/trainers',
		url: '/racing/base/trainers',
		type: 'GET',
		hasIdentifier: true,
		optionGroup: 'trainers',
	},
	{
		id: 'POST/racing/base/trainers',
		url: '/racing/base/trainers',
		type: 'POST',
		hasIdentifier: false,
		optionGroup: 'trainers',
		dataForm: Trainers,
	},
	{
		id: 'PUT/racing/base/trainers',
		url: '/racing/base/trainers',
		type: 'PUT',
		hasIdentifier: true,
		optionGroup: 'trainers',
		dataForm: Trainers,
	},
	{
		id: 'DELETE/racing/base/trainers',
		url: '/racing/base/trainers',
		type: 'DELETE',
		hasIdentifier: true,
		optionGroup: 'trainers',
	},
];

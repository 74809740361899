import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import Hr1StreamListContainer from './Hr1StreamListContainer';
import Hr1streamCreateContainer from './Hr1streamCreateContainer';
import Hr1streamEditContainer from './Hr1streamEditContainer';

const Hr1StreamPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={Hr1StreamListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={Hr1streamCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={Hr1streamEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		hr1Stream: Controller.denormalize('hr1Stream', state.entities),
	};
};

export default connect(mapStateToProps)(Hr1StreamPage);

import React, {useEffect} from 'react';
import { FlexText, Heading,Button} from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { css } from 'react-emotion/macro';
import Card from '../Ui/Card';
import Table from 'react-bootstrap/Table';
import ButtonMaterial from '../Ui/Button';


const GwsListItem = ({
	id,
	editLink,
	onDelete,
	venue_key,
	track_id,
	track_name,
	track_country_code,
	track_type,
	track_code,
	track_video_code,
	...rest
}) => {
	const handleDelete = (e) => {
		e.stopPropagation();
		onDelete && onDelete(id);
	};

	return (

		   <tr>
			   <td><Heading type="6">{venue_key ||<span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{track_id || <span className="badge badge-danger">N/A</span>}</Heading></td>
			   <td><Heading type="6">{track_name || <span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{track_country_code ||<span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{track_type || <span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{track_code || <span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><Heading type="6">{track_video_code || <span className="badge badge-danger" >N/A</span>}</Heading></td>
			   <td><div>
				   <Link to={editLink}><ButtonMaterial color="primary">Edit</ButtonMaterial></Link><i onClick={handleDelete} ><ButtonMaterial color="danger" >Delete</ButtonMaterial></i>
			   </div>
			   </td>
		   </tr>
	);
};

export default GwsListItem;

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';

export const generateToken = () => {
    console.log('generateToken');
    return post('admin/generate-gbs-token');
};

export const pageRefresh = () => {
    console.log('pageRefresh');
    // 3000 = 3 sec
    setInterval(function() {
        window.location.reload();
      }, 2000);
};
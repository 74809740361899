import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BetRadarVenueMapForm from '../../components/BetRadarVenueMap/BetRadarVenueMapForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllBetRadarVenueMaps, editBetRadarVenueMap } from '../../store/entities/actions/BetRadarVenueMapAction';
import Card from '../../components/Ui/Card';

const BetRadarVenueEditContainer = ({ betRadarVenueMap, fetchBetRadarVenueMap, createProduct, history, editBetRadarVenueMap, match, ...rest }) => {
    
	function handleFormSubmit(formData) {
		editBetRadarVenueMap({ ...betRadarVenueMap, ...formData }).then(() => {
			history.push('/betradar-venue-map');
		});
	}

	function handleCancel() {
		history.push('/betradar-venue-map');
	}

	return (
		<div>
			<Heading>Edit Venue Map</Heading>
			<Card>
				<BetRadarVenueMapForm onSubmit={handleFormSubmit} onCancel={handleCancel} betRadarVenueMaps={betRadarVenueMap} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const betRadarVenueMaps = Controller.denormalize('betRadarVenueMaps', state.entities);

	const betRadarVenueMap = betRadarVenueMaps.find((betRadarVenueMap) => {
		return betRadarVenueMap.id == ownProps.match.params.id;
	});

	return {
		betRadarVenueMap,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchBetRadarVenueMap: () => {
			dispatch(fetchAllBetRadarVenueMaps());
        },

		editBetRadarVenueMap: (formData) => {
			return dispatch(editBetRadarVenueMap(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BetRadarVenueEditContainer);

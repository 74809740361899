import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import BetRadarVenueMapList from '../../components/BetRadarVenueMap/BetRadarVenueMapList';
import { fetchAllBetRadarVenueMaps, deleteBetRadarVenueMap, resetBetRadarVenueMaps, runCommand } from '../../store/entities/actions/BetRadarVenueMapAction';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import Button from "../../components/Ui/Button";

const BetRadarVenueMapPage = (props) => {
	const { betRadarVenueMaps, deleteBetRadarVenueMap, fetchBetRadarVenueMaps } = props;

	useEffect(() => {
		fetchBetRadarVenueMaps();
	}, []);

	function handleUpdate(){
		runCommand();
	}
		
	return (
		<div>
			<FlexText>
				<Heading>BetRadar Venue Mappings</Heading>
				<div>
					<Button onClick={handleUpdate} color="primary" round>Update</Button>
					<Link to="/betradar-venue-map/create">
						<Button color="primary" round>Map BetRadar Venue</Button>
					</Link>
				</div>
			</FlexText>
            <Table bordered  responsive={true} id="betRadartable">
				<thead>
					<tr>
						<th>Id</th>
						<th>Venue Key</th>
						<th>GBS Name</th>
						<th>Name</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
		         	<BetRadarVenueMapList betRadarVenueMaps={betRadarVenueMaps} onDelete={deleteBetRadarVenueMap} />
				</tbody>
				</Table>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		betRadarVenueMaps: Controller.denormalize('betRadarVenueMaps', state.entities),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteBetRadarVenueMap: (id) => {
			return dispatch(deleteBetRadarVenueMap(id)).finally(() => {
				dispatch(resetBetRadarVenueMaps());
			});
		},

		fetchBetRadarVenueMaps: () => {
			dispatch(fetchAllBetRadarVenueMaps());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BetRadarVenueMapPage);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GwsForm from '../../components/Gws/GwsForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { createGws } from '../../store/entities/actions/GwsAction';
import Card from '../../components/Ui/Card';

const GwsCreateContainer = ({ createGws, history, ...rest }) => {

	function handleFormSubmit(formData) {
		createGws(formData).then(() => {
			history.push('/manage-gws');
		});
	}

	function handleCancel() {
		history.push('/manage-gws');
	}

	return (
		<div>
			<Heading>Create GWS</Heading>
			<Card>
				<GwsForm onSubmit={handleFormSubmit} onCancel={handleCancel} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = (dispatch) => {
	return {
		createGws: (formData) => {
			return dispatch(createGws(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(GwsCreateContainer);

// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { NavLink as RouteLink } from 'react-router-dom';
import { spacings, ui, Link, Text } from '@tbh/ui-kit';

const StyledSideBarMenuItem = styled(Text)(
	(props) => css`
		label: SideBarMenuItem;

		padding: ${spacings(props).cozy}px ${spacings(props).compact}px;
		display: flex;
		align-items: center;
	
		:hover {
					cursor: pointer;
					background-color:#455a64 ;
					
				}
	`,
);

const StyledSideBarMenuItem__Icon = styled('i')(
	(props) => css`
		label: SideBarMenuItem__Icon;
		color:white;

		margin-right: ${spacings(props).tight}px;
	`,
);

const StyledSideBarMenuItem__Title = styled(Text)(
	(props) => css`
		label: SideBarMenuItem__Title;
		color:white;

		//margin-bottom: ${spacings(props).comfortable}px;
	`,
);

const SideBarMenuItem = (props) => {
	const { className, name, icon, href } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<RouteLink exact to={href} activeStyle={{ boxShadow: 'inset 0.2875rem 0 0 #007bff' }}>
			<StyledSideBarMenuItem className={componentClasses} size="-2" whiteSpace="nowrap" ellipsis>
				{icon && <StyledSideBarMenuItem__Icon className="material-icons">{icon}</StyledSideBarMenuItem__Icon>}
				<StyledSideBarMenuItem__Title style={{color:"white"}}>{name}</StyledSideBarMenuItem__Title>
			</StyledSideBarMenuItem>
		</RouteLink>
	);
};

SideBarMenuItem.propTypes = {
	/** Sidebar menu item name */
	name: PropTypes.string,

	/** Sidebar menu item icon reference */
	icon: PropTypes.string,

	/** Menu item route to navigate to */
	href: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

SideBarMenuItem.defaultProps = {
	title: 'Menu Item',
	icon: null,
	href: undefined,
	className: null,
};

export default SideBarMenuItem;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import BetmakersList from '../../components/Betmakers/BetmakersList';
import {
	fetchAllVenueMaps,
	deleteBetmakerVenueMap,
	resetVenueMaps,
} from '../../store/entities/actions/BetmakersActions';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import Button from "../../components/Ui/Button";

const BetmakersListPage = (props) => {
	const { betmakers, deleteBetmakerVenueMap, fetchVenueMaps } = props;

	useEffect(() => {
		fetchVenueMaps();
	}, []);

	return (
		<div>
			<FlexText>
				<Heading>Betmakers Venue Map List</Heading>
				<Link to="/betmakers/create">
					<Button color="primary" round>Create new Betmakers Venue Map</Button>
				</Link>
			</FlexText>
			     <Table bordered  responsive={true} id="betMTable">
					 <thead>
						 <tr>
						 <th>Venue Key</th>
						 <th>Code</th>
						 <th>Volume</th>
						 <th>Action</th>
						 </tr>
					 </thead>

				 <tbody>
						<BetmakersList betmakers={betmakers} onDelete={deleteBetmakerVenueMap} />
				 </tbody>
				 </Table>
        </div>
	);
};

const mapStateToProps = (state) => {
	return {
		betmakers: Controller.denormalize('betmakers', state.entities),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteBetmakerVenueMap: (venueMapId) => {
			return dispatch(deleteBetmakerVenueMap(venueMapId)).finally(() => {
				dispatch(resetVenueMaps());
			});
		},

		fetchVenueMaps: () => {
			dispatch(fetchAllVenueMaps());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BetmakersListPage);

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Validate the resource.
 *
 * @param {*} formData
 */
const validateIndividualEndpoint = (individualEndpoint) => {
    return individualEndpoint;
};

/**
 * Load individual endpoints for the logged in client
 *
 * @param {*} embed
 */
const loadIndividualEndpoints = (embed = []) => {
    return get('/admin/individual-endpoints', {
        params: { with: embed },
    }).catch((e) => {
        throw new Error(`Couldn't load individual endpoints: ${e}`);
    });
};

/**
 * Create a resource
 *
 * @param {*} formData
 */
export const createIndividualEndpoint = (individualEndpoint) => (dispatch) => {
    return post('/admin/individual-endpoints', validateIndividualEndpoint(individualEndpoint));
};

/**
 * Edit the resource
 *
 * @param {*} individualEndpoint
 */
export const editIndividualEndpoint = (individualEndpoint) => (dispatch) => {
    return put(`/admin/individual-endpoints/${individualEndpoint.id}`, validateIndividualEndpoint(individualEndpoint));
};

/**
 * Delete the resource
 *
 * @param {*} individualEndpointId
 */
export const deleteIndividualEndpoint = (individualEndpointId) => (disaptch) => {
    return remove(`/admin/individual-endpoints/${individualEndpointId}`);
};

/**
 * Fetch all individual endpoints for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllIndividualEndpoints = (embed) => (dispatch) => {
    loadIndividualEndpoints(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('individualEndpoints', data.data).entities)),
    );
};

/**
 * Reset individual endpoints for logged in client
 *
 * @param {*} embed
 */
export const resetIndividualEndpoints = (embed) => (dispatch) => {
    loadIndividualEndpoints(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                individualEndpoints: {},
                ...Controller.normalize('individualEndpoints', data.data).entities,
            }),
        ),
    );
};

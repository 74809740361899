import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProductForm from '../../components/Products/ProductForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllProducts, editProduct } from '../../store/entities/actions/ProductAction';
import Card from '../../components/Ui/Card';

const ProductEditContainer = ({ product, fetchProducts, createProduct, history, editProduct, match, ...rest }) => {
	useEffect(() => {
		fetchProducts();
	}, []);

	function handleFormSubmit(formData) {
		editProduct({ ...product, ...formData }).then(() => {
			history.push('/manage-products');
		});
	}

	function handleCancel() {
		history.push('/manage-products');
	}

	return (
		<div>
			<Heading>Edit Product</Heading>
			<Card>
				<ProductForm onSubmit={handleFormSubmit} onCancel={handleCancel} product={product} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const products = Controller.denormalize('products', state.entities);

	const product = products.find((product) => {
		return product.id == ownProps.match.params.id;
	});

	return {
		product,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProducts: () => {
			dispatch(fetchAllProducts());
        },

		editProduct: (formData) => {
			return dispatch(editProduct(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductEditContainer);

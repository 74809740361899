import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion/macro';

import {
	spacings,
	typography,
	PlotElements,
	Select,
	SelectOption,
	Textarea,
	Heading,
	Input,
	Button,
	Notification,
} from '@tbh/ui-kit';

import axios from '../common/ajax';

const StyledCodePush__Textarea = styled(Textarea)(
	(props) => css`
		label: CodePush__Textarea;

		width: 100%;
		min-height: 50vh;
		margin-top: ${spacings(props).comfortable}px;
		color: ${typography(props).color_2};
		background-color: #fff;
		padding: ${spacings(props).comfortable}px;
		border: 1px solid #fff;
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

		&:focus {
			border: 1px solid #ddd;
		}
	`,
);

const StyledCodePush__Notification = styled(Notification)(
	(props) => css`
		label: CodePush__Notification;

		margin-top: ${spacings(props).comfortable}px;
		margin-bottom: ${spacings(props).comfortable}px;
	`,
);

const StyledCodePush__Button = styled(Button)(
	(props) => css`
		label: CodePush__Button;

		width: 100%;
		margin-top: ${spacings(props).comfortable}px;
	`,
);

const StyledCodePush__Select = styled(Select)(
	(props) => css`
		label: CodePush__Select;

		width: 100%;
		color: ${typography(props).color_2};
	`,
);

const StyledCodePush__Input = styled(Input)(
	(props) => css`
		label: CodePush__Input;

		&& {
			input {
				color: ${typography(props).color_2};
			}
		}
	`,
);

const StyledCodePush__Optgroup = styled('optgroup')(
	(props) => css`
		label: CodePush__Optgroup;

		color: ${typography(props).color_1};
	`,
);

class CodePush extends Component {
	state = {
		error: '',
		successText: '',
		resourceLoading: false,
		resourceIdentifier: '',
		selectedResourceId: '',
		dataToPush: '',
	};

	/**
	 * Handle a resource being selected from the dropdown
	 */
	handleChangeResource = (name, value) => {
		this.setState({
			error: '',
			successText: '',
			resourceIdentifier: '',
			selectedResourceId: value,
		});
	};

	/**
	 * Handle when the textarea has it's data changed
	 */
	handleTextChange = (event) => {
		this.setState({
			dataToPush: event.target.value,
		});
	};

	/** Update the text area data */
	updateTextToPush = (value, parseFirst = false) => {
		if (value === null) {
			this.setState({
				dataToPush: '',
			});
		} else {
			const prettifiedJSON = this.prettyJSON(value, parseFirst);

			if (prettifiedJSON) {
				this.setState({
					dataToPush: prettifiedJSON,
				});
			}
		}
	};

	/**
	 * Handle setting the resource identifier for some requests
	 */
	handleSetResourceIdentifier = (name, value) => {
		this.setState({
			resourceIdentifier: value,
		});
	};

	/**
	 * Handle when the textarea loses focus
	 */
	handleTextBlur = () => {
		this.updateTextToPush(this.state.dataToPush, true);
	};

	/**
	 * Handle submitting the form
	 */
	handleSubmit = () => {
		this.setState({
			error: '',
			successText: '',
			resourceLoading: true,
		});

		const { dataToPush, selectedResourceId, resourceIdentifier } = this.state;
		const selectedResource = this.findResource(selectedResourceId);

		// The ajax submission method
		const resourceType = selectedResource.type.toLowerCase();

		// Add the endpoint resource if one exists
		const endpointResource = selectedResource.hasIdentifier && resourceIdentifier ? `/${resourceIdentifier}` : '';

		// Attempt to parse JSON data for submitting
		let data = dataToPush;
		try {
			data = JSON.parse(dataToPush);
		} catch (e) {
			console.error(e);
		}

		axios[resourceType](`${selectedResource.url}${endpointResource}`, data)
			.catch((error) => {
				this.setState({
					error: error.message,
				});
			})
			.then((response) => {
				if (response) {
					if (resourceType === 'get') {
						const data = response.data.data;
						const prettifiedData = this.prettyJSON(data);

						this.setState({
							dataToPush: prettifiedData,
						});
					} else if (resourceType === 'post') {
						if (response && response.status === 201) {
							this.setState({
								successText: 'Resource has been created.',
							});
						}
					} else if (resourceType === 'put') {
						if (response && response.status === 200) {
							this.setState({
								successText: 'Resource has been modified.',
							});
						}
					} else if (resourceType === 'delete') {
						if (response && response.status === 200) {
							this.setState({
								successText: 'Resource has been deleted.',
							});
						}
					}
				}
			})
			.finally(() => {
				this.setState({
					resourceLoading: false,
				});
			});
	};

	/**
	 * Handle the form submitting itself
	 */
	handleFormSubmit = (e) => {
		e.preventDefault();
	};

	/**
	 * Find the selected resource
	 */
	findResource = (resourceId) => {
		return this.props.resources.find((resource) => resource.id === resourceId);
	};

	/**
	 * Prettify the JSON data in the textarea
	 */
	prettyJSON = (jsonToPretty, parseFirst = false) => {
		try {
			let obj = jsonToPretty;

			if (parseFirst && jsonToPretty) {
				obj = JSON.parse(jsonToPretty);
			}

			const pretty = JSON.stringify(obj, undefined, 4);

			return pretty;
		} catch (e) {
			console.error(e);
		}

		return jsonToPretty;
	};

	render() {
		const { resources } = this.props;
		const { error, dataToPush, successText, selectedResourceId, resourceIdentifier, resourceLoading } = this.state;

		const selectedResource = this.findResource(selectedResourceId);

		// Compile a unique list of the resource groups to build our optgroups from
		const uniqueResourceGroups = [...new Set(resources.map((resource) => resource.optionGroup))];

		const FormToRender = selectedResource && selectedResource.dataForm;

		return (
			<PlotElements
				className={css`
					min-width: 50vw;
				`}
				align="middle"
				alignElements="stretch"
				vertical
			>
				<form onSubmit={this.handleFormSubmit}>
					<StyledCodePush__Select
						name="resource"
						value={selectedResourceId}
						onChange={this.handleChangeResource}
						selectClassName={css`
							color: #666;
						`}
					>
						<SelectOption
							className={css`
								color: black;
							`}
							value=""
						>
							Select a Resource
						</SelectOption>
						{uniqueResourceGroups.sort().map((resourceGroup) => (
							<StyledCodePush__Optgroup key={resourceGroup} label={resourceGroup}>
								{resources
									.filter((resource) => resource.optionGroup === resourceGroup)
									.map((resource) => (
										<SelectOption
											key={resource.id}
											className={css`
												color: black;
											`}
											label={`${resource.type} - ${resource.url}`}
											value={resource.id}
										>
											{resource.type} - {resource.url}
										</SelectOption>
									))}
							</StyledCodePush__Optgroup>
						))}
					</StyledCodePush__Select>

					{selectedResource && selectedResource.hasIdentifier && (
						<StyledCodePush__Input
							placeholder="Resource Identifier"
							name="resourceIdentifier"
							value={resourceIdentifier}
							onChange={this.handleSetResourceIdentifier}
						/>
					)}
					{error ? (
						<StyledCodePush__Notification type="danger" message={error} strong />
					) : successText ? (
						<StyledCodePush__Notification type="success" message={successText} strong />
					) : null}

					{FormToRender && <FormToRender onDataChange={this.updateTextToPush} />}

					<StyledCodePush__Textarea
						name="code"
						value={dataToPush}
						onChange={this.handleTextChange}
						onBlur={this.handleTextBlur}
					/>

					<StyledCodePush__Button
						type="secondary"
						action={this.handleSubmit}
						disabled={!selectedResource}
						loading={resourceLoading}
					>
						{selectedResource ? selectedResource.type.toUpperCase() : 'Submit'}
					</StyledCodePush__Button>
				</form>
			</PlotElements>
		);
	}
}

CodePush.propTypes = {
	/** The list of resources */
	resources: PropTypes.array.isRequired,
};

export default CodePush;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import GwsList from '../../components/Gws/GwsList';
import { fetchAllGws, deleteGws, resetGws } from '../../store/entities/actions/GwsAction';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import Button from "../../components/Ui/Button";

const GwsListContainer = (props) => {
	const { gwss, deleteGws, fetchGws } = props;

	useEffect(() => {
		fetchGws();
	}, []);
	return (
		<div>
			<FlexText>
				<Heading>GWS List</Heading>
				<Link to="/manage-gws/create">
					<Button color="primary" round>Create new gws</Button>
				</Link>
			</FlexText>

			<Table bordered responsive={true} id="gwsTable" width="100%">
				<thead>
					<tr>
						<th>Venue Key</th>
						<th>Track Id</th>
						<th>Track Name</th>
						<th>Track Country</th>
						<th>Track Type</th>
						<th>Track Code</th>
						<th>Track Vedio Code</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<GwsList gwss={gwss} onDelete={deleteGws} />
				</tbody>
			</Table>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		gwss: Controller.denormalize('gwss', state.entities),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteGws: (id) => {
			return dispatch(deleteGws(id)).finally(() => {
				dispatch(resetGws());
			});
		},

		fetchGws: () => {
			dispatch(fetchAllGws());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GwsListContainer);

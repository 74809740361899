import React from 'react';
import { FlexText, Heading,Button } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { css } from 'react-emotion/macro';
import Card from '../Ui/Card';
import ButtonMaterial from "../Ui/Button";

const GlobalEndpointListItem = ({
	id,
	editLink,
	onDelete,
	client_id,
	endpoint,
	...rest
}) => {
	const handleDelete = (e) => {
		e.stopPropagation();
		onDelete && onDelete(id);
	};

	return (
		       <tr>
				   <td><Heading type="6">{client_id || '[Untitled]'}</Heading></td>
				   <td><Heading type="6">{endpoint || '[Untitled]'}</Heading></td>
				   <td><div>
					   <Link to={editLink}><ButtonMaterial color="primary">Edit</ButtonMaterial></Link><i onClick={handleDelete} ><ButtonMaterial color="danger" >Delete</ButtonMaterial></i>
				   </div></td>

			   </tr>
	);
};

export default GlobalEndpointListItem;

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import { mergeEntities, resetEntities } from 'redux-entities-reducer';

/**
 * Load subscriptions for the logged in client
 *
 * @param {*} embed
 */
const loadSubscriptionsForUser = (embed = ['sources', 'client']) => {
	return get('/admin/subscriptions', {
		params: { with: embed },
	}).catch((e) => {
		throw new Error(`Couldn't load subscriptions: ${e}`);
	});
};

/**
 * Fetch all subscriptions for the logged in client
 *
 * @param {*} embed
 */
export const fetchSubscriptionsForCurrentUser = (embed) => (dispatch) => {
	loadSubscriptionsForUser(embed).then(({ data }) =>
		dispatch(mergeEntities(Controller.normalize('subscriptions', data.data).entities)),
	);
};

export const fetchSubscriptionById = (subscriptionId) => (dispatch) =>
	get(`/admin/subscriptions/${subscriptionId}`).then(({ data }) => {
		return dispatch(mergeEntities(Controller.normalize('subscriptions', [data.data]).entities));
	});

/**
 * Reset subscriptions for logged in client
 *
 * @param {*} embed
 */
export const resetSubscriptions = (embed) => (dispatch) => {
	loadSubscriptionsForUser(embed).then(({ data }) =>
		dispatch(
			resetEntities({
				subscriptions: {},
				...Controller.normalize('subscriptions', data.data).entities,
			}),
		),
	);
};

const validateSubscription = (subscription) => {
	if (!subscription.racing_field_priorities) {
		subscription.racing_field_priorities = '[]';
	}

	if (!subscription.sport_field_priorities) {
		subscription.sport_field_priorities = '[]';
	}

	return subscription;
};

/**
 * Create a subscription
 *
 * @param {*} formData
 */
export const createSubscription = (subscription) => (dispatch) => {
	return post('/admin/subscriptions', validateSubscription(subscription));
};

/**
 * Edit a subscription given a subscription id
 *
 * @param {*} subscription
 */
export const editSubscription = (subscription) => (dispatch) => {
	return put(`/admin/subscriptions/${subscription.id}`, validateSubscription(subscription));
};

/**
 * Delete subscription
 *
 * @param {*} subscriptionId
 */
export const deleteSubscription = (subscriptionId) => (disaptch) => {
	return remove(`/admin/subscriptions/${subscriptionId}`);
};

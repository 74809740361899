import Controller from './controller';
import { CLEAR_AUTHENTICATED_USER } from '../application/applicationActionTypes';

let reducer = Controller.createReducer();

const initialState = {
	user: {},
	meetings: {},
	races: {},
	runners: {},
	selections: {},
	subscriptions: {},
	sources: {},
	client: {},
	rcns:{},
	globalEndpoints:{},
	individualEndpoints:{},
	clients:{},
	value: 0,
	betmakers: {},
	deliveryTypes:{},
	sqsQueues:{},
	siss:{},
	products:{},
	rcnGbsMaps:{},
	betRadarVenueMaps:{},
    hr1Stream: {},
	gwss:{},
	youtubeStreams:{},
};

/**
 *
 * Entity Reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const entities = (state = initialState, action) => {
	state = reducer(state, action);

	switch (action.type) {
		case CLEAR_AUTHENTICATED_USER:
			return initialState;
		default:
			return state;
	}
};

/**
 * Exports
 */
export default entities;

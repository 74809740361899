import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import SubscriptionsList from '../../components/Subscriptions/SubscriptionsList';
import {
	fetchSubscriptionsForCurrentUser,
	deleteSubscription,
	resetSubscriptions,
} from '../../store/entities/actions/SubscriptionActions';
import { fetchAllSources } from '../../store/entities/actions/SourceActions';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Button from "../../components/Ui/Button";
import CardHeader from "../../components/Ui/CardHeader";
import CardIcon from "../../components/Ui/CardIcon";
import Accessibility from "@material-ui/icons/Accessibility";
import CardMaterial from '../../components/Ui/CardMaterial';
const SubscriptionsListPage = (props) => {

	const { subscriptions, deleteSubscription, fetchSubscriptions } = props;


	useEffect(() => {
		fetchSubscriptions();

	}, []);

	return (

		<div>

			<FlexText>
				<Heading>Subscription List</Heading>

				<Link to="/subscriptions/create">
					<Button color="primary" round>Create new Subscription</Button>
				</Link>
			</FlexText>

			<SubscriptionsList subscriptions={subscriptions} onDelete={deleteSubscription} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		subscriptions: Controller.denormalize('subscriptions', state.entities),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteSubscription: (subscriptionId) => {
			return dispatch(deleteSubscription(subscriptionId)).finally(() => {
				dispatch(resetSubscriptions());
				dispatch(fetchAllSources());
			});
		},

		fetchSubscriptions: () => {
			dispatch(fetchSubscriptionsForCurrentUser());
			dispatch(fetchAllSources());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SubscriptionsListPage);

import React from 'react';
import {connect} from 'react-redux';
import Controller from '../../store/entities/controller';
import RcnGbsMapList from '../../components/RcnGbsMap/RcnGbsMapList';
import {deleteRcnGbsVenueMap, fetchAllRcnGbsMappings, resetVenueMaps} from '../../store/entities/actions/RcnGbsMapActions';
import {FlexText, Heading} from '@tbh/ui-kit';
import {Link} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../../components/Ui/Button";
import Table from 'react-bootstrap/Table'
import 'datatables.net';
import 'datatables.net-dt';

class RcnGbsMapListPage extends React.Component{
	componentWillMount() {
   		this.props.fetchMappings();
    }

    render(){
		const { rcnGbsMaps, deleteRcnGbsVenueMap, fetchVenueMaps } = this.props
	return (
		<div>
			<FlexText>

				<Heading strong padded={true}>RCN/SIS to GBS Mappings</Heading>
				<ToastContainer autoClose={2000} />
				<Link to="/rcn-gbs-map/create" >
					<Button color="primary" round>Map RCN/SIS to GBS</Button>
				</Link>
			</FlexText>
			<Table   bordered responsive={true} id="rcntable" width="100%">
				<thead>
				<tr>
					<th>Id</th>
					<th>RCN/SIS meeting name</th>
					<th>GBS meeting name</th>
					<th>Action</th>
				</tr>
				</thead>
				<tbody>
				{rcnGbsMaps.length > 0
					?
					<RcnGbsMapList rcnGbsMaps={rcnGbsMaps} onDelete={deleteRcnGbsVenueMap}/>
					:
					<tr><td colSpan="4" className="text-center">No records found</td></tr>
				}
				</tbody>
			</Table>
		</div>
	)
};
}
const mapStateToProps = (state) => {
	return {
		rcnGbsMaps: Controller.denormalize('rcnGbsMaps', state.entities),

	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteRcnGbsVenueMap: (venueMapId) => {
			return dispatch(deleteRcnGbsVenueMap(venueMapId)).finally(() => {
				dispatch(resetVenueMaps());
			});
		},

		fetchMappings: async () => {
			dispatch(fetchAllRcnGbsMappings());
		},
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RcnGbsMapListPage);

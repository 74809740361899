import React, { useState, useEffect } from 'react';
import { typography, Select, SelectOption, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const SqsQueueForm = (props) => {
	const { onSubmit, onCancel, sqsQueue, subscriptions, clients } = props;
    const[btn_load,setBtnLoad] = useState();
	const [subscription_id, setSubscriptionId] = useState();
	const [version, setVersion] = useState();
	const [region, setRegion] = useState();
	const [key, setKey] = useState();
	const [secret, setSecret] = useState();
	const [queue_name, setQueueName] = useState();

	useEffect(() => {
		setBtnLoad(false);
		setSubscriptionId(sqsQueue.subscription_id);
		setVersion(sqsQueue.version);
		setRegion(sqsQueue.region);
		setKey(sqsQueue.key);
		setSecret(sqsQueue.secret);
		setQueueName(sqsQueue.queue_name);
	}, [sqsQueue]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			subscription_id,
			version,
			region,
			key,
			secret,
			queue_name,
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
    }
    
    /**
     * Create the subscription select items
     * 
     * @return array
     */
    function createSubscriptionSelectItems() {
        return subscriptions.map((item, key) =>
            <SelectOption value={item.id}>{item.description}</SelectOption>
        );
    }
    
    /**
     * Create the client select items
     * 
     * @return array
     */
    function createClientSelectItems() {
        return clients.map((item, key) =>
            <SelectOption value={item.id}>{item.name}</SelectOption>
        );
    }

	return (
		<form onSubmit={handleSubmit}>

			<Styled__Label>Subscription</Styled__Label>
			<Select value={subscription_id} onChange={(name, value) => setSubscriptionId(value)}>
                {createSubscriptionSelectItems()}
            </Select>

			<Styled__Label>Version</Styled__Label>
			<Input value={version} onChange={(name, value) => setVersion(value)} />

            <Styled__Label>Region</Styled__Label>
            <Input value={region} onChange={(name, value) => setRegion(value)} />

            <Styled__Label>Key</Styled__Label>
            <Input value={key} onChange={(name, value) => setKey(value)} />

            <Styled__Label>Secret</Styled__Label>
            <Input value={secret} onChange={(name, value) => setSecret(value)} />

            <Styled__Label>Queue Name</Styled__Label>
            <Input value={queue_name} onChange={(name, value) => setQueueName(value)} />

			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} size="large" type={Button.types.PRIMARY} loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>

		</form>
	);
};

SqsQueueForm.defaultProps = {
	sqsQueue: {
		subscription_id: 1,
		version: '',
		region: '',
		key: '',
		secret: '',
		queue_name: '',
	},
};

export default SqsQueueForm;

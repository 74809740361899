import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';

/**
 * Validate the resource.
 *
 * @param {*} formData
 */
const validateClient = (client) => {
    return client;
};

/**
 * Load clients for the logged in client
 *
 * @param {*} embed
 */
const loadClients = (embed = []) => {
    return get('/admin/clients', {
        params: { with: embed },
    }).catch((e) => {
        throw new Error(`Couldn't load clients: ${e}`);
    });
};

/**
 * Create a resource
 *
 * @param {*} formData
 */
export const createClient = (client) => (dispatch) => {
    return post('/admin/clients', validateClient(client));
};

/**
 * Edit the resource
 *
 * @param {*} client
 */
export const editClient = (client) => (dispatch) => {
    return put(`/admin/clients/${client.id}`, validateClient(client));
};

/**
 * Delete the resource
 *
 * @param {*} clientId
 */
export const deleteClient = (clientId) => (disaptch) => {
    return remove(`/admin/clients/${clientId}`);
};

/**
 * Fetch all clients for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllClients = (embed) => (dispatch) => {
    loadClients(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('clients', data.data).entities)),
    );
};

/**
 * Reset clients for logged in client
 *
 * @param {*} embed
 */
export const resetClients = (embed) => (dispatch) => {
    loadClients(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                clients: {},
                ...Controller.normalize('clients', data.data).entities,
            }),
        ),
    );
};

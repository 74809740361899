import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';

const Styled_RaceItem = styled(Link)`
	flex: 1 1 0px;
	padding: 5px;
	margin: 2px;
	text-align: center;
	max-width: 30px;
	border: 1px solid grey;
	border-radius: 5px;

	&:hover {
		background-color: darkgray;
		cursor: pointer;
	}
`;

const RaceItem = ({ url, number }) => {
	return <Styled_RaceItem to={url}>{number}</Styled_RaceItem>;
};

export default RaceItem;

import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import SisListContainer from './SisListContainer';
import SisCreateContainer from './SisCreateContainer';
import SisEditContainer from './SisEditContainer';

const SisPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={SisListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={SisCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={SisEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		sis: Controller.denormalize('siss', state.entities),
	};
};

export default connect(mapStateToProps)(SisPage);

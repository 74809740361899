import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import Card from '../../components/Ui/Card';
import YoutubeStreamForm from '../../components/YoutubeStream/YoutubeStreamForm';
import { fetchAllYoutubeStreams, editYoutubeStream } from '../../store/entities/actions/YoutubeStreamAction';

const YoutubeStreamEditContainer = ({ youtubeStream, fetchYoutubeStreams, createYoutubeStream, history, editYoutubeStream, match, ...rest }) => {
	useEffect(() => {
		fetchYoutubeStreams();
	}, []);

	function handleFormSubmit(formData) {
		editYoutubeStream({ ...youtubeStream, ...formData }).then(() => {
			history.push('/youtube-streams');
		});
	}

	function handleCancel() {
		history.push('/youtube-streams');
	}

	return (
		<div>
			<Heading>Edit Youtube Live Stream</Heading>
			<Card>
				<YoutubeStreamForm onSubmit={handleFormSubmit} onCancel={handleCancel} youtubeStream={youtubeStream} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const youtubeStreams = Controller.denormalize('youtubeStreams', state.entities);

	const youtubeStream = youtubeStreams.find((youtubeStream) => {
		return youtubeStream.r_youtube_livestream_id == ownProps.match.params.r_youtube_livestream_id;
	});

	return {
		youtubeStream,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchYoutubeStreams: () => {
			dispatch(fetchAllYoutubeStreams());
        },

		editYoutubeStream: (formData) => {
			return dispatch(editYoutubeStream(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(YoutubeStreamEditContainer);

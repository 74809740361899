import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Load venue maps for the logged in client
 *
 * @param {*} embed
 */
const loadRcnGbsMaps = (embed = []) => {

	return get('/admin/rcn-gbs-map', {
		params: { with: embed },
	}).catch((e) => {
		throw new Error(`Couldn't load rcns: ${e}`);
	});
};

/**
 * Fetch all venue maps for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllRcnGbsMappings = (embed) => (dispatch) => {
	loadRcnGbsMaps(embed).then(({ data }) => dispatch(mergeEntities(Controller.normalize('rcnGbsMaps', data.data).entities)));
};

/**
 * Reset venue maps for logged in client
 *
 * @param {*} embed
 */
export const resetVenueMaps = (embed) => (dispatch) => {
	loadRcnGbsMaps(embed).then(({ data }) =>
		dispatch(
			resetEntities({
				rcnGbsMaps: {},
				...Controller.normalize('rcnGbsMaps', data.data).entities,
			}),
		),
	);
};

const validateRcnVenueMap = (rcn) => {
	return rcn;
};

/**
 * Create a rcn
 *
 * @param {*} formData
 */
export const createRcnGbsMap = (rcn) => (dispatch) => {
	return post('/admin/rcn-gbs-map', validateRcnVenueMap(rcn));
};

/**
 * Edit a rcn given a rcn id
 *
 * @param {*} rcn
 */
export const editRcnGbsVenueMap = (rcn) => (dispatch) => {
	return put(`/admin/rcn-gbs-map/${rcn.id}`, validateRcnVenueMap(rcn));
};
//
/**
 * Delete rcn
 *
 * @param {*} rcnId
 */
export const deleteRcnGbsVenueMap = (rcnId) => (disaptch) => {
	return remove(`/admin/rcn-gbs-map/${rcnId}`);
};

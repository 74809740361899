import React from 'react';
import styled, { css } from 'react-emotion';
import BetmakersListItem from './BetmakersListItem';
import { spacings } from '@tbh/ui-kit';
import { BETMAKERS_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
const Styled__BetmakersListItem = styled(BetmakersListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);

const BetmakersList = ({ betmakers, onDelete }) => {

		let bmListItem= betmakers.map((betmaker) => {
			return (
				<Styled__BetmakersListItem
					key={betmaker.id}
					onDelete={onDelete}
					{...betmaker}
					editLink={`${BETMAKERS_ROUTE}/edit/${betmaker.id}`}
				/>
			);
		});
		if(bmListItem.length>0){

			$(document).ready(function () {
				$('#betMTable').DataTable();
			});
			return bmListItem;
		}else{
			return null;
		}


};

export default BetmakersList;

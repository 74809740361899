import React from 'react';
import { SizeMe } from 'react-sizeme';
import styled, { css } from 'react-emotion/macro';
import { media, spacings, typography, ui, Text, Input } from '@tbh/ui-kit';

import {
	BASE_ROUTE,
	LOGOUT_ROUTE,
	RACING_ROUTE,
	SPORTS_ROUTE,
	USER_ROUTE,
	SUBSCRIPTIONS_ROUTE,
	RCN_ROUTE,
	RCN_GBS_ROUTE,
	GLOBAL_ENDPOINT_ROUTE,
	INDIVIDUAL_ENDPOINT_ROUTE,
	SQS_QUEUE_ROUTE,
	BETMAKERS_ROUTE,
	SIS_ROUTE,
	YOUTUBE_STREAM_ROUTE,
	PRICES_ROUTE, MEETING_PRIORITIES_ROUTE,
	MANAGE_PRODUCT_ROUTE,
	BET_RADAR_VENUE_MAP_ROUTE,
	MANAGE_HR1_STREAM_ROUTE,
	MANAGE_GWS_ROUTE,
    SETTINGS_ROUTE

} from '../../constants/RouteConstants';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import PropTypes from 'prop-types';
import '../../assets/css/hambuger.css';

const APP_BREAKPOINT = 600;

const StyledApp = styled('div')(
	(props) => css`
    label: App;

    //background-color: ${ui(props).color_1};

    position: relative;
    width: 100%;
    padding-left: 0;
    min-height: 100vh;
    margin: 0 auto;
    left: 0;
    background-color: #eee;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
    -webkit-transition: left 0.3s ease, padding-left 0.3s ease;
    transition: left 0.3s ease, padding-left 0.3s ease;
    overflow: hidden;
  `,
	media(
		css`
			padding-left: 260px;
		`,
		APP_BREAKPOINT,
	),
);

const StyledAdminPortal__Header = styled('header')(
	(props) => css`
		label: AdminPortal__Header;

		//background-color: #d7dde4;
		height: 70px;
		position: absolute;
		padding-left: ${spacings(props).cozy}px;
		left: 0;
		right: 0;
		-webkit-transition: left 0.3s ease;
		transition: left 0.3s ease;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: space-between;
		display: flex;
	`,
	media(
		css`
			left: 260px;
		`,
		APP_BREAKPOINT,
	),
);

const StyledAdminPortal__MenuIcon = styled('i')(
	(props) => css`
		label: AdminPortal__MenuIcon;

		//padding-left: ${spacings(props).cozy}px;
		display: block;
	`,
	media(
		css`
			display: none;
		`,
		APP_BREAKPOINT,
	),
);

const StyledAdminPortal__HeaderBlock = styled('div')(
	css`
		label: AdminPortal__HeaderBlock;

		padding-left: 16px;
		flex: 1 0 auto;
	`,
);

const StyledAdminPortal__Brand = styled('div')(
	(props) => css`
		label: AdminPortal__Brand;

		margin: ${spacings(props).cozy}px 0;
		line-height: 70px;
	`,
);

const StyledAdminPortal__SidebarFilter = styled(Input)(
	(props) => css`
		label: AdminPortal__SidebarFilter;

		margin: ${spacings(props).cozy}px 0;
		//padding-left: ${spacings(props).cozy}px;
		line-height: 70px;
	`,
);

const StyledAdminPortal__Sidebar = styled('aside')(
	(props) => css`
		label: AdminPortal__Sidebar;

		position: fixed;
		left: -260px;

		//background-color: #3a4651;
		background-color: #f1f3f5;
		box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
			0 8px 10px -5px rgba(0, 0, 0, 0.2);

		width: 260px;
		padding-bottom: 60px;
		top: 0;
		bottom: 0;
		-webkit-transition: left 0.3s ease;
		transition: left 0.3s ease;
		z-index: 20;
		height: 100vh important;
	`,
	media(
		css`
			position: absolute;
			left: 0;
		`,
		APP_BREAKPOINT,
	),
);

const StyledAdminPortal__SidebarContainer = styled('div')(
	css`
		label: AdminPortal__SidebarContainer;

		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		left: 0;
		overflow-y: auto;
		overflow-x: hidden;
		display: flex;
		color:white;
		background-image:url();
		background-color: #263238;
		flex-direction: column;
	
	`,
);

const StyledAdminPortal__Content = styled('article')(
	css`
		label: AdminPortal__Content;

		padding: 25px;
		min-height: 100vh;
	`,
);

const StyledAdminPortal__Card = styled('div')(
	(props) => css`
		label: AdminPortal__Card;

		/* background-color: #fff; */
		/* -webkit-box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1); */
		/* box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1); */
		margin-bottom: ${spacings(props).cozy}px;
		padding: ${spacings(props).cozy}px;
		border-radius: 0;
		border: none;
	`,
);

const SIDEBAR_MENU_ITEMS = [
	// { icon: 'dashboard', name: 'Home', href: BASE_ROUTE },
	{ icon: 'list', name: 'Manage Subscriptions', href: SUBSCRIPTIONS_ROUTE },
    { icon: 'list', name: 'Manage RCN', href: RCN_ROUTE },
    { icon: 'list', name: 'Map RCN/SIS to GBS', href: RCN_GBS_ROUTE },
    { icon: 'list', name: 'Manage SIS', href: SIS_ROUTE },
	{ icon: 'list', name: 'Manage Youtube Stream', href: YOUTUBE_STREAM_ROUTE },
    { icon: 'list', name: 'Manage Global Endpoint', href: GLOBAL_ENDPOINT_ROUTE },
    { icon: 'list', name: 'Manage Individual Endpoint', href: INDIVIDUAL_ENDPOINT_ROUTE },
    { icon: 'list', name: 'Manage SQS Queues', href: SQS_QUEUE_ROUTE },
	{ icon: 'list', name: 'Manage Betmakers', href: BETMAKERS_ROUTE },
	{ icon: 'list', name: 'Monitor Prices', href: PRICES_ROUTE },
	{ icon: 'list', name: 'Manage Meeting Priorities', href: MEETING_PRIORITIES_ROUTE },
	{ icon: 'list', name: 'Manage Products', href: MANAGE_PRODUCT_ROUTE },
	{ icon: 'list', name: 'Map BetRadar Venue', href: BET_RADAR_VENUE_MAP_ROUTE},
	{ icon: 'list', name: 'Manage HR1 Streams', href: MANAGE_HR1_STREAM_ROUTE },
	{ icon: 'list', name: 'Manage GWS', href: MANAGE_GWS_ROUTE },
	{ icon: 'settings', name: 'Settings', href: SETTINGS_ROUTE },
];

const SIDEBAR_ACTION_ITEMS = [
	{ icon: 'content_paste', name: 'Racing (Dev)', href: RACING_ROUTE },
	{ icon: 'library_books', name: 'Sports (Dev)', href: SPORTS_ROUTE },
	{ icon: 'person', name: 'User Profile (Soon)', href: USER_ROUTE },
	{ icon: 'logout', name: 'Logout', href: LOGOUT_ROUTE },
];

// TODO: Move into components somewhere...
const Spacer = styled('div')(
	(props) => css`
		flex: ${props.flex};
	`,
);

class AdminPortal extends React.Component {
	state = { filter: '',
		is_toggeled:true };


	onFilterChange = (name, value) => {
		this.setState({
			filter: value,
		});
	};

	render() {
		const { filter,is_toggeled } = this.state;
		const { children } = this.props;

		return (
			<SizeMe>
				{({ size }) => (

					<StyledApp size={this.state.is_toggeled?size:null}>
						<StyledAdminPortal__Sidebar size={this.state.is_toggeled?size:null}>
							<StyledAdminPortal__SidebarContainer>
								<StyledAdminPortal__Brand>
									<Text align="center" style={{color:"white"}} type="warning" strong="true" size="2">

										Data Highway
									</Text>
								</StyledAdminPortal__Brand>

								{/* <StyledAdminPortal__SidebarFilter
									value={filter}
									onChange={this.onFilterChange}
									name="sidebarFilter"
									placeholder="Filter search"
								/> */}

								<SideBarMenu items={SIDEBAR_MENU_ITEMS} filter={filter} />
								<Spacer flex={1} />
								<SideBarMenu items={SIDEBAR_ACTION_ITEMS} />
							</StyledAdminPortal__SidebarContainer>
						</StyledAdminPortal__Sidebar>

						<StyledAdminPortal__Content>
							<div className={this.state.is_toggeled?"change":"containerham"} onClick={()=>{
								this.setState({
									is_toggeled:!is_toggeled
								})
							}}>
								<div className="bar1"></div>
								<div className="bar2"></div>
								<div className="bar3"></div>
							</div>
							<StyledAdminPortal__Card>{children}</StyledAdminPortal__Card>
						</StyledAdminPortal__Content>
					</StyledApp>
				)}
			</SizeMe>
		);
	}
}
AdminPortal.propTypes = {
	/** Extra classes */
	className: PropTypes.string,
};

AdminPortal.defaultProps = {
	className: '',
};

export default AdminPortal;

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Validate the resource.
 *
 * @param {*} formData
 */
const validateYoutubeStream = (youtubeStream) => {
    return youtubeStream;
};

/**
 * Load individual endpoints for the logged in client
 *
 * @param {*} embed
 */
const loadYoutubeStreams = (embed = []) => {
    return get('/admin/youtube-streams', {
        params: { with: embed },
    }).catch((e) => {
        throw new Error(`Couldn't load individual endpoints: ${e}`);
    });
};

/**
 * Create a resource
 *
 * @param youtubeStream
 */
export const createYoutubeStream = (youtubeStream) => (dispatch) => {
    return post('/admin/youtube-streams', validateYoutubeStream(youtubeStream));
};

/**
 * Edit the resource
 *
 * @param youtubeStream
 */
export const editYoutubeStream = (youtubeStream) => (dispatch) => {
    return put(`/admin/youtube-streams/${youtubeStream.r_youtube_livestream_id}`, validateYoutubeStream(youtubeStream));
};

/**
 * Delete the resource
 *
 * @param id
 */
export const deleteYoutubeStream = (id) => (disaptch) => {
    return remove(`/admin/youtube-streams/${id}`);
};

/**
 * Fetch all individual endpoints for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllYoutubeStreams = (embed) => (dispatch) => {
    loadYoutubeStreams(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('youtubeStreams', data.data).entities)),
    );
};

/**
 * Reset individual endpoints for logged in client
 *
 * @param {*} embed
 */
export const resetYoutubeStreams = (embed) => (dispatch) => {
    loadYoutubeStreams(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                youtubeStreams: {},
                ...Controller.normalize('youtubeStreams', data.data).entities,
            }),
        ),
    );
};

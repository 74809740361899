import React from "react";

import DataForm from "../../DataForm/DataForm";

import { Input, Select, SelectOption } from "@tbh/ui-kit";

class Meetings extends DataForm {
  state = {
    fields: {
      identifier: "",
      date: "",
      type: "",
      name: "",
      state: "",
      events: "",
      country: "",
      grade: "",
      track_condition: "",
      weather: "",
      field_priority: ""
    }
  };

  render() {
    return (
      <fieldset>
        <Input
          name="identifier"
          value={this.state.fields.identifier}
          placeholder="identifier"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="date"
          value={this.state.fields.date}
          placeholder="date"
          onChange={this.handleChange}
          type="datetime-local"
        />
        <Select
          name="type"
          value={this.state.fields.type}
          onChange={this.handleChange}
        >
          <SelectOption label="r" value="r">
            r
          </SelectOption>
          <SelectOption label="g" value="g">
            g
          </SelectOption>
          <SelectOption label="h" value="h">
            h
          </SelectOption>
        </Select>
        <Input
          name="name"
          value={this.state.fields.name}
          placeholder="name"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="state"
          value={this.state.fields.state}
          placeholder="state"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="events"
          value={this.state.fields.events}
          placeholder="events"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="country"
          value={this.state.fields.country}
          placeholder="country"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="grade"
          value={this.state.fields.grade}
          placeholder="grade"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="track_condition"
          value={this.state.fields.track_condition}
          placeholder="track_condition"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="weather"
          value={this.state.fields.weather}
          placeholder="weather"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="field_priority"
          value={this.state.fields.field_priority}
          placeholder="field_priority"
          onChange={this.handleChange}
          type="text"
        />
      </fieldset>
    );
  }
}

export default Meetings;

import React from 'react';
import styled, { css } from 'react-emotion';
import PriceUpdatesSingle from "./PriceUpdatesBlock"
import PriceUpdates from "./PriceUpdates"

import '../../assets/css/prices/list-item.css';

const PriceUpdatesListItem = (props) => {    
    
    const races = props.data;
    const raceRows = [];

    for (let raceIdentifier in races){
        let sources = races[raceIdentifier];
        
        raceRows.push(
                    <tr>
                        <td>
                            <p className="race-identifier">{raceIdentifier}</p>
                            <div className="price-updates">
                            <PriceUpdates data={sources}/>
                            </div>
                        </td>
                    </tr>
                );
    }
    
    return raceRows;
};

export default PriceUpdatesListItem;

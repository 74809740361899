import React, { useState } from 'react';
import { ui, FlexText, CollapsibleIcon, spacings, Heading } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import styled, { css } from 'react-emotion/macro';
import SourceLabel from './SourceLabel';
import Card from '../../components/Ui/Card';
import Switch from '../../components/Ui/Switch';
import Button from "../../components/Ui/Button";


const OnOffSwitch = ({ label, on }) => {
	return (
		<FlexText>
			<div>{label}</div>
			<div>{!!on ? 'ON' : 'OFF'}</div>
		</FlexText>
	);
};

const Styled__SourceList = styled('div')(
	() => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
	`,
);

const SourceList = ({ sources }) => {
	return (
		<Styled__SourceList>
			{sources.map((source, index) => {
				return <SourceLabel key={source.id} label={source.name} />;
			})}
		</Styled__SourceList>
	);
};

const SubscriptionDetails = ({ sportPriorities, racingPriorities }) => {
	return (
		<FlexText>
			<div>
				<div>Sports Priorities</div>
				<div>{JSON.stringify(sportPriorities)}</div>
			</div>
			<div>
				<div>Racing Priorities</div>
				<div>{JSON.stringify(racingPriorities)}</div>
			</div>
		</FlexText>
	);
};

const Styled__CollapsibleIcon = styled('div')(
	(props) => css`
		display: flex;
		justify-content: center;
		back-color: ${ui(props).color_1};
		:hover {
			back-color: ${ui(props).color_4};
			cursor: pointer;
		}

		width: 100%;
		padding: ${spacings(props).compact}px;
	`,
);

const SubscriptionsListItem = ({
	id,
	racing,
	sport,
	description,
	sources,
	sourceOrder,
	sport_field_priorities,
	racing_field_priorities,
	editLink,
	testLink,
	raceCardLink,
	onDelete,
	className,
	...rest
}) => {
	const orderedSources = sourceOrder.map((sourceId) => sources.find((source) => source.id == sourceId));

	const [collapsed, setCollapsed] = useState(true);

	const toggle = () => {
		setCollapsed(!collapsed);
	};

	const handleCardClick = () => {
		if (collapsed) {
			toggle();
		}
	};

	const handleDelete = (e) => {
		e.stopPropagation();
		onDelete && onDelete(id);
	};

	const actionBarRender = () => {
		return (
			<Styled__CollapsibleIcon onClick={toggle}>
				<CollapsibleIcon isCollapsed={collapsed} />
			</Styled__CollapsibleIcon>
		);
	};

	return (
		<Card hover={collapsed} className={className} onClick={handleCardClick} actionBar={!collapsed && actionBarRender}>
			<FlexText>
				<Heading type="5">{description || '[Untitled]'}</Heading>
				<div>
					<Link to={editLink}><Button color="warning">Edit</Button> </Link> | <Link to={testLink}><Button color="info" >Test</Button></Link> | <Link to={raceCardLink}><Button color="success">Race Card</Button></Link> |{' '}
					<i onClick={handleDelete} ><Button color="danger">Delete</Button></i>
				</div>
			</FlexText>

			{!collapsed && (
				<div>
					<SourceList sources={orderedSources} />

					<label style={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
						<span>Racing</span>
						<Switch checked={!!racing} disabled />
					</label>
					<label style={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
						<span>Sports</span>
						<Switch checked={!!sport} disabled />
					</label>

					<div>
						<SubscriptionDetails sportPriorities={sport_field_priorities} racingPriorities={racing_field_priorities} />
					</div>
				</div>
			)}
		</Card>
	);
};

export default SubscriptionsListItem;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import IndividualEndpointList from '../../components/IndividualEndpoint/IndividualEndpointList';
import { fetchAllIndividualEndpoints, deleteIndividualEndpoint, resetIndividualEndpoints } from '../../store/entities/actions/IndividualEndpointActions';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import Button from "../../components/Ui/Button";

const IndividualEndpointListPage = (props) => {
	const { individualEndpoints, deleteIndividualEndpoint, fetchIndividualEndpoints } = props;

	useEffect(() => {
		fetchIndividualEndpoints();
	}, []);

	return (
		<div>
			<FlexText>
				<Heading>Individual Endpoint List</Heading>
				<Link to="/individual-endpoints/create">
					<Button color="primary" round>Create new Individual Endpoint</Button>
				</Link>
			</FlexText>
            <Table bordered  responsive={true} id="iveTable">
				<thead>
					<tr>
				   <th>Client Id</th>
					<th>Subscription Id</th>
					<th>Domain</th>
					<th>Model</th>
					<th>Endpoint</th>
					<th>Action</th>
					</tr>
				</thead>
				<tbody>
		         	<IndividualEndpointList individualEndpoints={individualEndpoints} onDelete={deleteIndividualEndpoint} />
				</tbody>
				</Table>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		individualEndpoints: Controller.denormalize('individualEndpoints', state.entities),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteIndividualEndpoint: (individualEndpointId) => {
			return dispatch(deleteIndividualEndpoint(individualEndpointId)).finally(() => {
				dispatch(resetIndividualEndpoints());
			});
		},

		fetchIndividualEndpoints: () => {
			dispatch(fetchAllIndividualEndpoints());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndividualEndpointListPage);

import React from "react";
import styled from 'styled-components';
import { Draggable } from "react-beautiful-dnd";

const MeetingDiv = styled.div`
    width: fit-content;
    background: ${props => props.color ? props.color : '#d8e2e2'};
    border-radius: 4px;
    padding: 13px 8px;
    margin: 5px;
    
    p{
        margin-bottom: 0;
        font-size: .7rem;
        font-weight: 600;
    }
    
    .meeting-type{
         font-family: "tbicons";
         margin-right: 8px;
    }
`;

const Meeting = (props) => {
    return <Draggable draggableId={props.meeting.meeting_identifier} index={props.index}>
        {provided => (
            <MeetingDiv ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} priority={props.priorityId} color={props.color}>
                <p>
                    <span className="meeting-type">{ props.meeting.meeting_identifier ? props.meeting.meeting_identifier.substr(0, 1).toLowerCase() : '' }</span>
                    {props.meeting.meeting_identifier}
                </p>
                {provided.placeholder}
            </MeetingDiv>
        )}
    </Draggable>
}

export default Meeting;
import React from 'react';
import styled, { css } from 'react-emotion';
import IndividualEndpointListItem from './IndividualEndpointListItem';
import { spacings } from '@tbh/ui-kit';
import { INDIVIDUAL_ENDPOINT_ROUTE } from '../../constants/RouteConstants';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

const Styled__IndividualEndpointListItem = styled(IndividualEndpointListItem)(
	(props) => css`
		padding-top: ${spacings(props).cozy}px;
		border-bottom: 1px solid grey;
	`,
);

const IndividualEndpointList = ({ individualEndpoints, onDelete }) => {
	let ivListItem= individualEndpoints.map((individualEndpoint) => {
		return <Styled__IndividualEndpointListItem key={individualEndpoint.id} onDelete={onDelete} {...individualEndpoint} editLink={`${INDIVIDUAL_ENDPOINT_ROUTE}/edit/${individualEndpoint.id}`} />;
	});

	if(ivListItem.length>0){

		$(document).ready(function () {
			$('#iveTable').DataTable();
		});
		return ivListItem;
	}else{

		return null;
	}
};

export default IndividualEndpointList;

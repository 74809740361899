import React, { useState, useEffect } from 'react';
import { typography, Select, SelectOption, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const IndividualEndpointForm = (props) => {
	const { onSubmit, onCancel, individualEndpoint, subscriptions, clients } = props;
    const[btn_load,setBtnLoad] = useState();
	const [subscription_id, setSubscriptionId] = useState();
	const [domain, setDomain] = useState();
	const [model, setModel] = useState();
	const [endpoint, setEndpoint] = useState();
	const [params, setParams] = useState();

	useEffect(() => {
		setBtnLoad(false);
		setSubscriptionId(individualEndpoint.subscription_id);
		setDomain(individualEndpoint.domain);
		setModel(individualEndpoint.model);
		setEndpoint(individualEndpoint.endpoint);
		setParams(individualEndpoint.params);
	}, [individualEndpoint]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			subscription_id,
			domain,
			model,
			endpoint,
			params,
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
    }
    
    /**
     * Create the subscription select items
     * 
     * @return array
     */
    function createSubscriptionSelectItems() {
        return subscriptions.map((item, key) =>
            <SelectOption value={item.id}>{item.description}</SelectOption>
        );
    }
    
    /**
     * Create the client select items
     * 
     * @return array
     */
    function createClientSelectItems() {
        return clients.map((item, key) =>
            <SelectOption value={item.id}>{item.name}</SelectOption>
        );
    }

	return (
		<form onSubmit={handleSubmit}>

			<Styled__Label>Subscription</Styled__Label>
			<Select value={subscription_id} onChange={(name, value) => setSubscriptionId(value)}>
                {createSubscriptionSelectItems()}
            </Select>

			<Styled__Label>Domain</Styled__Label>
			<Select value={domain} onChange={(name, value) => setDomain(value)}>
                <SelectOption value="racing">Racing</SelectOption>
                <SelectOption value="sport">Sport</SelectOption>
            </Select>

			<Styled__Label>Model</Styled__Label>
			<Input value={model} onChange={(name, value) => setModel(value)} />

			<Styled__Label>Endpoint</Styled__Label>
			<Input value={endpoint} onChange={(name, value) => setEndpoint(value)} />

            <Styled__Label>Paramters (JSON)</Styled__Label>
            <Input value={params} onChange={(name, value) => setParams(value)} />

			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} size="large" loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>

		</form>
	);
};

IndividualEndpointForm.defaultProps = {
	individualEndpoint: {
		subscription_id: 1,
		domain: 'racing',
		model: '',
		endpoint: '',
		params: '',
	},
};

export default IndividualEndpointForm;

// import React from 'react';

import React, { useEffect, useState } from 'react';
import { Heading, FlexText } from '@tbh/ui-kit';
import { generateToken} from '../../store/entities/actions/GenerateGbsTokenActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "../../components/Ui/Button"

class GenerateGbsTokenPage extends React.Component {

    render() {

        return <div>
            <FlexText>
				<Heading>Generate GBS Token</Heading>
			</FlexText>
            <List aria-label="secondary">
                <ListItem>
                    <ListItemText primary="Generate GBS Token" />
                    <Button onClick={generateToken} color="primary" round>Generate GBS Token</Button>
                </ListItem>
            </List>
        </div>;
    }
}



export default GenerateGbsTokenPage;
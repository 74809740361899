import React from 'react';
import { connect } from 'react-redux';
import { Heading } from '@tbh/ui-kit';
import { createRcnGbsMap } from '../../store/entities/actions/RcnGbsMapActions';
import Card from '../../components/Ui/Card';
import RcnGbsMapForm from "../../components/RcnGbsMap/RcnGbsMapForm";

const RcnGbsMapCreateContainer = ({ createRcnGbsMap, history, ...rest }) => {
	function handleFormSubmit(formData) {
		createRcnGbsMap(formData).then(() => {
			history.push('/rcn-gbs-map');
		});
	}

	function handleCancel() {
		history.push('/rcn-gbs-map');
	}

	return (
		<div>
			<Heading>Map RCN/SIS to GBS</Heading>
			<Card>
				<RcnGbsMapForm onSubmit={handleFormSubmit} onCancel={handleCancel} />
			</Card>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		createRcnGbsMap: (formData) => {
			return dispatch(createRcnGbsMap(formData));
		},
	};
};

export default connect(
	null,
	mapDispatchToProps,
)(RcnGbsMapCreateContainer);

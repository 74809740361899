import React from "react";
import PropTypes from "prop-types";

class DataForm extends React.Component {
  state = {
    fields: {}
  };

  componentDidMount() {
    this.props.onDataChange(this.state.fields);
  }

  componentWillUnmount() {
    this.props.onDataChange(null);
  }

  handleChange = (name, value) => {
    this.setState(
      prevState => ({
        fields: {
          ...prevState.fields,
          [name]: value
        }
      }),
      () => {
        this.props.onDataChange(this.state.fields);
      }
    );
  };
}

DataForm.propTypes = {
  /** Func that is called when a field is changed */
  onDataChange: PropTypes.func.isRequired
};

export default DataForm;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import ProductList from '../../components/Products/ProductList';
import { fetchAllProducts, deleteProduct, resetProducts } from '../../store/entities/actions/ProductAction';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import Button from "../../components/Ui/Button";

const ProductListPage = (props) => {
	const { products, deleteProduct, fetchProducts } = props;

	useEffect(() => {
		fetchProducts();
	}, []);

	return (
		<div>
			<FlexText>
				<Heading>Product List</Heading>
				<Link to="/manage-products/create">
					<Button color="primary" round>Create new Product</Button>
				</Link>
			</FlexText>
            <Table bordered  responsive={true} id="iveTable">
				<thead>
					<tr>
				   <th>Product Id</th>
					<th>Product Name</th>
					<th>Product Description</th>
					<th>GBS Product Name</th>
					<th>Action</th>
					</tr>
				</thead>
				<tbody>
		         	<ProductList products={products} onDelete={deleteProduct} />
				</tbody>
				</Table>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		products: Controller.denormalize('products', state.entities),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteProduct: (id) => {
			return dispatch(deleteProduct(id)).finally(() => {
				dispatch(resetProducts());
			});
		},

		fetchProducts: () => {
			dispatch(fetchAllProducts());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductListPage);

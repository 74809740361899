import React from 'react';
import RaceItem from './RaceItem';
import styled from 'react-emotion';

const Styled_RacesList = styled('div')`
	display: flex;
`;

const MeetingAndRaces = ({ match, name, races = [] }) => {
	const { id } = match.params;

	return (
		<div>
			<div>~{name}~</div>

			<Styled_RacesList>
				{races.map((race) => {
					const baseUrl = `/race-card/${id}/race/${race.races_identifier}`;
					return <RaceItem url={baseUrl} number={race.races_number} />;
				})}
			</Styled_RacesList>
		</div>
	);
};

export default MeetingAndRaces;

import { get } from '../../../common/ajax';
import Controller from '../controller';
import { mergeEntities } from 'redux-entities-reducer';

export const fetchAllSources = (embed = []) => (dispatch) => {
	return get('/admin/sources', {
		params: { with: embed },
	})
		.catch((e) => {
			throw new Error(`Couldn't load subscriptions: ${e}`);
		})
		.then(({ data }) => dispatch(mergeEntities(Controller.normalize('sources', data.data).entities)));
};

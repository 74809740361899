import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Validate the resource.
 *
 * @param {*} formData
 */
const validateSqsQueue = (sqsQueue) => {
    return sqsQueue;
};

/**
 * Load sqs queues for the logged in client
 *
 * @param {*} embed
 */
const loadSqsQueues = (embed = []) => {
    return get('/admin/sqs-queues', {
        params: { with: embed },
    }).catch((e) => {
        throw new Error(`Couldn't load sqs queues: ${e}`);
    });
};

/**
 * Create a resource
 *
 * @param {*} formData
 */
export const createSqsQueue = (sqsQueue) => (dispatch) => {
    return post('/admin/sqs-queues', validateSqsQueue(sqsQueue));
};

/**
 * Edit the resource
 *
 * @param {*} sqsQueue
 */
export const editSqsQueue = (sqsQueue) => (dispatch) => {
    return put(`/admin/sqs-queues/${sqsQueue.id}`, validateSqsQueue(sqsQueue));
};

/**
 * Delete the resource
 *
 * @param {*} sqsQueueId
 */
export const deleteSqsQueue = (sqsQueueId) => (disaptch) => {
    return remove(`/admin/sqs-queues/${sqsQueueId}`);
};

/**
 * Fetch all sqs queues for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllSqsQueues = (embed) => (dispatch) => {
    loadSqsQueues(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('sqsQueues', data.data).entities)),
    );
};

/**
 * Reset sqs queues for logged in client
 *
 * @param {*} embed
 */
export const resetSqsQueues = (embed) => (dispatch) => {
    loadSqsQueues(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                sqsQueues: {},
                ...Controller.normalize('sqsQueues', data.data).entities,
            }),
        ),
    );
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SisForm from '../../components/Sis/SisForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllVenueMaps, editSisVenueMap } from '../../store/entities/actions/SisActions';
import Card from '../../components/Ui/Card';

const SisEditContainer = ({ sis, fetchVenueMaps, createSisVenueMap, history, editSisVenueMap, match, ...rest }) => {
	useEffect(() => {
		fetchVenueMaps();
	}, []);

	function handleFormSubmit(formData) {
		editSisVenueMap({ ...sis, ...formData }).then(() => {
			history.push('/sis');
		});
	}

	function handleCancel() {
		history.push('/sis');
	}

	return (
		<div>
			<Heading>Edit Venue Maps</Heading>
			<Card>
				<SisForm onSubmit={handleFormSubmit} onCancel={handleCancel} sis={sis} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const siss = Controller.denormalize('siss', state.entities);

	const sis = siss.find((sis) => {
		return sis.id == ownProps.match.params.id;
	});

	return {
		sis,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchVenueMaps: () => {
			dispatch(fetchAllVenueMaps());
		},

		editSisVenueMap: (formData) => {
			return dispatch(editSisVenueMap(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SisEditContainer);

import { theme } from "@tbh/ui-kit";

const initialState = {
  ...theme,
  brand: {
    color_1: "#408AD2",
    color_1_light: "#679ED2",
    color_1_dark: "#0C5AA6",

    color_2: "#111111",
    color_2_light: "#333333",
    color_2_dark: "#000000",

    color_3: "#32be60",
    color_3_light: "#3cc86a",
    color_3_dark: "#28b456",

    color_4: "#666666",
    color_4_light: "#707070",
    color_4_dark: "#5c5c5c"
  }
};

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const rainbow = () => {
  var r = getRandomInt(0, 255);
  var g = getRandomInt(0, 255);
  var b = getRandomInt(0, 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};

/**
 * Favourites reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const themeReducer = (state = initialState, action) => {
  const newTheme = { ...state };
  switch (action.type) {
    case "PIZZAZZ":
      return {
        ...state,
        brand: {
          ...state.brand,
          color_1: rainbow(),
          color_2: rainbow(),
          color_3: rainbow(),
          color_4: rainbow()
        }
      };

    case "APPLY_THEME":
      Object.keys(action.payload).forEach(key => {
        newTheme[key] = {
          ...state[key],
          ...action.payload[key]
        };
      });

      return newTheme;

    default:
      return state;
  }
};

export default themeReducer;

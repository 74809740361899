import React from 'react';
import PriceUpdatesBlock from "./PriceUpdatesBlock"

const PriceUpdates = (props) => {

    let updatesList = [];
    const sources = props.data;
    
    for (let sourceName in sources){
        let updates = sources[sourceName];

        updatesList.push(
            <table className="table-updates">
                <thead>
                    <tr>
                        <th>{sourceName}</th>
                    </tr>
                </thead>
                <PriceUpdatesBlock updates={updates}/>  
            </table>
        );
    }

    return updatesList;
};

export default PriceUpdates;

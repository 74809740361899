import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Load Hr1 stream maps for the logged in client
 *
 * @param {*} embed
 */
const loadHr1StreamMaps = (embed = []) => {

	return get('/admin/manage-hr1-stream', {
		params: { with: embed },
	}).catch((e) => {
		throw new Error(`Couldn't load Hr1Streams: ${e}`);
	});
};

/**
 * Create a resource
 *
 * @param Hr1stream
 */
export const createHr1stream = (hr1Stream) => (dispatch) => {
	return post('/admin/manage-hr1-stream', validateHr1Stream(hr1Stream));
};

/**
 * Edit a resource
 *
 * @param Hr1stream
 */

export const editHr1stream = (hr1Stream) => (dispatch) => {
	return put(`/admin/manage-hr1-stream/${hr1Stream.id}`, validateHr1Stream(hr1Stream));
};


/**
 * Delete the resource
 *
 * @param id
 */
export const deleteHr1Stream = (id) => (disaptch) => {
    return remove(`/admin/manage-hr1-stream/${id}`);
};


const validateHr1Stream = (Hr1stream) => {
	return Hr1stream;
};


/**
 * Fetch all individual endpoints for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllHr1Streams = (embed) => (dispatch) => {
    loadHr1StreamMaps(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('hr1Stream', data.data).entities)),
    );
};

/**
 * Reset individual endpoints for logged in client
 *
 * @param {*} embed
 */
export const resetHr1Streams = (embed) => (dispatch) => {
    loadHr1StreamMaps(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                hr1Stream: {},
                ...Controller.normalize('hr1Stream', data.data).entities,
            }),
        ),
    );
};


import React, { useState, useEffect } from 'react';
import { typography, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const RcnForm = (props) => {
	const { onSubmit, onCancel, rcn } = props;
	const[btn_load,setBtnLoad] = useState();
	const [venue_key, setVenueKey] = useState();
	const [private_key, setPrivateKey] = useState();
	const [referer, setReferer] = useState();
	const [stream_name, setStreamName] = useState();
	const [extra_params, setExtraParams] = useState();
	const [hd, setHd] = useState();
	const [feed, setFeed] = useState();
	const [volume, setVolume] = useState();

	useEffect(() => {
        setBtnLoad(false);
		setVenueKey(rcn.venue_key);
		setPrivateKey(rcn.private_key);
		setReferer(rcn.referer);
		setStreamName(rcn.stream_name);
		setExtraParams(rcn.extra_params);
		setHd(rcn.hd);
		setFeed(rcn.feed);
		setVolume(rcn.volume);
	}, [rcn]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
        setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			venue_key,
			private_key,
			referer,
			stream_name,
			extra_params,
			hd,
			feed,
            volume
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Styled__Label>Venue Key</Styled__Label>
			<Input value={venue_key} onChange={(name, value) => setVenueKey(value)} />
			<Styled__Label>Private Key</Styled__Label>
			<Input value={private_key} onChange={(name, value) => setPrivateKey(value)} />
			<Styled__Label>Referer</Styled__Label>
			<Input value={referer} onChange={(name, value) => setReferer(value)} />
			<Styled__Label>Stream Name Key</Styled__Label>
			<Input value={stream_name} onChange={(name, value) => setStreamName(value)} />
			<Styled__Label>Extra Params</Styled__Label>
			<Input value={extra_params} onChange={(name, value) => setExtraParams(value)} />
			<Styled__Label>HD</Styled__Label>
			<Input value={hd} onChange={(name, value) => setHd(value)} />
			<Styled__Label>Feed</Styled__Label>
			<Input value={feed} onChange={(name, value) => setFeed(value)} />

    		<Styled__Label>Volume</Styled__Label>
   			<Input value={volume} onChange={(name, value) => setVolume(parseFloat(value? value : 0))} type="number" />

			<Button action={handleCancel} size="large" type="secondary"> Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} size="large" loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>
		</form>
	);
};

RcnForm.defaultProps = {
	rcn: {
		venue_key: '',
		private_key: '',
		referer: '',
		stream_name: '',
		extra_params: '',
		hd: 0,
		feed: '',
		volume:0
	},
};

export default RcnForm;

import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import GwsListContainer from './GwsListContainer';
import GwsCreateContainer from './GwsCreateContainer';
import GwsEditContainer from './GwsEditContainer';

const GwsPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={GwsListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={GwsCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={GwsEditContainer} />

		</>
	);
};

const mapStateToProps = (state) => {
	return {
		gwss: Controller.denormalize('gwss', state.entities),
	};
};

export default connect(mapStateToProps)(GwsPage);

import React, { useState, useEffect } from 'react';
import { typography, Select, SelectOption, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const ProductForm = (props) => {
	const { onSubmit, onCancel, product } = props;
    const[btn_load,setBtnLoad] = useState();
	const [name, setProductName] = useState();
	const [description, setProductDescription] = useState();
	const [gbs_product_name, setGbsProductName] = useState();

	useEffect(() => {
		setBtnLoad(false);

		setProductName(product.name);
		setProductDescription(product.description);
		setGbsProductName(product.gbs_product_name);
	}, [product]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
		setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			name,
			description,
			gbs_product_name,
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
    }


	return (
		<form onSubmit={handleSubmit}>

			<Styled__Label>Product Name</Styled__Label>
			<Input value={name} onChange={(name, value) => setProductName(value)} />

			<Styled__Label>Product Description</Styled__Label>
			<Input value={description} onChange={(name, value) => setProductDescription(value)} />
			<Styled__Label>GBS Product Name</Styled__Label>
			<Input value={gbs_product_name} onChange={(name, value) => setGbsProductName(value)} />
			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} type={Button.types.PRIMARY} size="large" loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>

		</form>
	);
};

ProductForm.defaultProps = {
	product: {
		name: '',
		description: '',
		gbs_product_name: '',
	},
};

export default ProductForm;

import React from "react";

import DataForm from "../../DataForm/DataForm";

import { Input } from "@tbh/ui-kit";

class Owners extends DataForm {
  state = {
    fields: {
      owner_id: "",
      trainer_id: "",
      code: "",
      type: "",
      name: "",
      colour: "",
      sex: "",
      age: "",
      foal_date: "",
      sire: "",
      dam: ""
    }
  };

  render() {
    return (
      <fieldset>
        <Input
          name="owner_id"
          value={this.state.fields.owner_id}
          placeholder="owner_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="trainer_id"
          value={this.state.fields.trainer_id}
          placeholder="trainer_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="code"
          value={this.state.fields.code}
          placeholder="code"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="type"
          value={this.state.fields.type}
          placeholder="type"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="name"
          value={this.state.fields.name}
          placeholder="name"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="colour"
          value={this.state.fields.colour}
          placeholder="colour"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="sex"
          value={this.state.fields.sex}
          placeholder="sex"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="age"
          value={this.state.fields.age}
          placeholder="age"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="foal_date"
          value={this.state.fields.foal_date}
          placeholder="foal_date"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="sire"
          value={this.state.fields.sire}
          placeholder="sire"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="dam"
          value={this.state.fields.dam}
          placeholder="dam"
          onChange={this.handleChange}
          type="text"
        />
      </fieldset>
    );
  }
}

export default Owners;

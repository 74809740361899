import React from 'react';
import { connect } from 'react-redux';

const UserPage = () => {
	return 'User page';
};

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(UserPage);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProductForm from '../../components/Products/ProductForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { createProduct } from '../../store/entities/actions/ProductAction';
import Card from '../../components/Ui/Card';

const ProductCreateContainer = ({ createProduct, history, ...rest }) => {
    
	function handleFormSubmit(formData) {
		createProduct(formData).then(() => {
			history.push('/manage-products');
		});
	}

	function handleCancel() {
		history.push('/manage-products');
	}

	return (
		<div>
			<Heading>Create Product</Heading>
			<Card>
				<ProductForm onSubmit={handleFormSubmit} onCancel={handleCancel}  />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = (dispatch) => {
	return {
		createProduct: (formData) => {
			return dispatch(createProduct(formData));
        },


	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductCreateContainer);

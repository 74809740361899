import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import RcnList from '../../components/Rcn/RcnList';
import { fetchAllVenueMaps, deleteRcnVenueMap, resetVenueMaps } from '../../store/entities/actions/RcnActions';
import { Heading, FlexText } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../../components/Ui/Button";
import Table from 'react-bootstrap/Table'
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';

class RcnListPage extends React.Component{
	componentWillMount() {
   	this.props.fetchVenueMaps();
   	console.log('will mount')

   }
   componentDidUpdate(prevProps, prevState, snapshot) {

	}
   render(){
		const { rcns, deleteRcnVenueMap, fetchVenueMaps } = this.props
	return (
		<div>
			<FlexText>

				<Heading strong padded={true}>Rcn Venue Map List</Heading>
				<ToastContainer autoClose={2000} />
				<Link to="/rcn/create" >
					<Button color="primary" round>Create new Rcn Venue Map</Button>
				</Link>
			</FlexText>
			<Table   bordered responsive={true} id="rcntable" width="100%">
				<thead>
				<tr>
					<th>Venue Key</th>
					<th>Private Key</th>
					<th>Referer</th>
					<th>Stream Name</th>
					<th>Extra Params</th>
					<th>Feed</th>
					<th>Hd</th>
					<th>Volume</th>
					<th>Action</th>
				</tr>
				</thead>
				<tbody>
				<RcnList rcns={rcns} onDelete={deleteRcnVenueMap} />
				</tbody>
			</Table>
		</div>
	)
};
}
const mapStateToProps = (state) => {
	return {
		rcns: Controller.denormalize('rcns', state.entities),

	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		deleteRcnVenueMap: (venueMapId) => {
			return dispatch(deleteRcnVenueMap(venueMapId)).finally(() => {
				dispatch(resetVenueMaps());
			});
		},

		fetchVenueMaps: async () => {
			dispatch(fetchAllVenueMaps());
		},
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RcnListPage);

import React from "react";
import {Heading, FlexText, LoadingMask} from '@tbh/ui-kit';
import {
    fetchMeetingPriorityData,
    updatePriorities,
    updatePriorityOrder,
} from "../../store/entities/actions/MeetingPrioritiesActions";
import {Link} from "react-router-dom";
import Button from "../../components/Ui/Button";
import {DragDropContext} from "react-beautiful-dnd";
import PriorityList from "../../components/MeetingPriorities/PriorityList";
import styled from 'styled-components';

const Note = styled.div`
    font-size: 12px;
    margin-bottom: 15px;
    
    p{
        margin-bottom: 1px;
    }
    
    i{
        font-size: 12px;
    }
`;

class MeetingPrioritiesList extends React.Component {

    state = {
        loading: false,
        meetings: [],
        priorities: [
            {
                meeting_priorities_id: 0,
                meeting_priorities_name: 'Meetings',
                meeting_priorities_priority: 0,
                meetings: [],
            },
        ],
    };

    constructor(props){
        super(props);

        this.handleRemovePriority = this.handleRemovePriority.bind(this);
        this.getPriorityById = this.getPriorityById.bind(this);
        this.getMeetingById = this.getMeetingById.bind(this);
        this.displayLoadingMask = this.displayLoadingMask.bind(this);
        this.hideLoadingMask = this.hideLoadingMask.bind(this);
    }

    componentDidMount() {
        this.displayLoadingMask();

        fetchMeetingPriorityData()
            .then(result => {

                const {meetings, unprioritized_meetings, priorities} = result;

                let newPriorities = this.state.priorities;
                newPriorities[0].meetings = unprioritized_meetings;
                newPriorities = newPriorities.concat(priorities);

                this.setState({
                    meetings: meetings,
                    priorities: newPriorities,
                });

                this.hideLoadingMask();

            });

    }

    onDragEnd = (result) => {
        const { source, destination, draggableId, type } = result;

        if( ! destination){
            return;
        }

        if(type === 'priority'){

            if(destination.index === 0){
                return;
            }

            const draggedPriority = this.getPriorityById(draggableId);

            if(draggedPriority && draggedPriority !== 'meetings-list'){

                this.displayLoadingMask();

                let newPrioritiesList = this.state.priorities;

                // Remove dragged index
                newPrioritiesList = this.removeElement(newPrioritiesList, source.index);
                // Add dropped index
                newPrioritiesList.splice(destination.index, 0, draggedPriority);

                this.setState({
                    priorities: newPrioritiesList
                });

                this.updatePriorityOrder(newPrioritiesList);

            }

            return;
        }

        // Prevent drag and drop in same column
        if(destination.droppableId === source.droppableId){
            return;
        }

        const start = this.getPriorityById(source.droppableId);
        const finish = this.getPriorityById(destination.droppableId);
        const draggedMeeting = this.getMeetingById(draggableId);

        if(start && finish && draggedMeeting) {

           this.displayLoadingMask();

            // Start
            let startMeetings = start.meetings;
            startMeetings = this.removeElement(startMeetings, source.index);

            const newStartPriority = {
                ...start,
                meetings: startMeetings,
            };

            // Finish
            const finishMeetings = finish.meetings;
            finishMeetings.splice(destination.index, 0, draggedMeeting);

            const newFinishPriority = {
                ...finish,
                meetings: finishMeetings,
            };

            // Set new priorities
            let newPriorities = this.state.priorities;
            newPriorities[this.state.priorities.indexOf(start)] = newStartPriority;
            newPriorities[this.state.priorities.indexOf(finish)] = newFinishPriority;

            this.setState({
                priorities: newPriorities
            });

            this.updatePriorities(newStartPriority, newFinishPriority);
        }

    }

    isMeetingPrioritized(priorities, partialMeetingIdentifier){
        let prioritized = false;

        let filtered = priorities.map((priority, index) => {
            return priority.meetings.map(meeting => {
                if (meeting.priority_meetings_meeting_identifier == partialMeetingIdentifier) {
                    prioritized = true;
                }
            });
        });

        return prioritized;
    }

    getPriorityById(id){
        let foundPriority = this.state.priorities[0];
        this.state.priorities.forEach(priority => {
            if('priority-' + priority.meeting_priorities_id === id){
                foundPriority = priority;
            }
        });

        return foundPriority;
    }

    getMeetingById(id){
        let foundMeeting;

        this.state.meetings.forEach( meeting => {
            if( (meeting.meeting_identifier) === id){
                foundMeeting  = meeting;
            }
        });

        return foundMeeting;
    }

    removeElement(array, index){
        return array.filter((value, filterIndex) => {
            return filterIndex !== index;
        });
    }

    updatePriorityOrder(priorityList){

        let priorityOrder = [];
        priorityList.map((priority, index) => {
            if(priority.meeting_priorities_id > 0){
                priorityOrder.push(priority.meeting_priorities_id);
            }
        });

        let data = {
            priority_order: priorityOrder,
        }

        updatePriorityOrder(data).then((result)=>{
            this.hideLoadingMask();
        });
    }

    updatePriorities(updatedFrom, updatedTo){

        let data = {
            priorities: [
                updatedFrom,
                updatedTo
            ],
        }

        updatePriorities(data).then((result)=>{
            this.hideLoadingMask();
        });
    }

    displayLoadingMask(){
        this.setState({
            loading: true,
        })
    }

    hideLoadingMask(){
        this.setState({
            loading: false,
        })
    }

    handleRemovePriority(priorityId)
    {
        let newPriorities = this.state.priorities.filter( priority => {
            return priority.meeting_priorities_id !== priorityId;
        });

        this.setState({
            priorities: newPriorities,
        });
    }

    render() {

        const LoadingStyles = {
            left: '260px',
            background: '#ffffff4f',
        }

        return <div>
            <FlexText>
                <Heading>
                    <h4>Meeting priorities</h4>
                </Heading>
                <Link to="/meeting-priorities/create">
                    <Button color="primary" round>Create priority</Button>
                </Link>
            </FlexText>

            <Note>
                <p>Drag meetings and drop on a priority to change the meeting priority</p>
                <p>You can change the priority level by reordring them using drag and drop.</p>
                <p>Use the drag handle(<i className="material-icons">drag_indicator</i>) to drag a priority</p>
            </Note>

            <DragDropContext onDragEnd={this.onDragEnd}>
                <LoadingMask loading={this.state.loading} style={LoadingStyles}/>
                <PriorityList priorities={this.state.priorities} removePirotiyAction={this.handleRemovePriority}/>
            </DragDropContext>

        </div>
    }
}

export default MeetingPrioritiesList;
import { combineReducers } from 'redux';

import theme from './theme/themeReducer';
import entities from './entities/reducer';
import application from './application/applicationReducer';
import toast from './toasts/toastReducer';

export default combineReducers({
	entities,
	application,
	theme,
    toast,
});

import React, { useState, useEffect } from 'react';
import { typography, Input, Button, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';

const Styled__Label = styled('label')(
	(props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const SisForm = (props) => {
	const { onSubmit, onCancel, sis } = props;
	const[btn_load,setBtnLoad] = useState();
	const [venue_key, setVenueKey] = useState();
	const [secure_protocol, setSecureProtocol] = useState();
	const [low_latency, setLowLatency] = useState();
	const [competition, setCompetition] = useState();
	const [stream_to_pick, setStreamToPick] = useState();

	useEffect(() => {
        setBtnLoad(false);
		setVenueKey(sis.venue_key);
		setSecureProtocol(sis.secure_protocol);
		setLowLatency(sis.low_latency);
		setCompetition(sis.competition);
		setStreamToPick(sis.stream_to_pick);
	}, [sis]);

	/**
	 * Handle the submit of the form
	 *
	 * @param {*} e
	 */
	function handleSubmit(e) {
		// STOP!
		e.preventDefault();
		e.stopPropagation();
        setBtnLoad(true);
		// Call the parent handler with the current state of the form
		onSubmit({
			venue_key,
			secure_protocol,
			low_latency,
			competition,
			stream_to_pick
		});
	}

	function handleCancel(e) {
		e.preventDefault();
		e.stopPropagation();

		if (onCancel) {
			onCancel();
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Styled__Label>Venue Key</Styled__Label>
			<Input value={venue_key} onChange={(name, value) => setVenueKey(value)} />
			<Styled__Label>Secure Protocol</Styled__Label>
			<Input value={secure_protocol} onChange={(name, value) => setSecureProtocol(value)} />
			<Styled__Label>Low Latency</Styled__Label>
			<Input value={low_latency} onChange={(name, value) => setLowLatency(value)} />
			<Styled__Label>Competition</Styled__Label>
			<Input value={competition} onChange={(name, value) => setCompetition(value)} />
			<Styled__Label>Stream To Pick</Styled__Label>
			<Input value={stream_to_pick} onChange={(name, value) => setStreamToPick(value)} />

			<Button action={handleCancel} size="large" type="secondary">Cancel</Button>
			<Button onClick={handleSubmit} size="large" type={Button.types.PRIMARY} loading={btn_load} onSubmit={handleSubmit}>
				Submit
			</Button>
		</form>
	);
};

SisForm.defaultProps = {
	sis: {
		venue_key: '',
		secure_protocol: '',
		low_latency: '',
		competition: '',
	},
};

export default SisForm;

import React from 'react';
import {typography, Input, Button, spacings} from '@tbh/ui-kit';
import styled, {css} from "react-emotion";
import {ChromePicker} from 'react-color';

const Styled__Label = styled('label')(
    (props) => css`
		color: ${typography(props).color_2};
		font-size: ${typography(props)['size_-2']};
		margin-bottom: ${spacings(props).cozy};
	`,
);

const ColorPickerInput = styled('input')(
    (props) => css`
        border: none;
        border-bottom: 1px solid #dddddd;
        background: ${props.value ? props.value : ''};
        height: 35px;
        width: 76px;
        padding: 5px;
    `,
)

const ColorPickerOverlay = styled('div')(
    (props) => css`
        position: fixed;
        z-index: 99;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
    `,
)

const FloatingContainer = styled('div')(
    (props) => css`
        position: absolute;
        z-index: 100;
    `
)

class CreateMeetingPriorityForm extends React.Component {
    state = {
        name: '',
        color: '#d8e2e2',
        showColorPicker: false,
        onCancel: () => {
        },
        onSubmit: () => {
        },
    }

    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.displayColorPicker = this.displayColorPicker.bind(this);
        this.hideColorPicker = this.hideColorPicker.bind(this);
        this.handleChangeComplete = this.handleChangeComplete.bind(this);
    }

    componentDidMount() {
        this.setState({
            onCancel: this.props.onCancel,
            onSubmit: this.props.onSubmit,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        let data = {
            name: this.state.name,
            color: this.state.color,
        }

        this.state.onSubmit(data);
    }

    handleCancel(e) {
        e.preventDefault();
        e.stopPropagation();

        this.state.onCancel();
    }

    // set states
    setInputValue(name, value){
        this.setState({
            [name]: value,
        })
    }

    setPriorityValue(value){
        if(parseInt(value) <= 0){
            value = 1;
        }

        return this.setInputValue('priority', value);
    }

    displayColorPicker() {
        this.setState({
            showColorPicker: true
        });
    }

    hideColorPicker(){
        this.setState({
            showColorPicker: false
        });
    }

    handleChangeComplete(color) {
        color = color ? color.hex : '';
        this.setState({ color:  color });
    };

    render() {
        return <form onSubmit={this.handleSubmit}>
                    <Styled__Label>Name</Styled__Label>
                    <Input  value={this.state.name} onChange={ (name, value) => this.setInputValue('name', value) }/>
                    <Styled__Label>Color</Styled__Label>
                    <div>
                        <ColorPickerInput value={this.state.color} onFocus={this.displayColorPicker}/>
                        {this.state.showColorPicker ?
                            <div>
                                <ColorPickerOverlay onClick={this.hideColorPicker}/>
                                <FloatingContainer>
                                    <ChromePicker color={this.state.color} onChangeComplete={this.handleChangeComplete} />
                                </FloatingContainer>
                            </div>
                            : ''
                        }
                    </div>

                    <div style={{marginTop: '15px', textAlign: 'right'}}>
                        <Button action={this.handleCancel} size="medium" square={true} type="secondary"> Cancel</Button>

                        <Button type={Button.types.PRIMARY} square={true} size="medium" loading={false}>
                            Submit
                        </Button>
                    </div>
                </form>
    }
}

export default CreateMeetingPriorityForm;
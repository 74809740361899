import React from 'react';
import CreateMeetingPriorityForm from "./CreateMeetingPriorityForm";
import {getPriorityData} from "../../store/entities/actions/MeetingPrioritiesActions";

class EditMeetingPriorityForm extends CreateMeetingPriorityForm
{
    state = {
        ...this.state,
        priority_id: '',
    }

    componentDidMount() {
        super.componentDidMount();

        getPriorityData(this.props.priorityId).then((result) => {
           this.setState({
               priority_id: result.meeting_priorities_id,
               name: result.meeting_priorities_name,
               color: result.meeting_priorities_color ? result.meeting_priorities_color : '#d8e2e2'
           })
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        let data = {
            priority_id: this.state.priority_id,
            name: this.state.name,
            priority: this.state.priority,
            color: this.state.color,
        }

        this.state.onSubmit(data)
    }
}

export default EditMeetingPriorityForm;
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import IndividualEndpointForm from '../../components/IndividualEndpoint/IndividualEndpointForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { createIndividualEndpoint } from '../../store/entities/actions/IndividualEndpointActions';
import { fetchSubscriptionsForCurrentUser } from '../../store/entities/actions/SubscriptionActions';
import { fetchAllClients } from '../../store/entities/actions/ClientActions';
import Card from '../../components/Ui/Card';

const IndividualEndpointCreateContainer = ({ createIndividualEndpoint, fetchSubscriptions, subscriptions, fetchClients, clients, history, ...rest }) => {
    useEffect(() => {
		fetchSubscriptions();
		fetchClients();
    }, []);
    
	function handleFormSubmit(formData) {
		createIndividualEndpoint(formData).then(() => {
			history.push('/individual-endpoints');
		});
	}

	function handleCancel() {
		history.push('/individual-endpoints');
	}

	return (
		<div>
			<Heading>Create Individual Endpoint</Heading>
			<Card>
				<IndividualEndpointForm onSubmit={handleFormSubmit} onCancel={handleCancel} subscriptions={subscriptions} clients={clients} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
    const subscriptions = Controller.denormalize('subscriptions', state.entities);
	const clients = Controller.denormalize('clients', state.entities);

	return {
		subscriptions,
		clients,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createIndividualEndpoint: (formData) => {
			return dispatch(createIndividualEndpoint(formData));
        },
        
		fetchSubscriptions: () => {
			dispatch(fetchSubscriptionsForCurrentUser());
		},
        
		fetchClients: () => {
			dispatch(fetchAllClients());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndividualEndpointCreateContainer);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SubscriptionForm from '../../components/Subscriptions/SubscriptionForm';
import Controller from '../../store/entities/controller';
import { fetchAllSources } from '../../store/entities/actions/SourceActions';
import { Heading, Button } from '@tbh/ui-kit';
import { createSubscription } from '../../store/entities/actions/SubscriptionActions';
import Card from '../../components/Ui/Card';
import { fetchAllDeliveryTypes } from '../../store/entities/actions/DeliveryTypeActions';

const SubscriptionsCreateContainer = ({ sources, fetchSources, fetchDeliveryTypes, createSubscription, history, deliveryTypes, ...rest }) => {
	useEffect(() => {
        fetchDeliveryTypes();
		fetchSources();
	}, []);

	function handleFormSubmit(formData) {
		createSubscription(formData).then(() => {
			history.push('/subscriptions');
		});
	}

	function handleCancel() {
		history.push('/subscriptions');
	}

	return (
		<div>
			<Heading>Create Subscription</Heading>
			<Card>
				<SubscriptionForm sources={sources} deliveryTypes={deliveryTypes} onSubmit={handleFormSubmit} onCancel={handleCancel} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		deliveryTypes: Controller.denormalize('deliveryTypes', state.entities),
		sources: Controller.denormalize('sources', state.entities),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSources: () => {
			dispatch(fetchAllSources());
		},
        
		fetchDeliveryTypes: () => {
			dispatch(fetchAllDeliveryTypes());
		},

		createSubscription: (formData) => {
			return dispatch(createSubscription(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SubscriptionsCreateContainer);

import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import PricesTable from './PricesTable';

const Styled_RaceCardTD = styled('td')`
	vertical-align: text-top;
`;

const Styled_RaceCardDetailsTable = styled('table')`
	width: 100%;
	margin-bottom: 20px;
`;

const Styled_RaceCardDetailsTd = styled('td')`
	padding: 3px;
`;

const RaceCard = ({ match, race }) => {
	const { races_number, races_id, races_identifier, races_type, races_start_time } = race;
	const runners = race.runners || [];
	const { id } = match.params;

	const raceTitle = race.meeting ? `${race.meeting.meetings_name} Race ${races_number}` : `Race ${races_number}`;

	return (
		<div>
			<Link to={`/race-card/${id}`}>Back</Link>
			<h1>{raceTitle}</h1>
			<Styled_RaceCardDetailsTable>
				<tr>
					<Styled_RaceCardDetailsTd>
						<strong>mID:</strong> {races_identifier}
					</Styled_RaceCardDetailsTd>
					<Styled_RaceCardDetailsTd>
						<strong>bID:</strong> {races_id}
					</Styled_RaceCardDetailsTd>
				</tr>
				<tr>
					<Styled_RaceCardDetailsTd>
						<strong>Start Time:</strong> {races_start_time}
					</Styled_RaceCardDetailsTd>
					<Styled_RaceCardDetailsTd>
						<strong>Race Type:</strong> {races_type}
					</Styled_RaceCardDetailsTd>
				</tr>
			</Styled_RaceCardDetailsTable>

			<table>
				<tr>
					<th>#</th>
					<th>Name</th>
					<th>Trainer</th>
					<th>Prices</th>
				</tr>
				{runners.map(({ runners_number, runners_name, runners_trainer, prices }) => (
					<tr>
						<Styled_RaceCardTD>{runners_number}</Styled_RaceCardTD>
						<Styled_RaceCardTD>{runners_name}</Styled_RaceCardTD>
						<Styled_RaceCardTD>{runners_trainer}</Styled_RaceCardTD>
						<Styled_RaceCardTD>{prices && <PricesTable prices={prices} />}</Styled_RaceCardTD>
					</tr>
				))}
			</table>
		</div>
	);
};

export default RaceCard;

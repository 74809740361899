import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import GlobalEndpointForm from '../../components/GlobalEndpoint/GlobalEndpointForm';
import { Heading } from '@tbh/ui-kit';
import { createGlobalEndpoint } from '../../store/entities/actions/GlobalEndpointActions';
import { fetchAllClients } from '../../store/entities/actions/ClientActions';
import Card from '../../components/Ui/Card';

const GlobalEndpointCreateContainer = ({ createGlobalEndpoint, fetchClients, clients, history, ...rest }) => {
    useEffect(() => {
		fetchClients();
    }, []);

	function handleFormSubmit(formData) {
		createGlobalEndpoint(formData).then(() => {
			history.push('/global-endpoints');
		});
	}

	function handleCancel() {
		history.push('/global-endpoints');
	}

	return (
		<div>
			<Heading>Create Global Endpoint</Heading>
			<Card>
				<GlobalEndpointForm onSubmit={handleFormSubmit} onCancel={handleCancel} clients={clients} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const clients = Controller.denormalize('clients', state.entities);

	return {
		clients,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchClients: () => {
			dispatch(fetchAllClients());
        },
        
		createGlobalEndpoint: (formData) => {
			return dispatch(createGlobalEndpoint(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(GlobalEndpointCreateContainer);

export default (key, def) => {
	// Create config object
	const config = {
		apiUrl: process.env.REACT_APP_API_URL, // Moving theoverride to `apiUrl` for consistancy with other BM SPA's
		...(window.__DH_GLOBALS__ || {}),
		...(process.env || {}),
	};

	if (!key) {
		return config;
	}

	const value = config[key];

	// NULL !== undefined!!
	if (value === undefined) {
		return def;
	}

	return value;
};

import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import IndividualEndpointListContainer from './IndividualEndpointListContainer';
import IndividualEndpointCreateContainer from './IndividualEndpointCreateContainer';
import IndividualEndpointEditContainer from './IndividualEndpointEditContainer';

const IndividualEndpointPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={IndividualEndpointListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={IndividualEndpointCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={IndividualEndpointEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		individualEndpoints: Controller.denormalize('individualEndpoints', state.entities),
	};
};

export default connect(mapStateToProps)(IndividualEndpointPage);

import React from 'react';
import { FlexText, Heading ,Button } from '@tbh/ui-kit';
import { Link } from 'react-router-dom';
import { css } from 'react-emotion/macro';
import Card from '../Ui/Card';
import ButtonMaterial from "../Ui/Button";

const BetmakersListItem = ({ id, editLink, onDelete, venue_key, code,volume, ...rest }) => {
	const handleDelete = (e) => {
		e.stopPropagation();
		onDelete && onDelete(id);
	};

	return (
	<tr>
		<td><Heading type="5">{venue_key || '[Untitled]'}</Heading></td>
		<td><Heading type="5">{code || '[Untitled]'}</Heading></td>
		<td><Heading type="5">{volume || '[Untitled]'}</Heading></td>
		<td><div>
			<Link to={editLink}><ButtonMaterial color="primary">Edit</ButtonMaterial></Link><i onClick={handleDelete} ><ButtonMaterial color="danger" >Delete</ButtonMaterial></i>
		</div></td>
	</tr>
	);
};

export default BetmakersListItem;

import React from "react";

import DataForm from "../../DataForm/DataForm";

import { Input } from "@tbh/ui-kit";

class Owners extends DataForm {
  state = {
    fields: {
      selection_id: "",
      race_id: "",
      identifier: "",
      name: "",
      number: "",
      number_of_runners: "",
      associate: "",
      trainer: "",
      barrier: "",
      weight: "",
      silk_code: "",
      last_starts: "",
      silk_id: "",
      win_deduction: "",
      place_deduction: "",
      scratched_time: "",
      status_id: "",
      field_priority: "",
      last_source_id: "",
      results: "",
      first_up: "",
      second_up: "",
      this_track: "",
      track_dist: "",
      good: "",
      heavy: "",
      this_dist: "",
      firm: "",
      soft: "",
      synthetic: "",
      wet: "",
      non_wet: "",
      night: "",
      jumps: "",
      this_season: ""
    }
  };

  render() {
    return (
      <fieldset>
        <Input
          name="selection_id"
          value={this.state.fields.selection_id}
          placeholder="selection_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="race_id"
          value={this.state.fields.race_id}
          placeholder="race_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="identifier"
          value={this.state.fields.identifier}
          placeholder="identifier"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="name"
          value={this.state.fields.name}
          placeholder="name"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="number"
          value={this.state.fields.number}
          placeholder="number"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="number_of_runners"
          value={this.state.fields.number_of_runners}
          placeholder="number_of_runners"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="associate"
          value={this.state.fields.associate}
          placeholder="associate"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="trainer"
          value={this.state.fields.trainer}
          placeholder="trainer"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="barrier"
          value={this.state.fields.barrier}
          placeholder="barrier"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="weight"
          value={this.state.fields.weight}
          placeholder="weight"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="silk_code"
          value={this.state.fields.silk_code}
          placeholder="silk_code"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="last_starts"
          value={this.state.fields.last_starts}
          placeholder="last_starts"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="silk_id"
          value={this.state.fields.silk_id}
          placeholder="silk_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="win_deduction"
          value={this.state.fields.win_deduction}
          placeholder="win_deduction"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="place_deduction"
          value={this.state.fields.place_deduction}
          placeholder="place_deduction"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="scratched_time"
          value={this.state.fields.scratched_time}
          placeholder="scratched_time"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="status_id"
          value={this.state.fields.status_id}
          placeholder="status_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="field_priority"
          value={this.state.fields.field_priority}
          placeholder="field_priority"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="last_source_id"
          value={this.state.fields.last_source_id}
          placeholder="last_source_id"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="results"
          value={this.state.fields.results}
          placeholder="results"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="first_up"
          value={this.state.fields.first_up}
          placeholder="first_up"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="second_up"
          value={this.state.fields.second_up}
          placeholder="second_up"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="this_track"
          value={this.state.fields.this_track}
          placeholder="this_track"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="track_dist"
          value={this.state.fields.track_dist}
          placeholder="track_dist"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="good"
          value={this.state.fields.good}
          placeholder="good"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="heavy"
          value={this.state.fields.heavy}
          placeholder="heavy"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="this_dist"
          value={this.state.fields.this_dist}
          placeholder="this_dist"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="firm"
          value={this.state.fields.firm}
          placeholder="firm"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="soft"
          value={this.state.fields.soft}
          placeholder="soft"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="synthetic"
          value={this.state.fields.synthetic}
          placeholder="synthetic"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="wet"
          value={this.state.fields.wet}
          placeholder="wet"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="non_wet"
          value={this.state.fields.non_wet}
          placeholder="non_wet"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="night"
          value={this.state.fields.night}
          placeholder="night"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="jumps"
          value={this.state.fields.jumps}
          placeholder="jumps"
          onChange={this.handleChange}
          type="text"
        />
        <Input
          name="this_season"
          value={this.state.fields.this_season}
          placeholder="this_season"
          onChange={this.handleChange}
          type="text"
        />
      </fieldset>
    );
  }
}

export default Owners;

import { get, post, put, remove } from '../../../common/ajax';
import Controller from '../controller';
import {mergeEntities, resetEntities} from 'redux-entities-reducer';


/**
 * Validate the resource.
 *
 * @param {*} formData
 */
const validateProduct = (product) => {
    return product;
};

/**
 * Load individual endpoints for the logged in client
 *
 * @param {*} embed
 */
const loadProducts = (embed = []) => {
    return get('/admin/products', {
        params: { with: embed },
    }).catch((e) => {
        throw new Error(`Couldn't load individual endpoints: ${e}`);
    });
};

/**
 * Create a resource
 *
 * @param product
 */
export const createProduct = (product) => (dispatch) => {
    return post('/admin/products', validateProduct(product));
};

/**
 * Edit the resource
 *
 * @param product
 */
export const editProduct = (product) => (dispatch) => {
    return put(`/admin/products/${product.id}`, validateProduct(product));
};

/**
 * Delete the resource
 *
 * @param id
 */
export const deleteProduct = (id) => (disaptch) => {
    return remove(`/admin/products/${id}`);
};

/**
 * Fetch all individual endpoints for the logged in client
 *
 * @param {*} embed
 */
export const fetchAllProducts = (embed) => (dispatch) => {
    loadProducts(embed).then(({ data }) =>
        dispatch(mergeEntities(Controller.normalize('products', data.data).entities)),
    );
};

/**
 * Reset individual endpoints for logged in client
 *
 * @param {*} embed
 */
export const resetProducts = (embed) => (dispatch) => {
    loadProducts(embed).then(({ data }) =>
        dispatch(
            resetEntities({
                products: {},
                ...Controller.normalize('products', data.data).entities,
            }),
        ),
    );
};

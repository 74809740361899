import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SqsQueueForm from '../../components/SqsQueue/SqsQueueForm';
import Controller from '../../store/entities/controller';
import { Heading } from '@tbh/ui-kit';
import { fetchAllSqsQueues, editSqsQueue } from '../../store/entities/actions/SqsQueueActions';
import { fetchSubscriptionsForCurrentUser } from '../../store/entities/actions/SubscriptionActions';
import { fetchAllClients } from '../../store/entities/actions/ClientActions';
import Card from '../../components/Ui/Card';

const SqsQueueEditContainer = ({ sqsQueue, subscriptions, fetchSubscriptions, clients, fetchClients, fetchSqsQueues, createSqsQueue, history, editSqsQueue, match, ...rest }) => {
	useEffect(() => {
		fetchSqsQueues();
		fetchSubscriptions();
		fetchClients();
	}, []);

	function handleFormSubmit(formData) {
		editSqsQueue({ ...sqsQueue, ...formData }).then(() => {
			history.push('/sqs-queues');
		});
	}

	function handleCancel() {
		history.push('/sqs-queues');
	}

	return (
		<div>
			<Heading>Edit Sqs Queue</Heading>
			<Card>
				<SqsQueueForm onSubmit={handleFormSubmit} onCancel={handleCancel} sqsQueue={sqsQueue} subscriptions={subscriptions} clients={clients} />
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const sqsQueues = Controller.denormalize('sqsQueues', state.entities);
	const subscriptions = Controller.denormalize('subscriptions', state.entities);
	const clients = Controller.denormalize('clients', state.entities);

	const sqsQueue = sqsQueues.find((sqsQueue) => {
		return sqsQueue.id == ownProps.match.params.id;
	});

	return {
		sqsQueue,
		subscriptions,
		clients,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSqsQueues: () => {
			dispatch(fetchAllSqsQueues());
        },
        
		fetchSubscriptions: () => {
			dispatch(fetchSubscriptionsForCurrentUser());
		},
        
		fetchClients: () => {
			dispatch(fetchAllClients());
		},

		editSqsQueue: (formData) => {
			return dispatch(editSqsQueue(formData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SqsQueueEditContainer);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, post } from '../../common/ajax';
import { fetchSubscriptionById } from '../../store/entities/actions/SubscriptionActions';
import {
	fetchNextToJumpRacesForSubscription,
	fetchRaceForSubscription,
} from '../../store/entities/actions/RaceActions';
import Controller from '../../store/entities/controller';
import MeetingAndRace from '../../components/Racing/MeetingAndRaces';
import PrivateRoute from '../../components/Route/PrivateRoute';
import RaceCard from '../../components/Racing/RaceCard';
import { Heading, FlexText } from '@tbh/ui-kit';

const SubscriptionRaceCardPage = (props) => {
	const { subscription, selectedRace, fetchRace, meetings, fetchSubscription, fetchNextToJump } = props;

	const subscriptionId = props.match.params.id;
	const matchRaceId = props.match.params.raceId;

	useEffect(() => {
		if (!subscriptionId) {
			return;
		}
		// Fetch the card card data
		fetchNextToJump(subscriptionId);
	}, [subscriptionId]);

	useEffect(() => {
		if (!subscriptionId || !matchRaceId) {
			return;
		}
		// Fetch the card card data
		fetchRace(subscriptionId, matchRaceId);
	}, [subscriptionId, matchRaceId]);

	useEffect(() => {
		if (!subscriptionId) {
			return;
		}
		// Load the subscription race card
		fetchSubscription(subscriptionId);
	}, [subscriptionId]);

	return (
		<div>
			<FlexText>
				<Heading>{subscription ? subscription.description : null}</Heading>
			</FlexText>
			{!selectedRace && (
				<div>
					{meetings.map((meeting) => (
						<MeetingAndRace match={props.match} name={meeting.meetings_name} races={meeting.races} />
					))}
				</div>
			)}
			{selectedRace && (
				<PrivateRoute
					path={`${props.match.path}/race/:raceId`}
					component={(props) => {
						return <RaceCard match={props.match} race={selectedRace} />;
					}}
				/>
			)}
		</div>
	);
};

/**
 * TODO: Memoize
 *
 * Races have a relationship to meetings. The meetings and races are loading by the next to jump call, which returns races with an
 * embeded meeting. We need to reverse this structure.
 */
const getMeetingsWithRaces = (state) => {
	// Store as an object keyed by the meeting id, this will make it easier to pluck out the meeting.
	// When we are done, we will just take the Object.values to have our normalized meetings and races
	const meetingsAndRacesObject = {};

	const races = Controller.denormalize('races', state.entities);
	races.forEach((race) => {
		const { meeting } = race;

		if (!meeting) {
			return;
		}

		meetingsAndRacesObject[race.meeting.meetings_id] = race.meeting;

		// Get the races array reference. If the meeting doesn't have a races array, then create one
		const racesArray = meeting.races || (meeting.races = []);

		// We shouldn't need to check if the race already exists...
		racesArray.push(race);
	});

	return Object.values(meetingsAndRacesObject);
};

const mapStateToProps = (state, ownProps) => {
	const races = Controller.denormalize('races', state.entities);
	const race = races.find((race) => race.races_identifier === ownProps.match.params.raceId);

	return {
		meetings: getMeetingsWithRaces(state),
		subscription: state.entities.subscriptions[ownProps.match.params.id],
		selectedRace: race || null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRace: (subscriptionId, raceId) => {
			return dispatch(fetchRaceForSubscription(subscriptionId, raceId));
		},

		/**
		 * Fetch the subscription by the given id
		 */
		fetchSubscription: (subscriptionId) => {
			return dispatch(fetchSubscriptionById(subscriptionId));
		},

		fetchNextToJump: (subscriptionId) => {
			return dispatch(fetchNextToJumpRacesForSubscription(subscriptionId));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SubscriptionRaceCardPage);

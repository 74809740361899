import React from 'react';
import styled, { css } from 'react-emotion';
import { spacings, ui } from '@tbh/ui-kit';

const Styled__Card = styled('div')(
	(props) => css`
		background-color: ${ui(props).color_2};
		-webkit-box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1);
		box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1);
		margin-bottom: ${spacings(props).cozy}px;
		border-radius:20px;

		${props.hover &&
			css`
				:hover {
					cursor: pointer;
					background-color: ${ui(props).color_1};
					border-radius:10px;
				}
			`}
	`,
);

const Styled__CardBar = styled('div')((props) => css``);

const Styled__CardBody = styled('div')(
	(props) => css`
		padding: ${spacings(props).cozy}px;
	`,
);

const Card = ({ hover, children, title, actionBar, className, onClick }) => {
	return (
		<Styled__Card className={className} onClick={onClick} hover={hover}>
			{title && <Styled__CardBar>{title}</Styled__CardBar>}
			<Styled__CardBody>{children}</Styled__CardBody>
			{actionBar && <Styled__CardBar>{actionBar()}</Styled__CardBar>}
		</Styled__Card>
	);
};

export default Card;

import React from 'react';
import { connect } from 'react-redux';
import Controller from '../../store/entities/controller';
import PrivateRoute from '../../components/Route/PrivateRoute';
import BetRadarVenueMapListContainer from './BetRadarVenueMapListContainer';
import BetRadarVenueCreateContainer from './BetRadarVenueCreateContainer';
import BetRadarVenueEditContainer from './BetRadarVenueEditContainer';

const BetRadarVenueMapPage = (props) => {
	const { match } = props;
	return (
		<>
			<PrivateRoute exact path={`${match.path}`} component={BetRadarVenueMapListContainer} />
			<PrivateRoute path={`${match.path}/create`} component={BetRadarVenueCreateContainer} />
			<PrivateRoute path={`${match.path}/edit/:id`} component={BetRadarVenueEditContainer} />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		betRadarVenueMaps: Controller.denormalize('betRadarVenueMaps', state.entities),
	};
};

export default connect(mapStateToProps)(BetRadarVenueMapPage);
